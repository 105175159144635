export const getGroupIdFromDatasource = (dataSource) => {
  if (!dataSource) return null;
  if (!dataSource.staticParams) return null;

  const { rest, groupId } = dataSource.staticParams;

  if (groupId) return groupId;

  const splitted = rest[2].split("_");

  if (!splitted) return null;

  const idx = splitted.findIndex((el) => el.toLowerCase() === "groupid");
  if (idx === -1) return null;
  if (!splitted[idx + 1]) return null;

  return splitted[idx + 1];
};
