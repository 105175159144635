import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import PropTypes from "prop-types";

export const HeaderActions = ({ actions }) => {
  const { deleteSection, openTitleEdit, openNewTask } = actions;

  return (
    <Box display="flex" alignItems="center" gap="5px">
      <Button
        variant="unstyled"
        padding="0"
        margin="0"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={openNewTask}
        zIndex={0}
      >
        <FaPlus />
      </Button>
      <Menu>
        <MenuButton
          as={IconButton}
          display="flex"
          alignItems="center"
          justifyContent="center"
          variant="unstyled"
          icon={<BiDotsHorizontalRounded />}
        ></MenuButton>
        <MenuList zIndex={99}>
          <MenuItem color="red" onClick={deleteSection}>
            Delete section
          </MenuItem>
          <MenuItem onClick={openTitleEdit}>Rename section</MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

HeaderActions.propTypes = {
  actions: PropTypes.shape({
    deleteSection: PropTypes.func,
    openTitleEdit: PropTypes.func,
    openNewTask: PropTypes.func,
  }),
};
