import { Box, useColorMode } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { DragSection } from "@echo/draggable";
import { useBoardContext } from "../../context/board-context";
import { getBorderColor, getSectionBackground } from "../../utils/theme-utils";
import { SectionHeader } from "./section-header/section-header";
import { TasksList } from "./tasks-list/tasks-list";
import { getSectionId, getSectionName } from "../../utils/section-utils";

export const BoardSection = ({
  section,
  tasks,
  dragging,
  disableDrag,
  tasksLoading,
  enableSectionMove,
}) => {
  const { onSectionChange, onSectionDelete } = useBoardContext();

  const handleSectionNameChange = useCallback(
    (value) => {
      const newSection = { ...section, name: value };
      onSectionChange(newSection);
    },
    [onSectionChange, section],
  );

  const [sectionHovered, setSectionHovered] = useState(false);
  const [addingNewTask, setAddingNewTask] = useState(false);
  const { colorMode } = useColorMode();

  return !section.placeholder ? (
    <DragSection id={getSectionId(section)} context="board-sections">
      <Box
        display="flex"
        flexDirection="column"
        padding="6px"
        height="100%"
        borderRadius=".75rem"
        border={
          !dragging && sectionHovered
            ? `1px solid ${getBorderColor(colorMode)}`
            : "1px solid transparent"
        }
        overflow="hidden"
      >
        <SectionHeader
          title={getSectionName(section)}
          tasksLength={tasks.length}
          actions={{
            deleteSection: () => onSectionDelete(getSectionId(section)),
            onTitleChange: (value) => handleSectionNameChange(value),
            onMouseDown: () => disableDrag(false),
            onMouseEnter: () => setSectionHovered(true),
            onMouseLeave: () => setSectionHovered(false),
            openNewTask: () => setAddingNewTask(true),
          }}
          enableSectionMove={enableSectionMove}
        />
        <TasksList
          section={section}
          tasks={{
            data: tasks,
            loading: tasksLoading,
          }}
          addingNewTask={addingNewTask}
          closeNewTask={() => setAddingNewTask(false)}
        />
      </Box>
    </DragSection>
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      padding="10px"
      height="100%"
      background={getSectionBackground(colorMode)}
      borderRadius=".75rem"
    />
  );
};

BoardSection.propTypes = {
  section: PropTypes.object,
  tasks: PropTypes.array,
  dragging: PropTypes.bool,
  disableDrag: PropTypes.func,
  tasksLoading: PropTypes.bool,
  enableSectionMove: PropTypes.bool,
};
