const getToolbarOptions = ({
  enableFontToolbar,
  enableQuoteToolbar,
  enableAttachmentToolbar,
  enableHeaderToolbar,
  enableListToolbar,
  enableScriptToolbar,
  enableIndentToolbar,
  enableDirectionToolbar,
  enableSizeToolbar,
  enableColorToolbar,
  enableCleanToolbar,
  enableAlignToolbar,
}) => {
  const toolbarOptions = [];

  if (enableFontToolbar) {
    toolbarOptions.push(["bold", "italic", "underline", "strike"]);
    toolbarOptions.push([{ font: [] }]);
  }
  if (enableAlignToolbar) {
    toolbarOptions.push([{ align: [] }]);
  }
  if (enableQuoteToolbar) {
    toolbarOptions.push(["blockquote", "code-block"]);
  }
  if (enableAttachmentToolbar) {
    toolbarOptions.push(["link", "image", "video", "formula"]);
  }
  if (enableHeaderToolbar) {
    toolbarOptions.push([{ header: 1 }, { header: 2 }]);
  }
  if (enableListToolbar) {
    toolbarOptions.push([
      { list: "ordered" },
      { list: "bullet" },
      { list: "check" },
    ]);
  }
  if (enableScriptToolbar) {
    toolbarOptions.push([{ script: "sub" }, { script: "super" }]);
  }
  if (enableIndentToolbar) {
    toolbarOptions.push([{ indent: "-1" }, { indent: "+1" }]);
  }
  if (enableDirectionToolbar) {
    toolbarOptions.push([{ direction: "rtl" }]);
  }
  if (enableSizeToolbar) {
    toolbarOptions.push([{ size: ["small", false, "large", "huge"] }]);
  }
  if (enableHeaderToolbar) {
    toolbarOptions.push([{ header: [1, 2, 3, 4, 5, 6, false] }]);
  }
  if (enableColorToolbar) {
    toolbarOptions.push([{ color: [] }, { background: [] }]);
  }
  if (enableCleanToolbar) {
    toolbarOptions.push(["clean"]);
  }

  return toolbarOptions;
};

const clipboardOptions = {
  matchVisual: false,
};

export const getModules = (config) => ({
  toolbar: getToolbarOptions(config),
  clipboard: clipboardOptions,
});

export const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "clean",
  "custom-paragraph",
];
