import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  getContextWithItems,
  getContextWithSections,
} from "../utils/get-context-with-items";

export const DragContext = createContext();

export const DragContextProvider = ({ children }) => {
  const [contextList, setContextList] = useState([]);
  const draggedElementRef = useRef(null);
  const itemPosRef = useRef(null);
  const areaRef = useRef(null);

  const setItemPos = (pos) => {
    itemPosRef.current = pos;
  };

  const setArea = (area) => {
    areaRef.current = area;
  };

  const setDraggedElement = (elem) => {
    draggedElementRef.current = elem;
  };

  const onDragStart = (el) => setDraggedElement(el);

  const onDragEnd = () => {
    setDraggedElement(null);
    setArea(null);
  };

  const getDraggedElement = useCallback(
    () => draggedElementRef.current,
    [draggedElementRef],
  );

  const getDraggedItem = useCallback(() => {
    const newPos = itemPosRef.current;
    const item = getDraggedElement();
    return { ...item, newPos };
  }, [itemPosRef, getDraggedElement]);

  const getAreaPos = useCallback(() => areaRef.current, [areaRef]);

  const onDrop = useCallback(() => {
    const item = getDraggedItem();
    const areaPos = getAreaPos();

    setDraggedElement(null);
    setItemPos(null);
    setArea(null);

    return { item, areaPos };
  }, [getAreaPos, getDraggedItem]);

  const onDragOverSection = (id) => setArea(id);

  const onDragOverItem = (id) => setItemPos(id);

  const addContext = (ctx) =>
    setContextList((prev) => {
      const f = prev.find((c) => c.id === ctx);
      if (f) return prev;
      return [...prev, { id: ctx, sections: [], items: [] }];
    });

  const registerSection = (id, ctx) =>
    setContextList((prev) => {
      const f = prev.find((c) => c.id === ctx);
      if (!f) return [...prev, { id: ctx, sections: [id], items: [] }];
      return getContextWithSections(prev, ctx, id);
    });

  const registerItem = (item, ctx) =>
    setContextList((prev) => {
      const f = prev.find((c) => c.id === ctx);
      if (!f) return [...prev, { id: ctx, items: [item], sections: [] }];
      return getContextWithItems(prev, ctx, item);
    });

  const getContextList = useCallback(() => contextList, [contextList]);

  return (
    <DragContext.Provider
      value={{
        onDragStart,
        onDragEnd,
        onDragOverSection,
        onDragOverItem,
        onDrop,
        getDraggedItem,
        getAreaPos,
        getDraggedElement,
        addContext,
        registerSection,
        registerItem,
        getContextList,
      }}
    >
      {children}
    </DragContext.Provider>
  );
};

DragContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useDragContext = () => {
  const context = useContext(DragContext);

  if (!context) throw new Error("Drag context is not initialized.");

  return context;
};
