import {
  Box,
  Button,
  Input,
  ListItem,
  useColorMode,
  useOutsideClick,
} from "@chakra-ui/react";
import { useCallback, useRef, useState } from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import PropTypes from "prop-types";
import { uuidv4 } from "@echo/tools";
import { useBoardContext } from "../../../context/board-context";
import { getBorderColor, getTaskBackground } from "../../../utils/theme-utils";

export const EmptyTask = ({ closeTask, sectionId }) => {
  const { onItemAdd } = useBoardContext();
  const [title, setTitle] = useState("");
  const [isCompleted, setIsCompleted] = useState(false);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter") {
        if (!e.target.value || e.target.value === "") return;

        const task = {
          id: uuidv4(),
          title: e.target.value,
          isCompleted,
          createdAt: new Date().toISOString(),
          sectionId,
          order: 0,
        };
        onItemAdd(task);
        closeTask();
      }
    },
    [closeTask, isCompleted, onItemAdd, sectionId],
  );

  const handleBlur = useCallback(() => {
    if (!title || title === "") {
      closeTask();
      return;
    }
    const task = {
      id: uuidv4(),
      title,
      isCompleted,
      createdAt: new Date().toISOString(),
      sectionId,
      order: 0,
    };
    onItemAdd(task);
    closeTask();
  }, [closeTask, isCompleted, onItemAdd, sectionId, title]);

  const handleTitleChange = (e) => setTitle(e.target.value);

  const itemRef = useRef();

  useOutsideClick({
    ref: itemRef,
    handler: handleBlur,
  });

  const { colorMode } = useColorMode();

  return (
    <ListItem
      ref={itemRef}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap="10px"
      borderRadius="8px"
      background={getTaskBackground(colorMode)}
      padding="15px 10px"
      transition="all .3s ease"
      cursor="pointer"
      _hover={{
        outline: `1px solid ${getBorderColor(colorMode)}`,
      }}
    >
      <Box lineHeight="1.2rem" display="flex" alignItems="center">
        <Button
          padding="0"
          margin="0"
          variant="ghost"
          height="fit-content"
          color={isCompleted ? "green" : "gray"}
          _hover={{
            color: "green",
          }}
          onClick={() => setIsCompleted((prev) => !prev)}
        >
          <IoCheckmarkCircleOutline fontSize="1.2rem" />
        </Button>
        <Input
          width="100%"
          value={title}
          onChange={handleTitleChange}
          onKeyDown={handleKeyDown}
          border="none"
          _active={{
            border: "none",
            outline: "none",
          }}
          _hover={{
            border: "none",
            outline: "none",
          }}
          _focus={{
            border: "none",
            outline: "none",
          }}
          autoFocus
        />
      </Box>
    </ListItem>
  );
};

EmptyTask.propTypes = {
  closeTask: PropTypes.func,
  sectionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
