import {
  differenceInCalendarDays,
  differenceInCalendarWeeks,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import { timeIntervalType } from "./time-interval-type";

export const checkInterval = (n) => {
  const now = new Date();
  const dateToCheck = new Date(n.dateToCheck);

  switch (n.interval) {
    case timeIntervalType.hours:
      return differenceInHours(dateToCheck, now) === n.timeToNotify;
    case timeIntervalType.days:
      return differenceInCalendarDays(dateToCheck, now) === n.timeToNotify;
    case timeIntervalType.weeks:
      return differenceInCalendarWeeks(dateToCheck, now) === n.timeToNotify;
    case timeIntervalType.minutes:
    default:
      return differenceInMinutes(dateToCheck, now) === n.timeToNotify;
  }
};
