import { Box } from "@chakra-ui/react";
import { withBaseComponent } from "../../../with-base-component";
import PropTypes from "prop-types";
import { Board } from "./components/board";
import { useBoardComponent } from "./hooks/use-board-component";
import { DragContextProvider } from "@echo/draggable";
import { BoardContextProvider } from "./context/board-context";
import { useApiContext } from "../../../../../echo-components/api-context/use-api-context";
import { useResolveProp } from "../../../../../hooks/resolve-prop/use-resolve-prop";

const KanbanComponent = (props) => {
  const { id, guid, nodeId, nodeRef, rootProps, enableFilter = props } = props;

  const [, component] = useApiContext(id, guid, nodeId);

  const { tasks, sections } = useBoardComponent(props, component);

  const enableSectionAdd = useResolveProp(props.enableSectionAdd, true);
  const enableSectionMove = useResolveProp(props.enableSectionMove, true);
  const enableFilterState = useResolveProp(enableFilter, true);

  return (
    <Box ref={nodeRef} {...rootProps} overflow="hidden">
      <DragContextProvider>
        <BoardContextProvider value={{ ...sections.actions, ...tasks.actions }}>
          <Board
            sections={sections.data}
            tasks={tasks.data}
            sectionsLoading={sections.loading}
            tasksLoading={tasks.loading}
            enableSectionAdd={enableSectionAdd}
            enableSectionMove={enableSectionMove}
            enableFilter={enableFilterState}
          />
        </BoardContextProvider>
      </DragContextProvider>
    </Box>
  );
};

KanbanComponent.propTypes = {
  id: PropTypes.string,
  tasksDataSource: PropTypes.func,
  sectionsDataSource: PropTypes.func,
  enableSectionAdd: PropTypes.bool,
  enableSectionMove: PropTypes.bool,
  enableFilter: PropTypes.bool,

  onItemAdd: PropTypes.func,
  onItemClick: PropTypes.func,
  onItemChange: PropTypes.func,
  onItemDelete: PropTypes.func,

  onSectionAdd: PropTypes.func,
  onSectionDelete: PropTypes.func,
  onSectionChange: PropTypes.func,

  nodeRef: PropTypes.object,
  rootProps: PropTypes.object,

  guid: PropTypes.string,
  nodeId: PropTypes.string,
};

export default withBaseComponent(KanbanComponent);
