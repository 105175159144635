// @flow

import React, { useCallback, useState } from "react";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import {
  format,
  isToday,
  compareAsc,
  isSameDay,
  startOfWeek,
  endOfWeek,
  compareDesc,
  endOfDay,
  startOfDay,
  eachDayOfInterval,
} from "date-fns";
import { CalendarNav } from "./calendar-nav";

const WEEKDAYS_PL = ["Pon", "Wto", "Śro", "Czw", "Pią", "Sob", "Nie"];

const CalendarGridHeader = () =>
  WEEKDAYS_PL.map((el) => (
    <GridItem
      paddingBottom="5px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      key={el}
    >
      {el}
    </GridItem>
  ));

type GridProps = {
  calendarDays: { type: "PREV" | "CURRENT" | "NEXT", days: Date[] }[],
  onDateSelected: (date: Date) => void,
  selectedDates: Date[],
  selectionType: "day" | "week",
};

const CalendarGrid = (props: GridProps) => {
  const { selectedDates, calendarDays, onDateSelected, selectionType } = props;

  const getIsSelected = useCallback((date: Date, selectedDates: Date[]) => {
    const found = selectedDates.find((d) => isSameDay(date, new Date(d)));
    return found ? true : false;
  }, []);

  const [hoveredWeek, setHoveredWeek] = useState([]);

  const handleMouseOver = (date) => {
    const weekStart = startOfWeek(date, { weekStartsOn: 1 });
    const weekEnd = endOfWeek(date, { weekStartsOn: 1 });
    const week = [weekStart, weekEnd];
    setHoveredWeek(week);
  };

  const getBackground = (date, selectedDates, hoveredWeek) => {
    const isSelected = getIsSelected(date, selectedDates);

    if (isSelected) return "#8075FF";

    if (hoveredWeek && hoveredWeek[0] && hoveredWeek[1]) {
      if (
        (compareAsc(date, hoveredWeek[0]) === 1 ||
          compareAsc(date, hoveredWeek[0]) === 0) &&
        (compareAsc(date, hoveredWeek[1]) === -1 ||
          compareAsc(date, hoveredWeek[1]) === 0)
      ) {
        return "#DDDDDD";
      }
    }

    return "";
  };

  const handleClick = (date) => {
    if (!onDateSelected) return;

    if (selectionType === "day") {
      onDateSelected(date);
      return;
    }

    const weekStart = startOfWeek(date, { weekStartsOn: 1 });
    const weekEnd = endOfWeek(date, { weekStartsOn: 1 });
    const week = eachDayOfInterval({ start: weekStart, end: weekEnd });

    onDateSelected(week);
  };

  return (
    <Grid templateColumns={"repeat(7, 1fr)"} gap="5px">
      <CalendarGridHeader />
      {calendarDays.map((el) =>
        el.days.map((day, idx) => (
          <GridItem
            padding="5px 0"
            cursor="pointer"
            aspectRatio="1/1"
            onClick={() => handleClick(day)}
            border={isToday(day) ? "2px solid #8075FF !important" : ""}
            background={getBackground(day, selectedDates, hoveredWeek)}
            fontWeight={getIsSelected(day, selectedDates) ? "bold" : "normal"}
            onMouseOver={
              selectionType === "week" ? () => handleMouseOver(day) : undefined
            }
            onMouseLeave={
              selectionType === "week" ? () => setHoveredWeek([]) : undefined
            }
            borderRadius="8px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            key={idx}
            _hover={{ background: "#DDDDDD" }}
            color={el.type === "PREV" || el.type === "NEXT" ? "#AAA" : "black"}
          >
            {format(day, "d")}
          </GridItem>
        )),
      )}
    </Grid>
  );
};

type CalendarProps = {
  date: Date,
  calendarDays: Date[],
  onDateChange: () => void,
  onDateSelected: (date: Date) => void,
  selectedDates: Date[],
  selectionType: "day" | "week",
};

export const Calendar = (props: CalendarProps) => {
  const {
    selectedDates,
    calendarDays,
    date,
    onDateChange,
    onDateSelected,
    selectionType = "day",
  } = props;

  return (
    <Box display="flex" flexDirection="column" padding="10px" gap="5px">
      <CalendarNav date={date} onDateChange={onDateChange} />
      <CalendarGrid
        calendarDays={calendarDays}
        onDateSelected={onDateSelected}
        selectedDates={selectedDates}
        selectionType={selectionType}
      />
    </Box>
  );
};
