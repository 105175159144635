import React, { useState } from "react";
import { InputNumericEditCell } from "./input-numeric-edit-cell";
import { InputDateEditCell } from "./input-date-edit-cell";
import { BoolEditCell } from "./bool-edit-cell";
import { ComboEditCell } from "./combo-edit-cell";
import { InputEditCell } from "./input-edit-cell";

export const TypedEditCellV2 = (props) => {
  const { row, column, data, ...inputProps } = props;
  const { type, format, options } = data;

  switch (type) {
    case "numeric":
    case "number":
      return (
        <InputNumericEditCell
          format={format || column.format}
          {...inputProps}
        />
      );
    case "date":
    case "datetime":
      return (
        <InputDateEditCell
          dateFormat={format || column.format}
          {...inputProps}
        />
      );
    case "boolean":
    case "checkbox":
      return <BoolEditCell {...inputProps} />;
    case "combo":
      return <ComboEditCell options={options} {...inputProps} />;
    case "string":
      return <InputEditCell {...inputProps} />;
    default:
      null;
  }
};
