const propertiesToRemove = [
  "isNull",
  "type",
  "editDataSource",
  "editable",
  "onChangeEvent",
];

export const removeColumnProp = (c) => {
  const keys = Object.keys(c).filter((k) => !propertiesToRemove.includes(k));

  const obj = keys.reduce((acc, curr) => ({ ...acc, [curr]: c[curr] }), {});
  return obj;
};
