import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { getMenu, getTiles } from "../../services/menu-service";
import "../../styles/main-component/home-page.css";
import EchoRouterTabs from "../echo-router/echo-router-tabs";
import EchoContentComponent from "../menu/echo-content-component";
import Menu from "../menu/menu";
import { Box } from "@chakra-ui/react";
import UserContext from "../../user-context";
import { getRoutes } from "../../services/routes-service";
import { useComponentsRegistry } from "../../components-registry/hooks/use-components-registry";
import { useTokenExpiration } from "../../hooks/use-token-expiration";
import { useRefDimensions } from "@echo/ui";
import { useNotification } from "../../hooks/use-notification";

const HomePage = () => {
  const [menuState, setMenuState] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [tiles, setTiles] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const menuRef = useRef();
  const contentRef = useRef();
  const [contentDimensions] = useRefDimensions(contentRef, {
    reactOnResize: true,
  });

  useTokenExpiration();

  const { isLoggedIn } = useContext(UserContext);
  const componentsRegistry = useComponentsRegistry();

  useEffect(() => {
    if (tiles === null) {
      getTiles()
        .then(setTiles)
        .catch((err) => {
          toast.error("Error occurred while loading dashboard data.");
          window.console.error(err);
        });
    }
  }, [tiles]);

  useEffect(() => {
    if (
      contentDimensions &&
      contentDimensions.width &&
      contentDimensions.width < 1024
    ) {
      setIsLocked(false);
    } else {
      setIsLocked(true);
    }
  }, [contentDimensions]);

  const loadMenuData = (action) => {
    getMenu()
      .then((result) => {
        if (result?.length !== menuState?.length) {
          action(result);
        }
      })
      .catch((err) => {
        toast.error("Error occurred while loading menu items.");
        window.console.error(err);
      });
  };

  const loadRoutes = (action, onError) => {
    if (isLoggedIn) {
      getRoutes()
        .then((result) => {
          if (result?.length !== routes?.length) {
            action(result);
          }
        })
        .catch(onError);
    }
  };

  const handleToggle = useCallback(() => {
    if (isLocked) {
      Promise.all([setIsLocked(false), setIsOpen(false)]);
    } else {
      setIsOpen(!isOpen);
    }
  }, [isLocked, isOpen]);

  const handleToggleLock = useCallback(
    () => setIsLocked(!isLocked),
    [isLocked],
  );

  useEffect(() => {
    let isMounted = true;
    loadMenuData((result) => {
      if (isMounted) {
        setMenuState(result);
      }
    });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes]);

  useEffect(() => {
    let isMounted = true;

    const loadRoutesFunc = () => {
      loadRoutes(
        (newRoutes) => {
          if (isMounted) {
            setRoutes(newRoutes);
          }
        },
        (err) => {
          if (isMounted) {
            toast.error("Error occurred while loading routes.");
            window.console.error(err);
          }
        },
      );
    };

    loadRoutesFunc();

    window.addEventListener("uri_change", loadRoutesFunc);

    return () => {
      isMounted = false;
      window.removeEventListener("uri_change", loadRoutesFunc);
    };
  }, [routes]);

  useNotification(3152);

  return (
    <Box
      ref={contentRef}
      display="flex"
      flexDirection="row"
      width="100%"
      height="100%"
      overflow="hidden"
      flex={1}
    >
      <Menu
        ref={menuRef}
        isOpen={isOpen}
        isLocked={isLocked}
        onToggle={handleToggle}
        onToggleLock={handleToggleLock}
        menu={menuState}
      />
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
        overflow="hidden"
        flex={1}
      >
        <EchoRouterTabs />
        <EchoContentComponent
          routes={routes}
          tiles={tiles || []}
          componentsRegistry={componentsRegistry}
        />
      </Box>
    </Box>
  );
};

export default HomePage;
