import { getAvailableObjects } from "../../../../../../services/echo-object-service";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CustomSelect } from "../custom-select/custom-select";

export const DatagridRelatedObjectSelect = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getAvailableObjects().then((res) => {
      const options = res.map(({ id, name }) => ({ value: id, label: name }));
      setOptions(options);
    });
  }, []);

  return <CustomSelect {...props} options={options} />;
};

DatagridRelatedObjectSelect.propTypes = {
  propValue: PropTypes.string,
  onChange: PropTypes.func,
  propName: PropTypes.string,
  diagramParams: PropTypes.object,
};
