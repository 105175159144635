import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useEchoComponent } from "../../hooks/use-echo-component/use-echo-component";
import { withEchoComponentRenderer } from "../../../echo-component/function-wrapper/function-wrapper";
import EchoComponent from "../../shared/echo-components/echo-component";
import {
  componentStateReducer,
  getInitialComponentState,
} from "./reducer/component-state-reducer/component-state-reducer";
import { useApi } from "../api-context/create-api-context/create-api-context";
import EchoComponentsApiContext from "../api-context/api-context";
import EchoRouterContext from "../../echo-router/echo-router-context";
import { useComponentInitialization } from "./hooks/use-component-initialization";
import EchoComponentContext from "../../../echo-component/context/echo-component-context";
import { Box, IconButton, useDisclosure } from "@chakra-ui/react";
import { FiSliders } from "react-icons/fi";
import { ManagerDrawer } from "@echo/component-manager";
import UserContext from "../../../user-context";
import ComponentModal from "./modal/component-modal";
import { fillPattern } from "./tab-label";
import { usePage } from "../../echo-router/hooks/use-page";
import { showErrorToast } from "../../shared/echo-error-toast";

const ComponentRenderer = ({ componentId, componentsRegistry }) => {
  const { page } = usePage();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const rendererRef = useRef();
  const [modalComponentData, setModalComponentData] = useState();

  const onComponentModalClose = useCallback(() => {
    setModalComponentData(null);
  }, []);

  const { isSuperuser } = useContext(UserContext);

  const [componentState, dispatch] = useReducer(
    componentStateReducer,
    getInitialComponentState(page?.componentProps),
  );

  const [props, isLoading, component, componentContext, setComponent] =
    useEchoComponent(
      componentId,
      componentState,
      dispatch,
      {
        onError: (err) =>
          showErrorToast({
            reasonTitle: "Component loading error",
            location: "Component API",
            shortMessage: "Cannot load component.",
            message: err.toString(),
          }),
      },
      modalComponentData,
      (modalCmpData) => setModalComponentData(modalCmpData),
      componentsRegistry,
    );

  useEffect(() => {
    if (component?.tabLabelFormat) {
      componentContext?.routerContext?.updatePageName(
        componentContext.page.key,
        fillPattern(component.tabLabelFormat, componentState?.formSource),
      );
    } else if (component && componentContext?.page?.key) {
      if (!componentContext?.page?.displayName) {
        componentContext?.routerContext?.updatePageName(
          componentContext.page.key,
          component?.displayName || "New tab",
        );
      }
    }
  }, [component, componentContext]);

  const echoRouterContext = useContext(EchoRouterContext);
  const [apiContext] = useApi(
    component,
    echoRouterContext,
    dispatch,
    { mode: "renderer" },
    () => {},
  );

  useComponentInitialization(
    component,
    componentState,
    componentContext,
    dispatch,
    setComponent,
  );

  const { modalProps, ...modalCmpData } = modalComponentData || {};

  return (
    <EchoComponentContext.Provider value={componentContext}>
      <EchoComponentsApiContext.Provider value={apiContext}>
        <Box
          ref={rendererRef}
          height="100%"
          width="100%"
          flex={1}
          minHeight={0}
          display="flex"
          overflowY="auto"
          boxSizing="border-box"
        >
          {!isLoading && (
            <ComponentModal
              componentData={modalCmpData}
              context={componentContext}
              isOpen={modalComponentData}
              onClose={(type, component) => {
                modalProps.onClose(type, component);
                onComponentModalClose();
              }}
              modalProps={{ portalProps: { containerRef: rendererRef } }}
            />
          )}
          {isSuperuser && (
            <ManagerDrawer
              context={componentContext}
              isOpen={isOpen}
              onClose={onClose}
            />
          )}
          <Box
            right={0}
            position="absolute"
            padding="8px"
            marginTop={-35}
            zIndex="10"
          >
            {isSuperuser && (
              <IconButton
                onClick={onOpen}
                isRound
                variant="solid"
                size="md"
                colorScheme="red"
                icon={<FiSliders />}
              />
            )}
          </Box>
          {isLoading
            ? "Loading..."
            : withEchoComponentRenderer(
                <EchoComponent
                  context={componentContext}
                  componentProps={component?.componentProps ?? {}}
                  {...props}
                />,
                componentContext,
              )}
        </Box>
      </EchoComponentsApiContext.Provider>
    </EchoComponentContext.Provider>
  );
};

ComponentRenderer.propTypes = {
  componentId: PropTypes.number.isRequired,
  componentsRegistry: PropTypes.any,
};

export default ComponentRenderer;
