import { useEffect, useState } from "react";
import { showErrorToast } from "../../../../../echo-error-toast";
import { getKanbanData } from "../utils/get-kanban-data";
import { mapItemOrder } from "../utils/order-change";
import { getSectionOrder } from "../utils/section-utils";

export const useSectionsDataSource = (props, component) => {
  const { sectionsDataSource, context } = props;
  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState([]);

  const getData = async (dataSource, context, component) => {
    try {
      const res = await getKanbanData(dataSource, context, component);
      // probably temporary solution, we will see what to do with that, maybe delete later
      const sorted = res.sort(
        (a, b) => getSectionOrder(a) - getSectionOrder(b),
      );
      const mapped = sorted.map((t, idx) => ({ ...t, order: idx }));
      setSections(mapped);
      setLoading(false);
    } catch (e) {
      showErrorToast(e);
    }
  };

  const onSectionAdd = (section) => {
    if (props.onSectionAdd && typeof props.onSectionAdd === "function") {
      props.onSectionAdd(section);
      getData(sectionsDataSource, context, component);
      // left for future me, I will come back here
      // setSections((prev) => [...prev, section]);
    }
  };

  const onSectionMove = (section) => {
    if (props.onSectionChange && typeof props.onSectionChange === "function") {
      const f = sections.find((s) => s.id === section.id);
      const newSection = {
        ...f,
        order: section.newPos,
      };

      props.onSectionChange(newSection);
      setSections((prev) => [...prev.map((s) => mapItemOrder(s, section))]);
    }
  };

  const onSectionChange = (section) => {
    if (props.onSectionChange && typeof props.onSectionChange === "function") {
      props.onSectionChange(section);
      setSections((prev) => [...prev.map((s) => mapItemOrder(s, section))]);
    }
  };

  const onSectionDelete = (id) => {
    if (props.onSectionDelete && typeof props.onSectionDelete === "function") {
      props.onSectionDelete(id);
      setSections((prev) => [...prev.filter((s) => s.id !== id)]);
    }
  };

  useEffect(() => {
    if (sectionsDataSource?.id) {
      getData(sectionsDataSource, context, component);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionsDataSource?.id]);

  return {
    sections,
    loading,
    actions: {
      onSectionMove,
      onSectionChange,
      onSectionAdd,
      onSectionDelete,
    },
  };
};
