import { Box } from "@chakra-ui/react";
import { withBaseComponent } from "../../../with-base-component";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const HtmlComponent = ({ value, rootProps, designerMode }) => {
  const { nodeRef, ...restRootProps } = rootProps;
  const [valueState, setValueState] = useState(value);

  useEffect(() => {
    if (typeof value === "string") {
      setValueState({ __html: value });
    } else if (typeof value === "function") {
      Promise.resolve(value())
        .then((result) => {
          if (typeof result === "string") {
            setValueState({ __html: result });
          }
        })
        .catch(() => {});
    }
  }, [value]);

  const getHtmlValue = () => {
    if (designerMode) {
      return { __html: "<p>Html component</p>" };
    } else {
      return typeof valueState == "object" &&
        !Array.isArray(valueState) &&
        typeof valueState.__html == "string"
        ? valueState
        : undefined;
    }
  };

  const htmlValue = getHtmlValue();

  return (
    <Box ref={nodeRef} {...restRootProps} dangerouslySetInnerHTML={htmlValue} />
  );
};

HtmlComponent.propTypes = {
  value: PropTypes.string,
  rootProps: PropTypes.any,
  designerMode: PropTypes.bool,
};

export default withBaseComponent(HtmlComponent);
