export const notificationPromise = (text) =>
  new Promise((resolve) => {
    const notification = new Notification(text);

    const onClose = () => {
      resolve();
    };

    notification.addEventListener("close", onClose);
    setTimeout(() => {
      resolve();
    }, 5000);
  });
