import { isAfter } from "date-fns";
import { checkNotificationShown } from "./check-notification-shown";
import { getStoredNotifications } from "./get-stored-notifications";
import { sendNotification } from "./send-notification";
import { checkInterval } from "./check-interval";
import { getNotificationText } from "./get-notification-text";

const notificationFilter = (n) => {
  const isShown = checkNotificationShown(n.id);

  if (isShown) return false;

  const now = new Date();
  const dateToCheck = new Date(n.dateToCheck);
  return isAfter(dateToCheck, now);
};

const showNotificationsSequentially = async (notifications) => {
  for (const n of notifications) {
    const shouldNotify = checkInterval(n);
    const text = getNotificationText(n);
    if (shouldNotify) {
      await sendNotification(n.id, text);
    }
  }
};

export const notificationInterval = () =>
  setInterval(() => {
    const notifications = getStoredNotifications();
    const filtered = notifications.filter(notificationFilter);
    showNotificationsSequentially(filtered);
  }, 30000);
