import { notificationInterval } from "./notification-interval";

export const runNotification = () => {
  let interval;

  if (!("Notification" in window)) {
    console.error("This browser does not support desktop notification");
  } else if (Notification.permission === "granted") {
    interval = notificationInterval();
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        interval = notificationInterval();
      }
    });
  }

  return interval;
};
