import { Box, Input, Text, useColorMode } from "@chakra-ui/react";
import { getEmptySectionBackground } from "../../utils/theme-utils";
import { FaPlus } from "react-icons/fa";
import { useCallback, useState } from "react";
import { useBoardContext } from "../../context/board-context";
import { uuidv4 } from "@echo/tools";
import PropTypes from "prop-types";

export const EmptySection = ({ order }) => {
  const { onSectionAdd } = useBoardContext();
  const { colorMode } = useColorMode();
  const [sectionHovered, setSectionHovered] = useState(false);
  const [addingNewSection, setAddingNewSection] = useState(false);
  const [sectionName, setSectionName] = useState("");

  const reset = () => {
    setAddingNewSection(false);
    setSectionName("");
  };

  const handleBlur = useCallback(
    (e) => {
      if (!e.target.value || e.target.value === "") {
        setAddingNewSection(false);
        return;
      }
      onSectionAdd({ id: uuidv4(), name: e.target.value, order });
      reset();
    },
    [onSectionAdd, order],
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter") {
        if (!e.target.value || e.target.value === "") {
          setAddingNewSection(false);
          return;
        }
        onSectionAdd({ id: uuidv4(), name: e.target.value, order });
        reset();
      }
    },
    [onSectionAdd, order],
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding="10px"
      height="100%"
      borderRadius=".75rem"
      overflow="hidden"
      gap="5px"
      onMouseOver={() => setSectionHovered(true)}
      onMouseLeave={() => setSectionHovered(false)}
      onClick={() => setAddingNewSection(true)}
      cursor="pointer"
    >
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap="10px"
        borderRadius="8px"
        background={
          sectionHovered
            ? getEmptySectionBackground(colorMode, sectionHovered)
            : addingNewSection
              ? getEmptySectionBackground(colorMode)
              : ""
        }
      >
        {!addingNewSection ? (
          <Box
            width="100%"
            height="100%"
            padding="5px 8px"
            cursor="pointer"
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            gap="8px"
          >
            <FaPlus /> <Text>Add section</Text>
          </Box>
        ) : (
          <Input
            padding="5px 8px"
            borderRadius="8px"
            height="100%"
            width="100%"
            value={sectionName}
            colorScheme="white"
            onChange={(e) => setSectionName(e.target.value)}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        )}
      </Box>
      <Box
        borderRadius="8px"
        height="100%"
        background={getEmptySectionBackground(colorMode, sectionHovered)}
      ></Box>
    </Box>
  );
};

EmptySection.propTypes = {
  order: PropTypes.number,
};
