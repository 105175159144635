import { useCallback, useEffect, useState } from "react";
import {
  addArrayElementAction,
  removeArrayElementAction,
  updateArrayElementAction,
} from "../../../../../../../services/settings-service";

export const useFilters = (initialFilters, settingData, onFilterChange) => {
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    if (initialFilters) {
      setFilters(initialFilters);
    }
  }, [initialFilters]);

  const updateFilter = useCallback(
    async (name, type, values, shouldSave) => {
      setFilters((prev) =>
        [...prev].map((f) => {
          if (f.name === name) return { ...f, type, values };
          return f;
        }),
      );

      if (shouldSave === false) return null;

      const key = "use-filters-update-array-element-filters";
      const payload = {
        ...settingData,
        arrayName: "filters",
        locatorKey: "name",
        locatorValue: name,
        value: { name, type, values },
      };

      const res = await updateArrayElementAction(payload, key).catch(() => {});
      if (res) {
        const filters = JSON.parse(res.value).filters;
        // onFilterChange(filters);
        return filters;
      }

      return null;
    },
    [settingData],
  );

  const removeFilter = useCallback(
    async (name, shouldSave) => {
      setFilters((prev) => [...prev.filter((f) => f.name !== name)]);
      if (shouldSave === false) return null;

      const key = "use-filters-remove-array-element-filters";
      const payload = {
        ...settingData,
        arrayName: "filters",
        locatorKey: "name",
        locatorValue: name,
      };

      const res = await removeArrayElementAction(payload, key).catch(() => {});
      if (res) {
        const filters = JSON.parse(res.value).filters;
        // onFilterChange(filters);
        return filters;
      }

      return null;
    },
    [settingData],
  );

  const addFilter = useCallback(
    async (name, type, values, shouldSave) => {
      setFilters((prev) => [...prev, { name, values, type }]);
      if (shouldSave === false) return null;

      const key = "use-filters-add-array-element-filters";
      const payload = {
        ...settingData,
        arrayName: "filters",
        locatorKey: "name",
        locatorValue: name,
        value: { name, type, values },
      };

      const res = await addArrayElementAction(payload, key).catch(() => {});
      if (res) {
        const filters = JSON.parse(res.value).filters;
        // onFilterChange(filters);
        return filters;
      }

      return null;
    },
    [settingData],
  );

  const handleFilter = useCallback(
    async (name, type, values, shouldSave) => {
      const existing = filters.find((f) => f.name === name);

      if (
        !existing &&
        type === "contains" &&
        values.every((v) => v === null || v === undefined || v === "")
      ) {
        return null;
      }

      if (
        existing &&
        type === "contains" &&
        values.every((v) => v === null || v === undefined || v === "")
      ) {
        const res = await removeFilter(name, shouldSave);
        return res;
      }

      if (existing) {
        const res = await updateFilter(name, type, values, shouldSave);
        return res;
      } else {
        const res = await addFilter(name, type, values, shouldSave);
        return res;
      }
    },
    [addFilter, removeFilter, filters, updateFilter],
  );

  return { filters, handleFilter };
};
