import { formatDate } from "date-fns";

const getKeyFromObj = (obj, key) => {
  const keys = Object.keys(obj);
  return keys.find((k) => k.toLowerCase() === key.toLowerCase());
};

export const getTaskTitle = (obj) => {
  const titleKey = getKeyFromObj(obj, "title");
  return obj[titleKey];
};

export const getTaskId = (obj) => {
  const idKey = getKeyFromObj(obj, "id");
  return obj[idKey];
};

export const getTaskOrder = (obj) => {
  const order = getKeyFromObj(obj, "order");
  if (!order) return null;
  return obj[order];
};

export const getTaskCompleted = (obj) => {
  const isCompletedKey = getKeyFromObj(obj, "iscompleted");
  if (!isCompletedKey) return false;
  return obj[isCompletedKey];
};

export const getTaskSectionId = (obj) => {
  const sectionIdKey = getKeyFromObj(obj, "sectionid");
  return obj[sectionIdKey];
};

export const getTaskPriority = (obj) => {
  const priorityKey = getKeyFromObj(obj, "priority");
  if (!priorityKey) return null;
  return obj[priorityKey];
};

export const getTaskPriorityName = (obj) => {
  const priortyNameKey = getKeyFromObj(obj, "priorityname");
  if (!priortyNameKey) return "";
  return obj[priortyNameKey];
};

export const getTaskAssignedPerson = (obj) => {
  const assignedPersonKey = getKeyFromObj(obj, "assignedPerson");
  if (!assignedPersonKey) return "";
  return obj[assignedPersonKey];
};

export const getTaskDueDate = (obj) => {
  const dueDateKey = getKeyFromObj(obj, "plannedRealizationDate");
  if (!dueDateKey) return "";
  if (!obj[dueDateKey]) return "";
  return formatDate(new Date(obj[dueDateKey]), "dd-MM-yyyy");
};
