import { executeBlock } from "../../process-executor/process-executor";

const getCallback = (events, staticParams) => {
  const { name } = staticParams;

  if (name) {
    const event = events[name];
    if (event) return event;
  }

  const key = Object.keys(events)[0];
  return events[key];
};

const getProcess = (event, staticParams, params, ctx) => {
  const { name, ...restParams } = staticParams;

  const context = staticParams?.useRootContext ? event?.source?.context : ctx;
  const id = event?.id;
  const processParams = { ...restParams, callbackEventName: name } ?? {};
  const systemParams = {
    callbackEventName: event?.source?.name,
    source: event?.source,
    callerId: event?.source?.callerId,
    callerGuid: event?.source?.callerGuid,
    callerNodeId: event?.source?.callerNodeId,
  };

  return () => executeBlock(context, id, processParams, params, systemParams);
};

export const executeCallback = (block, componentContext) => {
  return {
    definition: block,
    execute: async (staticParams, params) => {
      const { component } = componentContext;

      if (!component) {
        throw new Error("Component cannot be null");
      }

      const { callbackEvents } = component;

      if (!callbackEvents || (callbackEvents && callbackEvents.length === 0))
        return staticParams;

      const callbackEvent = getCallback(callbackEvents, staticParams);

      const process = getProcess(
        callbackEvent,
        staticParams,
        params,
        componentContext,
      );

      await process();
      return staticParams;
    },
  };
};
