import React, { useContext } from "react";
import ComponentDesigner from "../echo-components/component-designer/component-designer";
import ComponentRenderer from "../echo-components/component-renderer/component-renderer";
import ComponentsPage from "../components-page/components-page";
import DiagramDesigner from "../diagram-designer/diagram-designer";
import ProcessListPage from "../process-page/process-list-page";
import UserList from "../user/user-list";
import PropTypes from "prop-types";
import EchoRouterContext from "../echo-router/echo-router-context";
import EchoRouterRoute from "../echo-router/echo-router-route";
import EchoRouterSwitch from "../echo-router/echo-router-switch";
import Dashboard from "../dashboard/dashboard";
import EchoRouterPageContext from "../echo-router/echo-router-page-context";

const EchoContentComponent = ({ routes, tiles, componentsRegistry }) => {
  const { getOpenedPages } = useContext(EchoRouterContext);
  const openedPages = getOpenedPages();

  return (
    <React.Fragment>
      <EchoRouterSwitch>
        <EchoRouterRoute unstacked path="/">
          <Dashboard tiles={tiles || []} />
        </EchoRouterRoute>
      </EchoRouterSwitch>
      {openedPages.map((page, index) => (
        <EchoRouterPageContext.Provider
          key={`page-${index}-${page.name}`}
          value={page}
        >
          <EchoRouterSwitch key={encodeURI(page.key)}>
            <EchoRouterRoute path={`/${encodeURI(page.key)}/user-list`}>
              <UserList />
            </EchoRouterRoute>
            <EchoRouterRoute
              exact
              path={`/${encodeURI(page.key)}/diagramDesigner`}
            >
              <DiagramDesigner />
            </EchoRouterRoute>
            <EchoRouterRoute
              path={`/${encodeURI(page.key)}/diagramDesigner/:id`}
            >
              <DiagramDesigner />
            </EchoRouterRoute>
            <EchoRouterRoute path={`/${encodeURI(page.key)}/blockList`}>
              <ProcessListPage />
            </EchoRouterRoute>
            <EchoRouterRoute
              exact
              path={`/${encodeURI(page.key)}/componentDesigner`}
            >
              <ComponentDesigner designerMode />
            </EchoRouterRoute>
            <EchoRouterRoute
              path={`/${encodeURI(page.key)}/componentDesigner/:id`}
            >
              <ComponentDesigner designerMode />
            </EchoRouterRoute>
            <EchoRouterRoute path={`/${encodeURI(page.key)}/componentList`}>
              <ComponentsPage />
            </EchoRouterRoute>
            {routes
              .filter((item) => item.componentId)
              .map((item) => (
                <EchoRouterRoute
                  key={`route-item-${item.id}`}
                  path={`/${encodeURI(page.key)}${
                    item.routePath.startsWith("/") ? "" : "/"
                  }${item.routePath}`}
                >
                  <ComponentRenderer
                    componentId={item.componentId}
                    componentsRegistry={componentsRegistry}
                  />
                </EchoRouterRoute>
              ))}
          </EchoRouterSwitch>
        </EchoRouterPageContext.Provider>
      ))}
    </React.Fragment>
  );
};

EchoContentComponent.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      routePath: PropTypes.string.isRequired,
      componentId: PropTypes.number.isRequired,
    }),
  ).isRequired,
  tiles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      icon: PropTypes.string,
      color: PropTypes.string,
      backgroundColor: PropTypes.string,
      hoverColor: PropTypes.string,
      hoverBackgroundColor: PropTypes.string,
      rowSpan: PropTypes.number,
      colSpan: PropTypes.number,
      path: PropTypes.string,
    }),
  ),
  componentsRegistry: PropTypes.shape({
    getComponent: PropTypes.func,
    updateComponent: PropTypes.func,
    register: PropTypes.func,
    unregister: PropTypes.func,
  }),
};

export default EchoContentComponent;
