import { baseGet, basePost } from "./base-fetch";

export const saveComponentSettings = (settings) =>
  basePost("Component/SaveComponentSettings", settings);

export const getComponentSettings = (opts) =>
  baseGet("Component/GetComponentSettings", opts);

export const deleteComponentSetting = (opts) =>
  basePost("Component/DeleteComponentSetting", {}, opts);

export const dispatchSettingAction = (action, payload, key) =>
  basePost(
    "Component/StoreSetting",
    { action, correlationKey: key, payload },
    {},
  );

export const dispatchBatchSettingAction = (action, payload, key) =>
  basePost(
    "Component/BatchStoreSettingActions",
    { action, correlationKey: key, payload },
    {},
  );

const DISPATCH_ACTIONS = {
  updateValue: "UPDATE_VALUE",
  updateArrayElement: "UPDATE_ARRAY_ELEMENT",
  updateValueInObject: "UPDATE_VALUE_IN_OBJECT",
  updateValueInArrayObject: "UPDATE_VALUE_IN_ARRAY_OBJECT",
  addValue: "ADD_VALUE",
  addArrayElement: "ADD_ARRAY_ELEMENT",
  addValueInObject: "ADD_VALUE_IN_OBJECT",
  addValueInArrayObject: "ADD_VALUE_IN_ARRAY_OBJECT",
  removeValue: "REMOVE_VALUE",
  removeArrayElement: "REMOVE_ARRAY_ELEMENT",
  removeValueInObject: "REMOVE_VALUE_IN_OBJECT",
  removeValueInArrayObject: "REMOVE_VALUE_IN_ARRAY_OBJECT",
};

export const updateValueAction = (payload, key) =>
  dispatchSettingAction(DISPATCH_ACTIONS.updateValue, payload, key);

export const updateArrayElementAction = (payload, key) =>
  dispatchSettingAction(DISPATCH_ACTIONS.updateArrayElement, payload, key);

export const updateValueInObjectAction = (payload, key) =>
  dispatchSettingAction(DISPATCH_ACTIONS.updateValueInObject, payload, key);

export const updateValueInArrayObjectAction = (payload, key) =>
  dispatchSettingAction(
    DISPATCH_ACTIONS.updateValueInArrayObject,
    payload,
    key,
  );

export const addValueAction = (payload, key) =>
  dispatchSettingAction(DISPATCH_ACTIONS.addValue, payload, key);

export const addArrayElementAction = (payload, key) =>
  dispatchSettingAction(DISPATCH_ACTIONS.addArrayElement, payload, key);

export const addValueInObjectAction = (payload, key) =>
  dispatchSettingAction(DISPATCH_ACTIONS.addValueInObject, payload, key);

export const addValueInArrayObjectAction = (payload, key) =>
  dispatchSettingAction(DISPATCH_ACTIONS.addValueInArrayObject, payload, key);

export const removeValueAction = (payload, key) =>
  dispatchSettingAction(DISPATCH_ACTIONS.removeValue, payload, key);

export const removeArrayElementAction = (payload, key) =>
  dispatchSettingAction(DISPATCH_ACTIONS.removeArrayElement, payload, key);

export const removeValueInObjectAction = (payload, key) =>
  dispatchSettingAction(DISPATCH_ACTIONS.removeValueInObject, payload, key);

export const removeValueInArrayObjectAction = (payload, key) =>
  dispatchSettingAction(
    DISPATCH_ACTIONS.removeValueInArrayObject,
    payload,
    key,
  );

export const updateManyValueInArrayObjectAction = (payload, key) =>
  dispatchBatchSettingAction(
    DISPATCH_ACTIONS.updateValueInArrayObject,
    payload,
    key,
  );

export const removeManyArrayElementAction = (payload, key) =>
  dispatchBatchSettingAction(DISPATCH_ACTIONS.removeArrayElement, payload, key);
