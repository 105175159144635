import { IconButton, Tr, useOutsideClick } from "@chakra-ui/react";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { MdCheck } from "react-icons/md";
import { CustomCheckbox } from "../checkbox/checkbox";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DisplayCellV2 } from "../cell/display-cell-v2";
import { Cell } from "../cell/cell";
import { DATAGRID_BODY_ROW_CELL_TESTID } from "../../utils/test-ids";
import { getId } from "../../utils/get-id";
import { getSelectedRowBackground } from "../../utils/theme-utils";
import { getRowValues } from "../../utils/get-row-values";
import { EditCellV2 } from "../cell/edit-cell-v2";
import { getResultData } from "../../utils/row-utils";

export const RowV2 = (props) => {
  const { row, style, onRowClick, onRowDoubleClick, onRowSelect, columns } =
    props;

  const {
    columns: defaultColumns,
    selectionMode,
    selectedItems,
    actions: { onRowEdit },
    enableEdit,
    rowFontSize,
    cellWhiteSpace,
    cellLinesNumber,
    settings,
  } = useDatagridContext();

  const [editMode, setEditMode] = useState(false);
  const [focused, setFocused] = useState();
  const [rowState, setRowState] = useState(() => getRowValues(row));

  const isChecked = (record, selectedItems) =>
    selectedItems?.find((item) => item == getId(record).value) ? true : false;

  const getBackground = (record, selectedItems, style) => {
    const checked = isChecked(record, selectedItems);
    return getSelectedRowBackground(checked, style?.background);
  };

  const handleRowClick = (e) => {
    if (editMode) return;
    if (!onRowClick) return;
    onRowClick();
  };

  const handleRowDoubleClick = (e) => {
    if (editMode) return;
    if (!onRowDoubleClick) return;
    onRowDoubleClick();
  };

  const handleCellClick = useCallback(
    (cell) => {
      if (!editMode && enableEdit && cell.editable) {
        setFocused(cell.order);
        setEditMode(true);
      }
    },
    [editMode],
  );

  const checkChanges = (oldState, newState) => {
    const keys = Object.keys(oldState);
    const boolArr = keys.map((k) => oldState[k] !== newState[k]);
    return boolArr.some((b) => b === true);
  };

  const handleRowEdit = () => {
    if (!onRowEdit || !enableEdit) return;
    const isChanged = checkChanges(getRowValues(row), rowState);
    if (isChanged) onRowEdit(rowState);
    setEditMode(false);
  };

  const rowRef = useRef();
  useOutsideClick({
    ref: rowRef,
    handler: () => {
      if (editMode && enableEdit) {
        handleRowEdit();
      }
    },
  });

  const handleCellValue = async (value, column) => {
    const { name } = column;
    setRowState((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (column.onChangeEvent) {
      const { onChangeEvent } = column;
      const state = { ...rowState, [name]: value };
      const res = await onChangeEvent(state);
      const result = getResultData(res);
      setRowState((prev) => ({ ...prev, ...result }));
    }
  };

  const getWhiteSpace = (lock) => (lock === false ? "normal" : "nowrap");

  return (
    <Tr
      ref={rowRef}
      onClick={handleRowClick}
      onDoubleClick={handleRowDoubleClick}
      style={{
        ...style,
        borderBottom: "1px solid black",
        background: getBackground(row, selectedItems, style),
      }}
    >
      {(selectionMode === "single" || selectionMode === "multiple") && (
        <Cell
          column={defaultColumns.find((col) => col.name === "checkbox")}
          width={defaultColumns.find((col) => col.name === "checkbox").width}
          key={"checkbox-body"}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "4px 8px",
          }}
          data-testid={DATAGRID_BODY_ROW_CELL_TESTID}
          onClick={(ev) => {
            ev.stopPropagation();
          }}
        >
          {editMode ? (
            <IconButton
              icon={<MdCheck />}
              onClick={handleRowEdit}
              variant="outline"
            />
          ) : (
            <CustomCheckbox
              isChecked={isChecked(row, selectedItems)}
              onChange={onRowSelect}
              value={getId(row).value}
            />
          )}
        </Cell>
      )}
      {Object.keys(row)
        .filter((k) =>
          columns.find((c) => c.name.toLowerCase() === k.toLowerCase()),
        )
        .sort(
          (a, b) =>
            parseInt(
              columns.find((c) => c.name.toLowerCase() === a.toLowerCase())
                .order,
            ) -
            parseInt(
              columns.find((c) => c.name.toLowerCase() === b.toLowerCase())
                .order,
            ),
        )
        .map((k) => {
          const editable = editMode && row[k].editable;
          const column = columns.find(
            (c) => c.name.toLowerCase() === k.toLowerCase(),
          );
          return !editable ? (
            <DisplayCellV2
              data={row[k]}
              column={column}
              onClick={(cell) =>
                handleCellClick({ ...cell, order: column.order })
              }
              onChange={
                row[k].editable
                  ? async (value) => {
                      await handleCellValue(value, column);
                      onRowEdit({ ...rowState, [k]: value });
                    }
                  : () => {}
              }
              style={{
                whiteSpace: getWhiteSpace(
                  settings?.lockRowHeight,
                  cellWhiteSpace,
                ),
                fontSize: rowFontSize,
                padding: "0 5px",
              }}
              key={`${k}-${getId(row).value}`}
            />
          ) : (
            <EditCellV2
              column={column}
              data={row[k]}
              value={rowState[k]}
              row={rowState}
              onChange={handleCellValue}
              autoFocus={column.order === focused}
              key={`${column.name}-${getId(row).value}-${k.toString()}`}
              data-testid={DATAGRID_BODY_ROW_CELL_TESTID}
              style={{ whiteSpace: cellWhiteSpace }}
            />
          );
        })}
    </Tr>
  );
};
