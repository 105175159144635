const moveBack = (col, startPos, newPos) => {
  if (
    parseInt(col.order) >= parseInt(newPos) &&
    parseInt(col.order) < parseInt(startPos)
  ) {
    return { ...col, order: parseInt(col.order) + 1 };
  }

  return col;
};

const moveForward = (col, startPos, newPos) => {
  if (
    parseInt(col.order) > parseInt(startPos) &&
    parseInt(col.order) <= parseInt(newPos)
  ) {
    return { ...col, order: parseInt(col.order) - 1 };
  }

  return col;
};

export const mapColumnOrder = (col, draggedItem) => {
  const { id, newPos, startPos } = draggedItem;

  if (id === col.name) {
    return { ...col, order: parseInt(newPos) };
  }

  if (parseInt(newPos) < parseInt(startPos)) {
    return moveBack(col, startPos, newPos);
  }

  if (parseInt(newPos) > parseInt(startPos)) {
    return moveForward(col, startPos, newPos);
  }

  return col;
};
