import {
  Box,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useRef } from "react";
import { FaRegCalendarAlt } from "react-icons/fa";
import PropTypes from "prop-types";
import { getSqlDateString, useDate } from "./hooks/use-date";
import { useDatepickerHandlers } from "./hooks/use-datepicker-handlers";
import { formatDateValue } from "./utils/format-date-value";
import { Calendar } from "./components/calendar";
import { useCalendar } from "./hooks/use-calendar";
import { mobileCheck } from "../../../utils";
import { Input } from "../input";
import { checkFormat } from "./utils/check-format";
import { format as formatDate, isMatch } from "date-fns";
import { pl } from "date-fns/locale";

const DatePicker = (props) => {
  const {
    id,
    name,
    date,
    style,
    onChange: onDateChange,
    isReadOnly,
    label,
    tabIndex,
    isDisabled,
    format,
    isRequired,
    isInvalid,
    autoFocus,
  } = props;
  const popoverRef = useRef(null);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const { isOpen, onClose, onToggle } = useDisclosure();

  const { isDate, isDateTime, isTime } = checkFormat(format);

  const {
    date: dateValue,
    time: timeValue,
    setDate,
    setTime,
  } = useDate(date, format);

  const dateFormat = useMemo(() => {
    const arr = format.split("");
    if (!format.toLowerCase().includes("h")) return format;

    const index = arr.findIndex((c) => c.toLowerCase() === "h");
    const withoutTime = arr.splice(0, index - 1).join("");
    return withoutTime;
  }, [format]);

  const isMobile = mobileCheck();

  const {
    onKeyDown,
    onChange,
    onSelect,
    onClick: onInputClick,
    onDateSelect,
    onPaste,
  } = useDatepickerHandlers(dateFormat, setDate);

  const handleKeyDown = (e) => onKeyDown(e);
  const handleSelect = (e) => onSelect(e);

  const handleOnClick = (e) => {
    if (isReadOnly) return;
    onInputClick(e);
  };

  const handlePaste = (e) => onPaste(e);

  const changeTimeoutRef = useRef();
  const handleDateChange = useCallback(
    (e) => {
      onChange(e);

      if (changeTimeoutRef.current) {
        clearTimeout(changeTimeoutRef.current);
      }

      const date = e.target.value;

      if (date === "") {
        onDateChange("");
        return;
      }

      const formatted = formatDateValue(e, date, dateFormat);
      const isValid = isMatch(formatted, dateFormat, { locale: pl });

      changeTimeoutRef.current = setTimeout(() => {
        if (isValid) {
          const sqlDateString = getSqlDateString(
            { date: formatted, time: timeValue },
            dateFormat,
          );
          onDateChange(sqlDateString);
        }
      }, 500);
    },
    [dateFormat, onChange, onDateChange, timeValue],
  );

  const handleOnDateSelected = (date) => {
    const isSelected = onDateSelect({ selectable: true, date });
    if (!isSelected) return;
    const formattedDate = dateFormat ? formatDate(date, dateFormat) : "";
    const sqlDateString = getSqlDateString(
      { date: formattedDate, time: "" },
      dateFormat,
    );
    onDateChange(sqlDateString);
    onClose();
  };

  const { currentDate, current, prev, next, onCalendarDateChange } =
    useCalendar();

  const calendarDays = [prev, current, next];

  const handleTimeChange = useCallback(
    (e) => {
      const time = e.target.value;
      const sqlDateString = getSqlDateString({ date: dateValue, time }, format);
      setTime(time);
      onDateChange(sqlDateString);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateValue, timeValue, format],
  );

  const styleModifier = {
    name: "zIndex",
    enabled: true,
    phase: "main",
    fn({ state }) {
      state.styles.popper["z-index"] = 9999999;
    },
  };

  const dateInputStyles = {
    ...style,
    marginRight: isDateTime ? "0" : "",
  };

  const timeInputStyles = {
    ...style,
    marginLeft: isDateTime ? "0" : "",
  };

  return (
    <Box
      style={{ order: style?.order }}
      display="flex"
      alignItems="center"
      gap="0"
      height={style?.height}
      data-testid="datepicker-trigger-test"
      ref={containerRef}
    >
      {(isDate || isDateTime) && (
        <Popover
          placement="bottom"
          isOpen={isOpen}
          onClose={onClose}
          initialFocusRef={popoverRef}
          modifiers={[styleModifier]}
          strategy="fixed"
        >
          <PopoverTrigger>
            <Input
              ref={inputRef}
              id={id}
              onSelect={handleSelect}
              onKeyDown={handleKeyDown}
              autoComplete="off"
              onPaste={handlePaste}
              isDisabled={isDisabled}
              label={label}
              onClick={handleOnClick}
              onDoubleClick={(e) => {
                if (!isMobile) e.target.setSelectionRange(0, dateValue.length);
              }}
              isReadOnly={isReadOnly || isMobile}
              name={name}
              value={dateValue}
              onChange={handleDateChange}
              isInvalid={isInvalid}
              isRequired={isRequired}
              data-testid="datepicker-input-test"
              style={dateInputStyles}
              icon={
                <IconButton
                  size="md"
                  tabIndex={tabIndex}
                  color={style?.color}
                  _focus={{ outline: "blue" }}
                  icon={<FaRegCalendarAlt />}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onToggle();
                  }}
                  variant="ghost"
                />
              }
              tabIndex={tabIndex}
              autoFocus={autoFocus}
            />
          </PopoverTrigger>
          <PopoverContent
            ref={popoverRef}
            width="100%"
            data-testid="datepicker-content-test"
          >
            <PopoverBody>
              {calendarDays && (
                <Calendar
                  calendarDays={calendarDays}
                  date={currentDate}
                  onDateChange={onCalendarDateChange}
                  onDateSelected={handleOnDateSelected}
                  selectedDates={[date]}
                />
              )}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
      {(isDateTime || isTime) && (
        <Input
          onClick={(e) => e.stopPropagation()}
          isDisabled={isDisabled}
          isInvalid={isInvalid}
          isRequired={isRequired}
          onChange={handleTimeChange}
          value={timeValue || "00:00"}
          style={{ ...timeInputStyles, width: "fit-content" }}
          type="time"
        />
      )}
    </Box>
  );
};

DatePicker.propTypes = {
  date: PropTypes.string,
  time: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
  isReadOnly: PropTypes.bool,
  label: PropTypes.string,
  tabIndex: PropTypes.number,
  isDisabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  format: PropTypes.string,
  isRequired: PropTypes.bool,
  isInvalid: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default DatePicker;
