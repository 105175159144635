import DesigneeProperties from "./side-panel/content-components/designee-properties/designee-properties";
import { withEchoComponentDesigner } from "../../../echo-component/function-wrapper/function-wrapper";
import StyleProperties from "./side-panel/content-components/style-properties/style-properties";
import DesignerShim from "../../shared/shim-components/designer-shim/designer-shim";
import Properties from "./side-panel/content-components/properites/properties";
import { useApi } from "../api-context/create-api-context/create-api-context";
import {
  useState,
  useReducer,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { getSetting, saveSetting } from "../../../services/user-services";
import EchoComponent from "../../shared/echo-components/echo-component";
import { COMPONENT_DESIGNER_SIDE_PANEL } from "../../../consts/const";
import EchoRouterContext from "../../echo-router/echo-router-context";
import Toolbox from "./side-panel/content-components/toolbox/toolbox";
import { findComponentParent } from "./utils/find-component-parent";
import DesignerButtons from "./designer-buttons/designer-buttons";
import EchoComponentsApiContext from "../api-context/api-context";
import { getStyle } from "./utils/get-designer-style-options";
import { useProperties } from "./hooks/use-properties";
import { useSidePanel } from "./hooks/use-side-panel";
import { useParams } from "../../echo-router/hooks";
import { theme } from "../../../theme/themeCaldo";
import { useToolbox } from "./hooks/use-toolbox";
import SidePanel from "./side-panel/side-panel";
import UserContext from "../../../user-context";
import usePromise from "react-use-promise";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  componentDesignerReducer,
  componentReducerInitialState,
} from "./component-designer-reducer";
import {
  getBaseComponentsList,
  getComponent,
  saveComponent,
} from "../../../services/components-service";
import { exportSchema, fillProcessRelations } from "./utils/import-export";
import { downloadText } from "../../../utils/download-text";
import { createEchoComponentContext } from "../../../echo-component/context/create-echo-component-context";
import { shimFunction } from "../../../utils/shims/shims";
import { getInitialComponentState } from "../component-renderer/reducer/component-state-reducer/component-state-reducer";
import EchoComponentContext from "../../../echo-component/context/echo-component-context";
import { Box, useDisclosure } from "@chakra-ui/react";
import { SaveComponentModal } from "./save-component-modal/save-component-modal";
import {
  getPropertyDefinitionById,
  saveComponentPropertyDefinition,
} from "../../../services/component-property-definition-service";
import { getAllElements } from "./utils/get-all-elements";
import { checkUniqueNames } from "./utils/check-unique-names";
import { handleSameElementsError } from "./utils/handle-same-elements-error";
import { usePage } from "../../echo-router/hooks/use-page";

const getHoverStyles = (modeCondition, highlightCondition) => ({
  borderColor: getStyle(
    theme.colors.redCa,
    "grey",
    modeCondition,
    highlightCondition,
  ),
  borderWidth: getStyle("3px", "1px", modeCondition, highlightCondition),
  borderStyle: getStyle("solid", "dashed", modeCondition, highlightCondition),
});

const ComponentDesigner = ({ designerMode: designerModeInit = true }) => {
  const designerRef = useRef();
  const { id } = useParams();
  const echoRouterContext = useContext(EchoRouterContext);
  const { closePage } = echoRouterContext;
  const [callback, setCallback] = useState(null);
  const { page } = usePage();
  const {
    isOpen: isTemplateModalOpened,
    onOpen: onTemplateModalOpen,
    onClose: onTemplateModalClose,
  } = useDisclosure();

  const {
    isOpen: isModalOpened,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  useEffect(() => {
    const { state: params } = page;

    if (params?.callback) {
      setCallback(() => params.callback);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, setCallback]);

  const [designerState, dispatchState] = useReducer(
    componentDesignerReducer,
    componentReducerInitialState,
  );

  const routerContext = useContext(EchoRouterContext);
  const userContext = useContext(UserContext);

  const componentContext = createEchoComponentContext(
    getInitialComponentState(),
    shimFunction,
    {
      ...designerState.componentInfo,
      childrenComponents: [designerState.componentData],
    },
    () => {},
    routerContext,
    userContext,
    undefined,
    null,
    () => {},
  );

  const [state, setState] = useState({
    dropAreaId: 0,
    dragMode: false,
    mode: "designer",
    activePropertiesComponentId: null,
  });

  const setActivePropertiesComponentId = (newActivePropertiesComponentId) =>
    setState({
      ...state,
      activePropertiesComponentId: newActivePropertiesComponentId,
    });

  const [apiContext] = useApi(
    {
      ...designerState.componentInfo,
      childrenComponents: [designerState.componentData],
    },
    echoRouterContext,
    dispatchState,
    state,
    setState,
  );

  const changeMode = (newMode) => setState({ ...state, mode: newMode });

  useEffect(() => {
    changeMode(designerModeInit ? "designer" : "renderer");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [designerModeInit]);

  const activeAreaId = state.dropAreaId;
  const setActiveAreaId = (dropAreaId) => setState({ ...state, dropAreaId });

  const { userId } = useContext(UserContext);

  const [sidePanelData] = usePromise(
    () => getSetting(userId, null, COMPONENT_DESIGNER_SIDE_PANEL),
    [],
  );

  const initComponent = async (component) => {
    if (component.source === "!?echo-defined") {
      return initEchoDefinedComponent(component);
    } else {
      return initSystemComponent(component);
    }
  };

  const initSystemComponent = async (component) => {
    const properties = await getPropertyDefinitionById(component.id);
    setActivePropertiesComponentId(component.id);
    return dispatchState({
      type: "INIT_COMPONENT",
      componentInfo: {
        id: component.id,
        name: component.name,
        source: component.source,
        type: component.type,
        displayName: component.displayName,
        componentPurpose: component.componentPurpose,
        description: component.description,
        availableStyleOptions: component.availableStyleOptions,
        crudActions: component.crudActions,
        crudCreate: component.crudCreate,
        crudRead: component.crudRead,
        crudUpdate: component.crudUpdate,
        crudDelete: component.crudDelete,
        relatedObjectId: component.relatedObjectId,
        tabLabelFormat: component.tabLabelFormat,
        properties: properties,
      },
      componentData: {
        ...component,
        component,
        childrenComponents: [],
      },
    });
  };

  const initEchoDefinedComponent = (component) => {
    const firstChild = component.childrenComponents[0];

    return dispatchState({
      type: "INIT_COMPONENT",
      componentInfo: {
        id: component.id,
        name: component.name,
        source: component.source,
        type: component.type,
        displayName: component.displayName,
        componentPurpose: component.componentPurpose,
        description: component.description,
        availableStyleOptions: component.availableStyleOptions,
        crudActions: component.crudActions,
        crudCreate: component.crudCreate,
        crudRead: component.crudRead,
        crudUpdate: component.crudUpdate,
        crudDelete: component.crudDelete,
        relatedObjectId: component.relatedObjectId,
        tabLabelFormat: component.tabLabelFormat,
        properties: component.properties,
      },
      componentData: {
        ...firstChild,
        childrenComponents: [...firstChild.childrenComponents],
      },
    });
  };

  useEffect(() => {
    if (id) {
      getComponent({ id })
        .then(async (result) => {
          const component = await initComponent(result);
          return component;
        })
        .catch(() => toast.error("Error while loading component data"));
    }
  }, [id]);

  // component designer hooks
  const [dragMode, toolboxProps] = useToolbox(
    (definition, defaultProps) =>
      dispatchState({
        type: "DROP_COMPONENT",
        definition,
        defaultProps,
        activeAreaId,
      }),
    designerState.toolboxComponents,
    setActiveAreaId,
    designerState?.componentInfo,
    state.dragMode,
    (newDragMode) => setState({ ...state, dragMode: newDragMode }),
  );

  useEffect(() => {
    getBaseComponentsList("Component", true).then((res) =>
      dispatchState({
        type: "UPDATE_TOOLBOX_COMPONENTS",
        toolboxComponents: res,
      }),
    );
  }, []);

  const [propertiesProps] = useProperties(
    state.activePropertiesComponentId,
    "componentProps",
    designerState.componentData,
    (id, action) => handlePropertiesAction(id, action),
    (model) =>
      dispatchState({
        type: "UPDATE_COMPONENT_PROPERTY",
        selectedComponent: state.activePropertiesComponentId,
        model,
        propertyName: "componentProps",
      }),
  );

  const [stylePropertiesProps] = useProperties(
    state.activePropertiesComponentId,
    "styleOptions",
    designerState.componentData,
    (id, action) => handlePropertiesAction(id, action),
    (model) =>
      dispatchState({
        type: "UPDATE_COMPONENT_PROPERTY",
        selectedComponent: state.activePropertiesComponentId,
        model,
        propertyName: "styleOptions",
      }),
  );

  const handlePropertiesAction = (id, action) => {
    switch (action.type) {
      case "DELETE":
        dispatchState({ type: "REMOVE_ELEMENT", id });
        break;
      case "GO_TO_PARENT":
        // eslint-disable-next-line no-case-declarations
        setActivePropertiesComponentId(
          findComponentParent(designerState?.componentData, id),
        );
        break;
      case "GO_TO":
        // eslint-disable-next-line no-case-declarations
        setActivePropertiesComponentId(action.id);
        break;
    }
  };

  const saveSystemComponent = async (data, callback) => {
    if (data.properties.length > 0) {
      await saveComponentPropertyDefinition(
        data.id,
        data.properties.map((p) => ({
          ...p,
          id: typeof p.id === "number" ? p.id : null,
          componentId: data.id,
        })),
      );
    }
    await saveComponent({
      ...data,
      childrenComponents: [],
    })
      .then(() => {
        toast.success("Successfully saved");

        if (callback) {
          Promise.all([callback(), closePage()]);
        } else {
          closePage();
        }
      })
      .catch(() => toast.error("Error while saving component"));
  };

  const saveEchoDefinedComponent = async (data, callback) => {
    if (data.componentInfo.properties?.length > 0) {
      await saveComponentPropertyDefinition(
        data.componentInfo.id,
        data.componentInfo.properties.map((p, idx) => ({
          ...p,
          id: typeof p.id === "number" ? p.id : null,
          componentId: data.componentInfo.id,
          order: p.order ?? idx,
          isSystem: p.isSystem ?? 0,
        })),
      );
    }

    await saveComponent({
      ...data.componentInfo,
      childrenComponents: [data.componentData],
    })
      .then(() => {
        toast.success("Successfully saved");

        if (callback) {
          Promise.all([callback(), closePage()]);
        } else {
          closePage();
        }
      })
      .catch(() => toast.error("Error while saving component"));
  };

  const handleSave = (data, callback) => {
    const elements = getAllElements(data.componentData);
    const sameElements = checkUniqueNames(elements);

    if (sameElements.length > 0) {
      handleSameElementsError(sameElements);
      return;
    }

    if (data.componentInfo.source === "!?echo-defined") {
      saveEchoDefinedComponent(data, callback);
    } else {
      const { componentData, componentInfo } = data;
      const { styleOptions, componentProps } = componentData;
      const component = { ...componentInfo, styleOptions, componentProps };
      saveSystemComponent(component, callback);
    }
  };

  const handleButtonsAction = useCallback(
    (action) => {
      switch (action.type) {
        case "update-structure": {
          dispatchState({
            type: "UPDATE_STRUCTURE",
            newStructure: action.newStructure,
          });
          break;
        }
        case "save_as_template": {
          const hasProperties = Object.keys(action.payload).length > 0;
          if (hasProperties) {
            onTemplateModalOpen();
          } else {
            toast.info("No component selected");
          }
          break;
        }
        case "save_as_new_component": {
          const hasProperties = Object.keys(action.payload).length > 0;
          if (hasProperties) {
            onModalOpen();
          } else {
            toast.info("No component selected");
          }
          break;
        }
        case "save":
          if (
            designerState?.componentInfo?.name !== "" &&
            !designerState?.componentInfo?.name !== null
          ) {
            handleSave(designerState, callback);
          } else {
            toast.warning('Please fill required fields in "Component" tab');
          }
          break;
        case "export":
          if (
            designerState?.componentInfo?.name !== "" &&
            !designerState?.componentInfo?.name !== null
          ) {
            var schemaJson = exportSchema({
              ...designerState.componentInfo,
              childrenComponents: [designerState.componentData],
            });

            fillProcessRelations(schemaJson)
              .then((result) =>
                downloadText(
                  result,
                  encodeURI(designerState?.componentInfo?.name),
                  "json",
                ),
              )
              .catch(window.console.error);
          } else {
            toast.warning('Please fill required fields in "Component" tab');
          }
          break;
        case "undo":
        case "redo":
          dispatchState({
            type: action.type.toUpperCase(),
          });
          break;
      }
    },
    [
      callback,
      closePage,
      designerState.componentData,
      designerState.componentInfo,
    ],
  );

  const panelComponents = [
    {
      name: "toolbox",
      component: <Toolbox {...toolboxProps} />,
    },
    {
      name: "properties",
      component: (
        <Properties
          modelSchema={designerState.modelSchema}
          designerPortalRef={designerRef}
          onModelSchemaChange={(modelSchema) =>
            dispatchState({ type: "UPDATE_MODEL_SCHEMA", modelSchema })
          }
          {...propertiesProps}
          componentInfo={designerState.componentInfo}
        />
      ),
    },
    {
      name: "style-properties",
      component: <StyleProperties {...stylePropertiesProps} />,
    },
    {
      name: "designee-properties",
      component: (
        <DesigneeProperties
          model={designerState.componentInfo}
          onModelChange={(model) =>
            dispatchState({
              type: "UPDATE_COMPONENT_INFO",
              model,
            })
          }
        />
      ),
    },
  ];

  const [sidePanelProps] = useSidePanel(
    sidePanelData?.value ? JSON.parse(sidePanelData.value) : null,
    panelComponents,
    (newData) =>
      saveSetting({
        ...sidePanelData,
        userId,
        key: COMPONENT_DESIGNER_SIDE_PANEL,
        value: JSON.stringify(newData),
      }),
    dragMode,
  );

  const [moveButtons, setMoveButtons] = useState(false);

  return (
    <EchoComponentContext.Provider value={componentContext}>
      <EchoComponentsApiContext.Provider value={apiContext}>
        <Box
          boxSizing="border-box"
          position="relative"
          minHeight={0}
          display="flex"
          flex={1}
          overflow="auto"
          width="100%"
        >
          <SidePanel {...sidePanelProps} setMoveButtons={setMoveButtons}>
            <Box
              ref={designerRef}
              position="absolute"
              top={0}
              left={0}
              height="100%"
              width="100%"
              className="container-modal"
            />
            <DesignerButtons
              designerPortalRef={designerRef}
              onAction={handleButtonsAction}
              componentStructure={designerState?.componentData}
              selectedComponent={propertiesProps.component}
              moveButtons={moveButtons}
            />
            {isTemplateModalOpened && (
              <SaveComponentModal
                isOpen={isTemplateModalOpened}
                onClose={onTemplateModalClose}
                selectedComponent={propertiesProps.component}
                setActivePropertiesComponentId={setActivePropertiesComponentId}
                componentType="Template"
              />
            )}
            {isModalOpened && (
              <SaveComponentModal
                isOpen={isModalOpened}
                onClose={onModalClose}
                selectedComponent={propertiesProps.component}
                setActivePropertiesComponentId={setActivePropertiesComponentId}
                componentType="Component"
              />
            )}
            <Box
              display="flex"
              flex={1}
              minHeight={0}
              boxSizing="border-box"
              width="100%"
            >
              {designerState.componentData ? (
                withEchoComponentDesigner(
                  <EchoComponent
                    source={designerState.componentData.component.source}
                    styleOptions={{
                      ...designerState.componentData.styleOptions,
                      ...getHoverStyles(
                        state.mode === "designer",
                        state.activePropertiesComponentId ===
                          designerState.componentData.id,
                      ),
                    }}
                    componentProps={designerState.componentData.componentProps}
                    systemProps={{
                      id: designerState.componentData.id,
                      guid: designerState.componentData.guid,
                      nodeId: designerState.componentData.nodeId,
                      depth: designerState.componentData.depth,
                      dragMode,
                      activeAreaId,
                      designerMode: state.mode === "designer",
                      styleOptions: {
                        ...designerState.componentData.styleOptions,
                        ...getHoverStyles(
                          state.mode === "designer",
                          state.activePropertiesComponentId ===
                            designerState.componentData.id,
                        ),
                      },
                      onMoveElement: (elem) =>
                        dispatchState({
                          type: "MOVE_COMPONENT",
                          id: elem.id,
                          newParentId: activeAreaId,
                        }),
                      childs: designerState.componentData.childrenComponents,
                      activePropertiesComponentId:
                        state.activePropertiesComponentId,
                      onClick: () =>
                        setActivePropertiesComponentId(
                          designerState.componentData.id,
                        ),
                      onComponentClick: setActivePropertiesComponentId,
                      onDropAreaChange: setActiveAreaId,
                      onMouseOver: () => {
                        Promise.all([
                          setActiveAreaId(designerState.componentData.id),
                        ]);
                      },
                      path: "",
                    }}
                  />,
                )
              ) : (
                <DesignerShim dragMode={dragMode} />
              )}{" "}
            </Box>
          </SidePanel>
        </Box>
      </EchoComponentsApiContext.Provider>
    </EchoComponentContext.Provider>
  );
};

ComponentDesigner.propTypes = {
  designerMode: PropTypes.bool,
};

export default ComponentDesigner;
