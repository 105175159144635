import PropTypes from "prop-types";
import { Box, IconButton } from "@chakra-ui/react";
import { FiLock, FiMenu, FiUnlock } from "react-icons/fi";
import { forwardRef, useCallback, useContext, useEffect, useMemo } from "react";
import EchoRouterContext from "../echo-router/echo-router-context";
import { Menu as UIMenu } from "@echo/ui";
import { MenuResizer } from "./menu-resizer";
import { useMenuResize } from "./hooks/use-menu-resize";
import { useMenuSettings } from "./hooks/use-menu-settings";

const Menu = forwardRef(
  (
    {
      menu,
      isOpen,
      onToggle,
      isLocked,
      onToggleLock,
      openWidth = 200,
      closeWidth = 26,
    },
    ref,
  ) => {
    const menuItems = useMemo(() => menu ?? [], [menu]);

    const { openPage } = useContext(EchoRouterContext);

    const handleOnSelect = useCallback(
      (_, { key, path }) => {
        if (key && path) {
          openPage(
            path,
            null,
            menuItems.find((item) => item.key === key)?.displayName,
            true,
          );
        }
      },
      [menuItems, openPage],
    );

    const handleToggle = useCallback(() => {
      onToggle();
    }, [onToggle]);

    const handleToggleLock = useCallback(() => {
      onToggleLock();
    }, [onToggleLock]);

    const handleMouseOver = useCallback(() => {
      if (!isLocked && !isOpen) {
        onToggle();
      }
    }, [isLocked, isOpen, onToggle]);

    const handleMouseLeave = useCallback(() => {
      if (!isLocked && isOpen) {
        onToggle();
      }
    }, [isLocked, isOpen, onToggle]);

    const [width, setWidth] = useMenuSettings(openWidth);
    const { menuRef, resizing, onMouseUp, onMouseDown } = useMenuResize(
      width,
      setWidth,
    );

    useEffect(() => {
      if (menuRef.current) {
        if (isOpen || isLocked)
          menuRef.current.style.width = `${width || openWidth}px`;
        else menuRef.current.style.width = `${closeWidth}px`;
      }
    }, [closeWidth, isLocked, isOpen, menuRef, openWidth, width]);

    return (
      <Box
        ref={menuRef}
        borderRight="1px solid var(--chakra-colors-grayCa)"
        boxSizing="border-box"
        background="linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(234,44,38,0.6712885837928921) 0%, rgba(67,18,82,1) 100%, rgba(255,255,255,1) 100%)"
        backgroundColor="rgb(2,0,36)"
        color="#fff7ff"
        height="100%"
        display="flex"
        // width={isOpen || isLocked ? `${width}px` : `${closeWidth}px`}
        flexFlow="column"
        transition={resizing ? "none" : "width 200ms ease-in-out"}
        userSelect={resizing ? "none" : "auto"}
        onMouseLeave={handleMouseLeave}
        onMouseOver={handleMouseOver}
        position="relative"
      >
        <Box
          display="flex"
          height="30px"
          flexShrink={0}
          justifyContent="flex-end"
          borderBottom="1px solid var(--chakra-colors-grayCa)"
        >
          <IconButton color="white" onClick={handleToggleLock} variant="ghost">
            {isLocked ? <FiLock /> : <FiUnlock />}
          </IconButton>
          <IconButton
            color="white"
            onClick={handleToggle}
            disabled={!isLocked}
            variant="ghost"
          >
            <FiMenu />
          </IconButton>
        </Box>
        <Box
          padding="4px 0px"
          display="flex"
          overflowY="auto"
          flexGrow={1}
          minHeight={0}
          flexDirection="column"
          boxSizing="border-box"
        >
          <Box
            ref={ref}
            flexDirection="column"
            width="100%"
            alignItems="stretch"
            boxSizing="border-box"
            display={isOpen || isLocked ? "flex" : "none"}
          >
            <UIMenu
              onSelect={handleOnSelect}
              activePath={window.location.href?.split("/").slice(-1).pop()}
              data={menuItems}
              color="#fff7ff"
            />
          </Box>
        </Box>
        {(isOpen || isLocked) && (
          <MenuResizer onMouseDown={onMouseDown} onMouseUp={onMouseUp} />
        )}
      </Box>
    );
  },
);

Menu.displayName = "Menu";

Menu.propTypes = {
  menu: PropTypes.any,
  onToggle: PropTypes.func.isRequired,
  isLocked: PropTypes.bool,
  openWidth: PropTypes.number,
  closeWidth: PropTypes.number,
  onToggleLock: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default Menu;
