import { Box, ListItem, Tooltip } from "@chakra-ui/react";
import { DragItem } from "@echo/draggable";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

export const FullDayEvent = ({
  id,
  title,
  background,
  sectionId,
  onDrop,
  onClick,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      const scrollWidth = contentRef.current.scrollWidth;
      const clientWidth = contentRef.current.clientWidth;
      if (scrollWidth > clientWidth) {
        setShowTooltip(true);
      } else {
        setShowTooltip(false);
      }
    }
  }, [contentRef]);

  return (
    <DragItem id={id} position={sectionId} onDrop={onDrop}>
      <ListItem
        borderRadius="8px"
        padding="1px 8px"
        display="flex"
        alignItems="center"
        fontSize=".8rem"
        background={background}
        onClick={onClick}
      >
        <Tooltip
          isDisabled={!showTooltip}
          openDelay={1000}
          label={title}
          padding="2px 8px"
        >
          <Box
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            width="100%"
            // display="block"
            ref={contentRef}
          >
            {title}
          </Box>
        </Tooltip>
      </ListItem>
    </DragItem>
  );
};

FullDayEvent.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  background: PropTypes.string,
  sectionId: PropTypes.number,
  onDrop: PropTypes.func,
  onClick: PropTypes.func,
};
