import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../local-storage/local-storage";
import { localStorageKeys } from "../local-storage/local-storage-keys";

export const addToShown = (id) => {
  const storedShown = getLocalStorageItem(localStorageKeys.SHOWN_NOTIFICATIONS);

  if (storedShown && storedShown.length > 0) {
    const data = [...storedShown, id];
    setLocalStorageItem(localStorageKeys.SHOWN_NOTIFICATIONS, data);
    return;
  }

  setLocalStorageItem(localStorageKeys.SHOWN_NOTIFICATIONS, [id]);
};
