import { useEffect, useState } from "react";
import { showErrorToast } from "../../../../../echo-error-toast";
import { getKanbanData } from "../utils/get-kanban-data";
import { mapItemOrder } from "../utils/order-change";
import { getTaskOrder } from "../utils/task-utils";
import { mapObjPropertiesToCamelCase } from "../utils/obj-to-camel-case";

export const useTasksDataSource = (props, component) => {
  const { tasksDataSource, context } = props;
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState([]);

  const getData = async (dataSource, context, component) => {
    try {
      const res = await getKanbanData(dataSource, context, component);
      // probably temporary solution, we will see what to do with that, maybe delete later
      const sorted = res.sort((a, b) => getTaskOrder(a) - getTaskOrder(b));
      const mapped = sorted.map((t, idx) => ({ ...t, order: idx }));
      const camelCaseList = mapped.map(mapObjPropertiesToCamelCase);
      setTasks(camelCaseList);
      setLoading(false);
    } catch (e) {
      showErrorToast(e);
    }
  };

  const onItemAdd = async (item) => {
    if (props.onItemAdd && typeof props.onItemAdd === "function") {
      await props.onItemAdd(item);
      getData(tasksDataSource, context, component);
      // left for future me, I will come back here
      // setTasks((prev) => [
      //   { ...item, id: id ? id : item.id, order: 0 },
      //   ...prev.map((t) => ({ ...t, order: t.order + 1 })),
      // ]);
    }
  };

  const onItemChange = (item) => {
    if (props.onItemChange && typeof props.onItemChange === "function") {
      props.onItemChange(item);
      setTasks((prev) => prev.map((el) => (el.id === item.id ? item : el)));
    }
  };

  const onItemMove = (item, newSection) => {
    if (props.onItemChange && typeof props.onItemChange === "function") {
      const f = tasks.find((t) => t.id === item.id);
      const task = {
        ...f,
        order: item.newPos,
        sectionId:
          newSection && newSection !== f.sectionId ? newSection : f.sectionId,
      };

      props.onItemChange(task);

      setTasks((prev) => {
        const f = prev.find((i) => item.id === i.id);
        if (!f) return prev;

        if (f.sectionId !== newSection) {
          if (newSection === null || newSection === undefined) return prev;
          return [
            ...prev.map((i) =>
              i.id === f.id ? { ...f, sectionId: newSection } : i,
            ),
          ];
        }

        return [...prev.map((i) => mapItemOrder(i, item))];
      });
    }
  };

  const onItemClick = (item) => {
    if (props.onItemClick && typeof props.onItemClick === "function") {
      props.onItemClick(item);
    }
  };

  useEffect(() => {
    if (tasksDataSource?.id) {
      getData(tasksDataSource, context, component);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksDataSource?.id]);

  return {
    tasks,
    loading,
    actions: { onItemAdd, onItemChange, onItemMove, onItemClick },
  };
};
