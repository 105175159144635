// @flow

import React, { useEffect, useMemo, useState } from "react";
import { Box, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { ContextMenu } from "@echo/ui";
import { useContextMenu } from "@echo/ui/src/lib/components/context-menu/hooks/useContextMenu";
import { CustomCheckbox } from "../checkbox/checkbox";
import { useRef } from "react";
import {
  DATAGRID_HEADER_ROW_CELL_TESTID,
  DATAGRID_HEADER_ROW_TESTID,
  DATAGRID_HEADER_TESTID,
} from "../../utils/test-ids";
import { getId } from "../../utils/get-id";
import { FilterRow } from "./filter-row";
import { useDragContext } from "../../hooks/useDragContext";
import { HeaderCell } from "../cell/header-cell/header-cell";
import {
  getBackground,
  getHeaderBackground,
  getTextColor,
} from "../../utils/theme-utils";
import { mapColumnOrder } from "../cell/header-cell/utils";
import { DragItem, DragSection } from "@echo/draggable";

export const Header = ({ columns }): React.Node => {
  const {
    data,
    disableFilters,
    actions: { onSelectAll },
    selectedItems,
    selectionContextActions,
    contextActions,
    selectionMode,
    theme,
  } = useDatagridContext();

  const {
    isContextMenuOpened,
    openContextMenu,
    closeContextMenu,
    clickPosition,
  } = useContextMenu();

  const {
    isContextMenuOpened: isHeaderContextOpened,
    openContextMenu: openHeaderContextMenu,
    closeContextMenu: closeHeaderContextMenu,
    clickPosition: headerClickPosition,
  } = useContextMenu();

  const { containerRef } = useDragContext();

  const handleChecked = (e) => {
    if (!onSelectAll) return;

    const isChecked = e.target.checked;
    const idArr = data.map((el) => getId(el)?.value.toString());

    if (isChecked) onSelectAll(idArr, "select");
    if (!isChecked) onSelectAll(idArr, "unselect");
  };

  const dataIdArr = useMemo(
    () => data.map((i) => getId(i)?.value.toString()),
    [data],
  );

  const isAllChecked = useMemo(
    () =>
      selectedItems?.length > 0 &&
      dataIdArr.filter((d_id) => !selectedItems?.find((s_id) => d_id == s_id))
        .length === 0,
    [dataIdArr, selectedItems],
  );

  const headerContextActions = useMemo(() => {
    if (!contextActions) return [];
    if (contextActions.length === 0) return [];
    return contextActions.filter((a) => a?.position?.includes("header"));
  }, [contextActions]);

  const ref = useRef();

  const [placeholderColumn, setPlaceholderColumn] = useState();

  const columnsWithPlaceholder = useMemo(() => {
    if (!placeholderColumn) return columns;
    const { id, newPos, startPos } = placeholderColumn;

    const newColumns = columns.map((c) => {
      const newColumn = c.name === id ? { ...c, placeholder: true } : c;
      return mapColumnOrder(newColumn, placeholderColumn);
    });

    return newColumns.sort((a, b) => parseInt(a.order) - parseInt(b.order));
  }, [placeholderColumn, columns]);

  return (
    <Thead
      minHeight="fit-content"
      width="100%"
      display="block"
      position="sticky"
      top="0"
      zIndex="1"
      data-testid={DATAGRID_HEADER_TESTID}
      // color={getTextColor(theme)}
      // background={getHeaderBackground(theme)}
      // overflow="hidden"
    >
      {isContextMenuOpened && selectionContextActions && (
        <ContextMenu
          isOpen={isContextMenuOpened}
          onClose={closeContextMenu}
          clickPosition={clickPosition}
          actions={selectionContextActions}
        />
      )}
      {isHeaderContextOpened && headerContextActions && (
        <ContextMenu
          isOpen={isHeaderContextOpened}
          onClose={closeHeaderContextMenu}
          clickPosition={headerClickPosition}
          actions={headerContextActions}
        />
      )}
      <DragSection id="table-header" context="datagrid">
        <Tr
          ref={ref}
          data-testid={DATAGRID_HEADER_ROW_TESTID}
          onContextMenu={openHeaderContextMenu}
          borderBottom="1px solid black"
        >
          {columnsWithPlaceholder.map((col) =>
            col.name === "checkbox" ? (
              selectionMode !== "none" && (
                <HeaderCell
                  column={col}
                  width={col.width}
                  index={col.order}
                  key={col.name}
                  data-testid={DATAGRID_HEADER_ROW_CELL_TESTID}
                  onContextMenu={openContextMenu}
                >
                  <CustomCheckbox
                    isChecked={isAllChecked}
                    onChange={handleChecked}
                  />
                </HeaderCell>
              )
            ) : (
              <HeaderCell
                column={col}
                width={col.width}
                index={col.order}
                key={col.name}
                data-testid={DATAGRID_HEADER_ROW_CELL_TESTID}
                setPlaceholderColumn={setPlaceholderColumn}
              >
                {placeholderColumn && col.placeholder
                  ? ""
                  : col.displayName || col.name}
              </HeaderCell>
            ),
          )}
        </Tr>
      </DragSection>
      {!disableFilters && <FilterRow columns={columns} />}
    </Thead>
  );
};
