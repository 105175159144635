import { dataFilter } from "../data-filter";
import { getOrderBy } from "../data-view-utils";

export const statePropDataLoader = ({ value, state }) =>
  new Promise((resolve, reject) => {
    try {
      //TODO debounce
      // eslint-disable-next-line no-case-declarations
      const cData = [...(value ?? [])]
        .orderBy(getOrderBy(state.sort))
        .filter((item) => dataFilter(state.globalFilter, item))
        .slice(
          state.page * 20,
          state.page * state.itemsPerPage + state.itemsPerPage,
        );
      resolve({
        type: "SET_DATA",
        data: cData,
        source: value,
        count: cData.length,
      });
    } catch (err) {
      reject(err);
    }
  });
