const moveBack = (item, startPos, newPos) => {
  if (
    parseInt(item.order) >= parseInt(newPos) &&
    parseInt(item.order) < parseInt(startPos)
  ) {
    return { ...item, order: parseInt(item.order) + 1 };
  }

  return item;
};

const moveForward = (item, startPos, newPos) => {
  if (
    parseInt(item.order) > parseInt(startPos) &&
    parseInt(item.order) <= parseInt(newPos)
  ) {
    return { ...item, order: parseInt(item.order) - 1 };
  }

  return item;
};

export const mapItemOrder = (item, draggedItem) => {
  const { id, newPos, startPos } = draggedItem;

  if (newPos === null || newPos === undefined) return item;

  if (id === item.id) {
    return { ...item, order: parseInt(newPos) };
  }

  if (parseInt(newPos) < parseInt(startPos)) {
    return moveBack(item, startPos, newPos);
  }

  if (parseInt(newPos) > parseInt(startPos)) {
    return moveForward(item, startPos, newPos);
  }

  return item;
};
