import { executeBlock } from "../../../../../../../process-executor/process-executor";

const getSort = (sortArr) => {
  if (!sortArr || sortArr?.length < 1) return {};

  const sortObj = sortArr.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.name]: curr.value,
    }),
    {},
  );

  return sortObj;
};

const convertDatabaseType = (type) => {
  switch (type?.toLowerCase()) {
    case "bit":
      return "boolean";
    case "date":
    case "datetime":
      return "datetime";
    case "tinyint":
    case "int":
    case "numeric":
    case "decimal":
      return "number";
    default:
      return "string";
  }
};

const getColumnsFromObj = (obj) =>
  Object.keys(obj)
    .filter((col) => col !== "RecCount")
    .map((c) => ({
      name: c,
      type: "string",
    }));

const getColumnsFromData = (data) =>
  data[0] ? getColumnsFromObj(data[0]) : [];

const mapColumn = (c) => ({
  name: c.columnName,
  type: convertDatabaseType(c.dataTypeName),
  isNull: c.allowDBNull,
});

const resolveDataResult = (res) => {
  if (!res) return { source: "", data: [], itemsCount: 0, columns: [] };

  if (res.data) {
    const {
      data,
      metadata: { itemsCount, columns, page },
      summary,
    } = res;

    const defaultColumns = columns
      ?.map(mapColumn)
      ?.filter((c) => c.name !== "RecCount");

    const mappedData = data?.map((r) => {
      // eslint-disable-next-line no-unused-vars
      const { RecCount, ...rest } = r;
      return rest;
    });

    return {
      source: res,
      data: mappedData,
      itemsCount: itemsCount,
      columns: defaultColumns,
      page,
      summary,
    };
  }

  const isArray = Array.isArray(res);

  if (isArray) {
    const columns = getColumnsFromData(res);
    return { source: res, data: res, itemsCount: res.length, columns };
  }

  return {
    source: res,
    data: [res],
    itemsCount: 1,
    columns: getColumnsFromObj(res),
  };
};

export const fetchData = async (context, dataSource, settings, name) => {
  const res = await executeBlock(
    context,
    dataSource.id,
    {
      ...dataSource.staticParams,
      queryParams: [
        { name: "Page", value: settings?.page },
        { name: "ItemsPerPage", value: settings?.itemsPerPage },
        { name: "ItemsCount", value: settings?.itemsCount },
        {
          name: "ResultMode",
          value: dataSource?.staticParams?.resultMode || "DetailedData",
        },
      ],
      columns: [{ name: "id" }],
      filters: settings.filters,
      filterOptions: {},
      sort: getSort(settings?.sorting),
      componentName: name,
    },
    [],
    {
      callerId: context?.component?.id,
      callerGuid: context?.component?.guid,
      callerNodeId: context?.component?.nodeId,
    },
  );

  return resolveDataResult(res);
};

export const fetchDataByQuery = async (context, query, settings, name) => {
  const res = await executeBlock(
    context,
    13138,
    {
      queryParams: [
        { name: "Page", value: settings?.page },
        { name: "ItemsPerPage", value: settings?.itemsPerPage },
        { name: "ItemsCount", value: settings?.itemsCount },
        {
          name: "ResultMode",
          value: "DetailedData",
        },
      ],
      columns: [{ name: "id" }],
      filters: settings.filters,
      filterOptions: {},
      sort: getSort(settings?.sorting),
      componentName: name,
      sql: query,
    },
    [],
    {
      callerId: context?.component?.id,
      callerGuid: context?.component?.guid,
      callerNodeId: context?.component?.nodeId,
    },
  );

  return resolveDataResult(res);
};
