// @flow
import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { Box, Td, Tooltip } from "@chakra-ui/react";
import type { Column, SortObj } from "../../../datagrid-v2";
import { ColumnResizer } from "../../column-resizer/column-resizer";
import { useDatagridContext } from "../../../hooks/useDatagridContext";
import { FaArrowUpLong, FaArrowDownLong } from "react-icons/fa6";
import { useShowTooltip } from "@echo/ui";
import {
  getBackground,
  getHeaderBackground,
  getTextColor,
} from "../../../utils/theme-utils";
import { mapColumnOrder } from "./utils";
import { DragItem } from "@echo/draggable";

type CellProps = {
  column: Column,
  width: string,
  children: React.ReactNode,
  index?: number,
  onDragOver: (e: any, columnIndex: number) => void,
  onDragEnd: (e: any, columnIndex: number) => void,
  tooltip: boolean,
};

export const HeaderCell = (props: CellProps) => {
  const {
    sort,
    actions: { onSortChange, onColumnOrderChange, onColumnVisibilityChange },
    settings: { headerWhiteSpace },
    headerFontSize,
    theme,
  } = useDatagridContext();

  const [isDraggable, setIsDraggable] = useState(true);
  const {
    width,
    children,
    column,
    index,
    tooltip,
    onContextMenu,
    setPlaceholderColumn,
    indicator,
  } = props;

  const columnSort = sort.find((el) => el.name === column.name);
  const ref = useRef();

  const handleClick = (e) => {
    if (!onSortChange) return;
    onSortChange(column.name, columnSort?.value, e.ctrlKey);
  };

  const handleWidthChange = (diff: number) => {
    const widthInt = parseInt(width.replace("px", ""));
    const newWidth = widthInt + diff;

    handleColumnSizeChange(newWidth, column.name);
  };

  const handleColumnSizeChange = (value: number, column) => {
    const newWidth = value < 0 ? 0 : value;
    const pxWidth = `${newWidth}px`;
    ref.current.style.width = pxWidth;
    ref.current.style.minWidth = pxWidth;
    ref.current.style.maxWidth = pxWidth;
  };

  useEffect(() => {
    ref.current.style.width = width;
    ref.current.style.minWidth = width;
    ref.current.style.maxWidth = width;
  }, [width]);

  const label = useMemo(
    () => column.displayName || column.name,
    [column.displayName, column.name],
  );

  const contentRef = useRef();
  const { showTooltip } = useShowTooltip(contentRef.current);

  const onItemDrop = useCallback(
    async (item, areaPos) => {
      if (!areaPos || areaPos !== "table-header") {
        await onColumnVisibilityChange(column.name, false);
        setPlaceholderColumn(null);
        return;
      }

      await onColumnOrderChange(item.id, item.newPos);
      setPlaceholderColumn(null);
    },
    [column],
  );

  return (
    <Tooltip
      isDisabled={!showTooltip}
      placement="top"
      padding="2px 8px"
      label={label}
    >
      <DragItem
        id={column.name}
        disabled={column.name === "checkbox" || !isDraggable}
        onDrag={(i) => {
          setPlaceholderColumn((prev) => {
            if (!prev) return i;
            return prev.id === i.id && prev.newPos === i.newPos ? prev : i;
          });
        }}
        onDrop={onItemDrop}
        position={column.order}
        context="datagrid"
      >
        <Td
          width={width || "auto"}
          minWidth={width || "auto"}
          maxWidth={width || "auto"}
          userSelect="none"
          padding="0"
          position="relative"
          overflow="hidden"
          onClick={column.name === "checkbox" ? undefined : handleClick}
          cursor="pointer"
          background={getHeaderBackground(theme)}
          color={getTextColor(theme)}
          fontWeight="bold"
          lineHeight="unset"
          ref={ref}
          onContextMenu={onContextMenu ? onContextMenu : undefined}
        >
          <Box
            height="100%"
            width="100%"
            padding="5px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="5px"
            fontSize={headerFontSize}
          >
            <Box
              ref={contentRef}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace={headerWhiteSpace || "nowrap"}
            >
              {children}
            </Box>
            {columnSort && (
              <Box fontSize="12px">
                {columnSort.value === "asc" ? (
                  <FaArrowUpLong />
                ) : (
                  <FaArrowDownLong />
                )}
              </Box>
            )}
          </Box>
          <ColumnResizer
            column={column}
            preventDraggable={(val: boolean) => setIsDraggable(!val)}
            onSizeChange={
              column.name === "checkbox" ? undefined : handleWidthChange
            }
          />
        </Td>
      </DragItem>
    </Tooltip>
  );
};
