import { useMemo } from "react";
import { resolveColumnsProcess } from "../utils/resolve-columns";

export const useColumnsProcess = (props) => {
  const { designerMode, columns } = props;
  const resolvedColumns = useMemo(() => {
    if (!designerMode && columns) {
      return resolveColumnsProcess(columns, props);
    }
    return [];
  }, [columns, designerMode, props]);

  return { resolvedColumns };
};
