import { showErrorToast } from "../../../components/shared/echo-error-toast";
import {
  authenticate,
  getLabels,
} from "../../../services/email-service/email-service";

export const getMailLabelsBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: async () => {
      const userId = componentContext.userContext.userId;

      const { refreshToken } = await authenticate(userId);

      if (!refreshToken || refreshToken === "") return [];

      const res = await getLabels({ refreshToken });

      if (!res.ok) {
        const err = await res.json();
        showErrorToast(err);
        return [];
      }

      const { labels } = await res.json();

      return labels;
    },
  };
};
