import { useCallback, useContext, useMemo } from "react";
import UserContext from "../../../../../../../user-context";
import { getSelectAllActionLabel } from "../utils/get-select-all-action-label";
import { getSelectAllAction } from "../utils/get-select-all-action";
import { echoPortal } from "../../../../../../echo-portal/echo-portal";
import QuestionAlertComponent from "../../question-alert-component/question-alert.component";

export const useContextActions = (state, api) => {
  const { isSuperuser } = useContext(UserContext);
  const { settings, itemsCount, selectedItems } = state;
  const {
    getData,
    selectAll,
    unselectAll,
    onHeaderVisibilityChange,
    onFiltersVisibilityChange,
    onNavPositionChange,
    onColumnsManagerOpen,
    onHeaderWhiteSpaceChange,
    onColumnsSettingsOpen,
    onLockRowHeightChange,
    refreshData,
    clearFilters,
    deleteSetting,
  } = api;

  const {
    disableFilters,
    disableHeader,
    navPosition,
    headerWhiteSpace,
    lockRowHeight,
  } = settings;

  const deleteSettingAlert = useCallback(async () => {
    const questionPromise = () =>
      new Promise((resolve) => {
        echoPortal.render((container) => (
          <QuestionAlertComponent
            open={true}
            header="Settings reset"
            question="Do you want to reset your settings?"
            alertMode={"No/Yes"}
            onAction={(type) => {
              container.unmount();
              resolve(type);
            }}
          />
        ));
      });

    const questionResult = await questionPromise();

    if (questionResult === "No") return;

    deleteSetting();
  }, [deleteSetting]);

  const lockRowHeightAction = useMemo(
    () => ({
      label: `Lock row height: ${lockRowHeight}`,
      action: onLockRowHeightChange,
    }),
    [lockRowHeight, onLockRowHeightChange],
  );

  const selectAllRowsAction = useMemo(
    () => ({
      label: getSelectAllActionLabel(itemsCount, selectedItems),
      action: () =>
        getSelectAllAction(
          itemsCount,
          selectedItems,
          selectAll,
          unselectAll,
          getData,
        ),
    }),
    [getData, itemsCount, selectAll, selectedItems, unselectAll],
  );

  const headerVisilibityAction = useMemo(
    () => ({
      label: `${disableHeader ? "Show" : "Hide"} header`,
      action: onHeaderVisibilityChange,
    }),
    [disableHeader, onHeaderVisibilityChange],
  );

  const filtersVisibilityAction = useMemo(
    () => ({
      label: `${disableFilters ? "Show" : "Hide"} filters`,
      action: onFiltersVisibilityChange,
    }),
    [onFiltersVisibilityChange, disableFilters],
  );

  const navPositionAction = useMemo(
    () => ({
      label: `Change navigation position: ${navPosition === "bottom" ? "top" : "bottom"}`,
      action: onNavPositionChange,
    }),
    [navPosition, onNavPositionChange],
  );

  const columnsManagerAction = useMemo(
    () => ({
      label: "Manage columns",
      action: onColumnsManagerOpen,
      color: "red",
      position: ["header"],
    }),
    [onColumnsManagerOpen],
  );

  const headerWhiteSpaceAction = useMemo(
    () => ({
      label: `Change header wrap: ${headerWhiteSpace === "nowrap" ? "no wrap" : "wrap"}`,
      action: onHeaderWhiteSpaceChange,
      position: ["header"],
    }),
    [onHeaderWhiteSpaceChange, headerWhiteSpace],
  );

  const columnSettingsAction = useMemo(
    () => ({
      label: "Advanced columns settings",
      action: onColumnsSettingsOpen,
      position: ["header"],
    }),
    [onColumnsSettingsOpen],
  );

  const clearFiltersAction = useMemo(
    () => ({
      label: "Clear filters",
      action: clearFilters,
      position: ["header"],
    }),

    [clearFilters],
  );

  const refreshDataAction = useMemo(
    () => ({
      label: "Refresh data",
      action: refreshData,
      position: ["header"],
    }),
    [refreshData],
  );

  const deleteSettingAction = useMemo(
    () => ({
      label: "Reset settings",
      action: deleteSettingAlert,
      position: ["header"],
      color: "red",
    }),
    [deleteSettingAlert],
  );

  const contextActions = [
    columnsManagerAction,
    deleteSettingAction,
    isSuperuser && columnSettingsAction,
    clearFiltersAction,
    refreshDataAction,
    headerVisilibityAction,
    filtersVisibilityAction,
    navPositionAction,
    headerWhiteSpaceAction,
    lockRowHeightAction,
  ];

  const selectionContextActions = [selectAllRowsAction];

  return { contextActions, selectionContextActions };
};
