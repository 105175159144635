import {
  Box,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Input } from "@echo/ui";
import { DatagridV2 } from "@echo/datagrid";
import { ColumnSettings } from "../datagrid-component/column-settings/column-settings";
import { ColumnVisibilityManager } from "../datagrid-component/header/column-visibility-manager";
import { useCombogrid } from "./hooks/use-combogrid";
import { useResolveProp } from "../../../../../hooks/resolve-prop/use-resolve-prop";

const ComboGridV2Component = (props) => {
  const { id, style, isReadOnly, isDisabled } = props;

  const isReadOnlyState = useResolveProp(isReadOnly);
  const isDisabledState = useResolveProp(isDisabled);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    inputProps,
    datagridProps,
    columnVisibilityProps,
    columnSettingsProps,
    popoverRef,
  } = useCombogrid({ ...props });

  return (
    <Box position="relative" order={style?.order}>
      <Popover
        autoFocus={false}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        initialFocusRef={popoverRef}
        flip={false}
      >
        <PopoverTrigger>
          <Input
            id={id}
            autoComplete="off"
            isDisabled={isDisabledState}
            icon={
              <IconButton
                isDisabled={isDisabledState}
                variant="ghost"
                style={{
                  fontSize: "1.25rem",
                  position: "absolute",
                  top: "50%",
                  right: "4px",
                  transform: "translateY(-50%)",
                  zIndex: "10",
                }}
                icon={<ChevronDownIcon />}
              />
            }
            {...inputProps}
          />
        </PopoverTrigger>

        <PopoverContent>
          <PopoverBody ref={popoverRef}>
            <ColumnVisibilityManager {...columnVisibilityProps} />
            <ColumnSettings {...columnSettingsProps} />
            <DatagridV2 {...datagridProps} />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

ComboGridV2Component.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  dataSource: PropTypes.func,
  onChange: PropTypes.func,
  selectionMode: PropTypes.oneOf(["single", "multiple"]),
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  valueColumnName: PropTypes.string,

  // datagrid props
  gridWidth: PropTypes.string,
  hideHeaderColumns: PropTypes.bool,
  hideFilterRow: PropTypes.bool,
  hideStatusRow: PropTypes.bool,
  hideFooter: PropTypes.bool,
  disableSorting: PropTypes.bool,

  // rest props
  rootProps: PropTypes.object,
  context: PropTypes.object,
  style: PropTypes.object,
};

export default withBaseComponent(ComboGridV2Component);
