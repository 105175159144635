import { Box, Text } from "@chakra-ui/react";
import { HeaderTitle } from "./header-title";
import { HeaderActions } from "./header-actions";
import PropTypes from "prop-types";
import { useState } from "react";

export const SectionHeader = ({
  actions,
  title,
  tasksLength,
  enableSectionMove,
}) => {
  const {
    onMouseDown,
    onMouseLeave,
    onMouseEnter,
    onTitleChange,
    openNewTask,
    deleteSection,
  } = actions;

  const [value, setValue] = useState(title);
  const [isEditable, setIsEditable] = useState(false);

  const handleChange = (e) => setValue(e.target.value);

  const handleBlur = (e) => {
    onTitleChange(e.target.value);
    setIsEditable(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onTitleChange(e.target.value);
      setIsEditable(false);
    }
  };

  const openTitleEdit = () => setIsEditable(true);

  return (
    <Box
      width="100%"
      padding="5px 2px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap="10px"
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      cursor={enableSectionMove ? "grab" : "default"}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        gap="5px"
        width="100%"
      >
        <HeaderTitle
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          isEditable={isEditable}
          openTitleEdit={openTitleEdit}
        />
        <Text>{tasksLength}</Text>
      </Box>
      <HeaderActions actions={{ openNewTask, deleteSection, openTitleEdit }} />
    </Box>
  );
};

SectionHeader.propTypes = {
  actions: PropTypes.shape({
    onMouseDown: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onTitleChange: PropTypes.func,
    openNewTask: PropTypes.func,
    deleteSection: PropTypes.func,
    openTitleEdit: PropTypes.func,
  }),
  title: PropTypes.string,
  tasksLength: PropTypes.number,
  enableSectionMove: PropTypes.bool,
};
