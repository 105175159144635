// @flow

import React, { useEffect, useState } from "react";
import { FilterInputProps } from "./filter-input";
import { FilterOptionType } from "./filter-options";
import { Box } from "@chakra-ui/react";
import { DatePicker } from "@echo/ui";
import { getTextColor } from "../../utils/theme-utils";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { useTimeoutValue } from "../../hooks/use-timeout-value";

type DateFilterInputProps = {
  type: FilterOptionType,
  onChange: (value) => void,
  values: string[],
  format: string,
};

export const DateFilterInput = (props: DateFilterInputProps) => {
  const { theme } = useDatagridContext();
  const { type, values, format, onChange } = props;

  const [firstValue, setFirstValue] = useTimeoutValue(values?.[0], (value) =>
    onChange(value, 0),
  );

  const [secondValue, setSecondValue] = useTimeoutValue(values?.[1], (value) =>
    onChange(value, 1),
  );

  return type === "between" || type === "excludeRange" ? (
    <Box display="flex" alignItems="center" gap="5px">
      <DatePicker
        date={firstValue || ""}
        onChange={setFirstValue}
        format={format || "dd-MM-yyyy"}
        style={{ color: getTextColor(theme) }}
        size="xs"
      />
      <DatePicker
        date={secondValue || ""}
        onChange={setSecondValue}
        format={format || "dd-MM-yyyy"}
        style={{ color: getTextColor(theme) }}
        size="xs"
      />
    </Box>
  ) : (
    <DatePicker
      date={firstValue || ""}
      onChange={setFirstValue}
      format={format || "dd-MM-yyyy"}
      style={{ color: getTextColor(theme) }}
      size="xs"
    />
  );
};
