// @flow

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Tooltip, Tr } from "@chakra-ui/react";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { Cell } from "../cell/cell";
import {
  DATAGRID_FILTER_ROW_CELL_TESTID,
  DATAGRID_FILTER_ROW_TESTID,
} from "../../utils/test-ids";
import { FilterCell } from "../cell/filter-cell";
import { Filter } from "../filter/filter";

import { addFilter, changeFilter } from "../../utils/filter-utils";
import { getBackground, getTextColor } from "../../utils/theme-utils";
import { DragSection } from "@echo/draggable";
import { filterOptions } from "../filter/filter-options";
import { FiFilter } from "react-icons/fi";

export const FilterRow = ({ columns }) => {
  const {
    filters,
    actions: { onFilterChange },
    selectionMode,
    theme,
  } = useDatagridContext();

  const handleFilterChange = (name, type, values) => {
    onFilterChange(name, type, values);
  };

  const hasFilters = useMemo(
    () => filters?.some((f) => f?.values?.some((v) => v && v !== "")),
    [filters],
  );

  const filtersLabel = useMemo(
    () =>
      `Aktywne filtry: ${filters?.map((f) => columns.find((c) => f.name === c.name)?.displayName || f.name).join(", ")}`,
    [filters, columns],
  );

  const getFilter = useCallback(
    (column) => filters?.find((f) => f.name === column.name),
    [filters],
  );

  return (
    <DragSection id="datagrid-filters" context="datagrid">
      <Tr
        width="100%"
        borderBottom="1px solid black"
        background={getBackground(theme)}
        color={getTextColor(theme)}
        data-testid={DATAGRID_FILTER_ROW_TESTID}
      >
        {columns.map((col) =>
          col.name === "checkbox" ? (
            selectionMode !== "none" && (
              <Cell
                column={col}
                width={col.width}
                key={`empty-${col.name}`}
                data-testid={DATAGRID_FILTER_ROW_CELL_TESTID}
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0",
                  fontSize: "16px",
                  position: "relative",
                }}
              >
                {hasFilters && (
                  <Tooltip label={filtersLabel} hasArrow padding="2px 4px">
                    <span>
                      <FiFilter color="red" />
                    </span>
                  </Tooltip>
                )}
              </Cell>
            )
          ) : (
            <FilterCell
              filter={getFilter(col)}
              onFilterChange={handleFilterChange}
              column={col}
              key={col.name}
              data-testid={DATAGRID_FILTER_ROW_CELL_TESTID}
            />
          ),
        )}
      </Tr>
    </DragSection>
  );
};
