import { getLocalStorageItem } from "../local-storage/local-storage";
import { localStorageKeys } from "../local-storage/local-storage-keys";

const getPropertyValue = (obj, property) => {
  const keys = Object.keys(obj);
  const f = keys.find((k) => k.toLowerCase() === property.toLowerCase());

  if (!f) return undefined;
  return obj[f];
};

const getOneOfPropertyValue = (obj, properties) => {
  const keys = Object.keys(obj);

  const f = keys.find((k) =>
    properties.find((p) => p.toLowerCase() === k.toLowerCase()),
  );

  if (!f) return undefined;
  return obj[f];
};

const mapNotification = (n) => {
  const id = getPropertyValue(n, "id");
  const timeToNotify = getPropertyValue(n, "value");
  const interval = getPropertyValue(n, "interval");
  const dateToCheck = getOneOfPropertyValue(n, [
    "startdate",
    "start_date",
    "date",
  ]);
  const text = getOneOfPropertyValue(n, ["title", "name"]);

  return { id, text, timeToNotify, dateToCheck, interval };
};

export const getStoredNotifications = () => {
  const notifications = getLocalStorageItem(localStorageKeys.NOTIFICATIONS);

  if (!notifications || (notifications && notifications.length === 0)) {
    return [];
  }

  return notifications.map(mapNotification);
};
