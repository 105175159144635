import { withBaseComponent } from "../../../with-base-component";
import { Box, Button, Text, useDisclosure } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { CalendarHeader } from "./calendar-header/calendar-header";
import { CalendarBody } from "./calendar-body/calendar-body";
import { useCalendarUtils } from "./hooks/use-calendar-utils";
import { CalendarNavigation } from "./calendar-nav/calendar-navigation";
import { useApiContext } from "../../../../../echo-components/api-context/use-api-context";
import { CalendarDragContextProvider } from "./context/calendar-drag-context";
import { useContextMenu } from "@echo/ui/src/lib/components/context-menu/hooks/useContextMenu";
import { ContextMenu } from "@echo/ui";
import { CalendarSettingsModal } from "./calendar-settings-modal/calendar-settings-modal";
import { useCalendars } from "./hooks/use-calendars";
import { useMemo } from "react";
import { isSameWeek } from "date-fns";
import { useEvents } from "./hooks/use-events";
import { FaRegCalendarAlt } from "react-icons/fa";

const Calendar = (props) => {
  const {
    id,
    guid,
    nodeId,
    style,
    nodeRef,
    rootProps,
    designerMode,
    ...restProps
  } = props;

  const {
    openContextMenu,
    closeContextMenu,
    isContextMenuOpened,
    clickPosition,
  } = useContextMenu();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { weekStart, weekEnd, weekDays, nextWeek, prevWeek, setWeek } =
    useCalendarUtils();

  const [, component] = useApiContext(id, guid, nodeId);

  const { events, onEventEdit, onEventAdd, onEventClick } = useEvents(
    props,
    component,
  );

  const { calendars, onCalendarBackgroundChange, onCalendarVisibilityChange } =
    useCalendars(props, component);

  const contextMenuActions = [{ label: "Calendar settings", action: onOpen }];

  const data = useMemo(
    () =>
      events?.length > 0 && calendars?.length > 0
        ? events
            .filter(
              (ev) =>
                calendars.find((c) => ev.calendarId === c.id)?.isVisible?.value,
            )
            .filter((ev) => isSameWeek(ev.from, weekStart, { weekStartsOn: 1 }))
        : [],
    [events, calendars, weekStart],
  );

  return (
    <CalendarDragContextProvider>
      <Box
        ref={nodeRef}
        {...style}
        {...rootProps}
        {...restProps}
        position="relative"
        overflow="auto"
      >
        {isOpen && (
          <CalendarSettingsModal
            isOpen={isOpen}
            onClose={onClose}
            availableCalendars={calendars}
            changeCalendarVisibility={onCalendarVisibilityChange}
            changeCalendarBackground={onCalendarBackgroundChange}
          />
        )}
        {!designerMode && isContextMenuOpened && (
          <Box position="fixed" zIndex="155">
            <ContextMenu
              isOpen={isContextMenuOpened}
              onClose={closeContextMenu}
              actions={contextMenuActions}
              clickPosition={clickPosition}
            />
          </Box>
        )}

        <Box position="relative">
          <Box position="absolute" left="10px" top="10px">
            <Button
              variant="outline"
              onClick={onOpen}
              display="flex"
              alignItems="center"
              gap="5px"
              fontSize="1.1rem"
            >
              <FaRegCalendarAlt /> <Text>Moje kalendarze</Text>
            </Button>
          </Box>

          <CalendarNavigation
            nextWeek={nextWeek}
            prevWeek={prevWeek}
            weekStart={weekStart}
            weekEnd={weekEnd}
            setWeek={setWeek}
          />
        </Box>

        <CalendarHeader weekDays={weekDays} onContextMenu={openContextMenu} />
        <CalendarBody
          weekDays={weekDays}
          events={data}
          availableCalendars={calendars}
          onEventAdd={onEventAdd}
          onEventEdit={onEventEdit}
          onEventClick={onEventClick}
        />
      </Box>
    </CalendarDragContextProvider>
  );
};

Calendar.propTypes = {
  id: PropTypes.any,
  name: PropTypes.string,
  calendarDataSource: PropTypes.func,
  eventDataSource: PropTypes.func,
  onEventAdd: PropTypes.func,
  onEventEdit: PropTypes.func,
  onEventClick: PropTypes.func,
  nodeRef: PropTypes.any,
  style: PropTypes.object,
  rootProps: PropTypes.object,
  guid: PropTypes.any,
  nodeId: PropTypes.any,
  context: PropTypes.any,
  designerMode: PropTypes.any,
};

export default withBaseComponent(Calendar);
