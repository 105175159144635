import { Input, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";

export const HeaderTitle = ({
  value,
  onChange,
  onBlur,
  onKeyDown,
  isEditable,
  openTitleEdit,
}) => {
  return !isEditable ? (
    <Text cursor="pointer" onClick={openTitleEdit}>
      {value}
    </Text>
  ) : (
    <Input
      width="100%"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      autoFocus
    />
  );
};

HeaderTitle.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  isEditable: PropTypes.bool,
  openTitleEdit: PropTypes.func,
};
