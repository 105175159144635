import PropTypes from "prop-types";
import { Box, Button } from "@chakra-ui/react";
import { withBaseComponent } from "../../../with-base-component";
import { useTreeSource } from "./hooks/use-tree-source";
import TreeElement from "./tree-element";
import ComponentResizer from "../../../../component-resizer/component-resizer";
import { useRefDimensions } from "@echo/ui";
import { useCallback, useEffect, useRef, useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { executeBlock } from "../../../../../../process-executor/process-executor";

const getValueFromObj = (obj) => {
  const valueProperty = Object.keys(obj).find(
    (p) => p.toLowerCase() === "value",
  );

  if (valueProperty) return obj[valueProperty];

  return Object.values(obj)[0];
};

const getIsOpenValue = (obj) => {
  const type = typeof obj;

  switch (type) {
    case "string": {
      if (obj === "true" || obj === "1") return true;
      return false;
    }
    case "number": {
      if (obj === 0) return false;
      return true;
    }
    case "boolean":
      return obj;
    case "object": {
      if (Array.isArray(obj)) {
        const firstElem = obj[0];
        return getIsOpenValue(getValueFromObj(firstElem));
      }

      return getIsOpenValue(getValueFromObj(obj));
    }
    case "undefined":
    default:
      return false;
  }
};

const TreeViewComponent = (props) => {
  const {
    id,
    guid,
    nodeId,
    rootProps,
    designerMode,
    component,
    width,
    onWidthChange,
    context,
    contextMenuDataSource,
    isOpen,
    onOpenChange,
  } = props;
  const [openState, setOpenState] = useState(false);
  const [schema, state, handlers] = useTreeSource(props);
  const { nodeRef, style, ...restRootProps } = rootProps;
  const [widthState, setWidthState] = useState(null);
  const { width: styleWidth, ...restStyles } = style;

  const getContextMenuData = useCallback(
    (params) => {
      const dataSourceId = contextMenuDataSource.id;
      const componentInfo = {
        callerId: component?.id,
        callerGuid: component?.guid,
        callerNodeId: component?.nodeId,
      };
      return executeBlock(context, dataSourceId, params, [], componentInfo);
    },
    [context, component, contextMenuDataSource],
  );

  const internalRef = useRef();
  const contentRef = nodeRef || internalRef;

  const [contentDimensions] = useRefDimensions(
    contentRef,
    {
      reactOnResize: true,
    },
    [state.data, state.columnDefinitions],
  );

  useEffect(() => {
    if (!widthState && typeof width === "function") {
      Promise.resolve(width())
        .then((result) => {
          if (
            Array.isArray(result) &&
            result.length > 0 &&
            typeof result[0] === "object" &&
            result[0].value &&
            !isNaN(result[0].value)
          ) {
            setWidthState(Number(result[0].value));
          }
        })
        .catch(() => {});
    } else if (!widthState && typeof width === "string" && !isNaN(width)) {
      setWidthState(Number(width));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    if (typeof isOpen === "function") {
      isOpen()
        .then((res) => {
          const value = getIsOpenValue(res);
          setOpenState(value);
        })
        .catch(() => {});
    } else {
      const value = getIsOpenValue(isOpen);
      setOpenState(value);
    }
  }, [isOpen]);

  const handleSizeChange = (newSize) => {
    if (contentRef.current && newSize >= 110) {
      contentRef.current.width = `${newSize}px`;
      contentRef.current.style.width = `${newSize}px`;
      contentRef.current.style.minWidth = `${newSize}px`;
      contentRef.current.style.maxWidth = `${newSize}px`;
    }
  };

  const handleSizeChangeCommit = (newWidth) => {
    if (typeof onWidthChange === "function") {
      Promise.resolve(onWidthChange(newWidth)).catch(() => {});
    }
    setWidthState(newWidth);
  };

  const getWidth = () => {
    if (widthState) {
      return widthState;
    } else {
      return contentDimensions.width;
    }
  };

  const toggleOpen = () =>
    setOpenState((prev) => {
      const newVal = !prev;
      if (onOpenChange) onOpenChange({ componentElementId: id, value: newVal });
      return newVal;
    });

  return (
    <Box
      display="flex"
      height="100%"
      width={designerMode ? widthState || styleWidth : ""}
    >
      {(designerMode || openState) && (
        <Box
          fontSize="smaller"
          position="relative"
          display="flex"
          flexFlow="row"
          minHeight="10px"
          ref={contentRef}
          {...restRootProps}
          style={restStyles}
          width={designerMode ? "100%" : widthState || styleWidth}
        >
          <Box width="100%" height="100%">
            {!designerMode ? (
              schema &&
              Object.keys(schema)
                .filter((displayName) => !displayName.startsWith("__"))
                .map((displayName, index) => {
                  const child = schema[displayName];
                  return (
                    <TreeElement
                      key={`tree-element-${index}-${child.path}`}
                      {...handlers}
                      state={state}
                      displayName={displayName}
                      child={child}
                      valuePath={child.__meta}
                      context={context}
                      contextMenuDataSource={getContextMenuData}
                      componentInfo={{ id, guid, nodeId }}
                    />
                  );
                })
            ) : (
              <Box
                height="100%"
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                {component.displayName}
              </Box>
            )}
          </Box>
          <ComponentResizer
            width={getWidth()}
            contentDimensions={{ ...contentDimensions, height: "100%" }}
            onSizeChange={handleSizeChange}
            commitChange={handleSizeChangeCommit}
          />
        </Box>
      )}
      <Button
        onClick={designerMode ? undefined : toggleOpen}
        flexShrink="0"
        style={{
          margin: "5px 5px 0 0",
          padding: "0",
          background: "none",
          marginLeft: openState ? "" : "5px",
        }}
      >
        <FaArrowRight
          style={{
            transform: openState ? "rotate(180deg)" : "",
          }}
        />
      </Button>
    </Box>
  );
};

TreeViewComponent.propTypes = {
  id: PropTypes.number,
  dataSource: PropTypes.any,
  contextMenuDataSource: PropTypes.any,
  value: PropTypes.shape({ path: PropTypes.string.isRequired }),
  onChange: PropTypes.func,
  width: PropTypes.string,
  isOpen: PropTypes.any,
  guid: PropTypes.string,
  nodeId: PropTypes.string,
  context: PropTypes.any,
  rootProps: PropTypes.any,
  component: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    id: PropTypes.string,
    guid: PropTypes.string,
    nodeId: PropTypes.string,
  }),
  designerMode: PropTypes.bool,
  defaultUnselected: PropTypes.bool,
  onWidthChange: PropTypes.func,
  onOpenChange: PropTypes.func,
};

export default withBaseComponent(TreeViewComponent);
