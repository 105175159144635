import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";
import { withBaseComponent } from "../../../with-base-component";
import { Quill } from "react-quill";
import "./wysiwyg.css";
import { useEffect, useState } from "react";
import { showErrorToast } from "../../../../echo-error-toast";
import { formats, getModules } from "./editor-config";
import { CustomParagraph } from "./custom-paragraph";
import { CustomClipboard } from "./custom-clipboard";
import { useDebouncedValueState } from "../../../../../hooks/shared/use-debounced-value-state";
import EchoReactQuill from "./wysiwyg-fixed";

const Parchment = Quill.import("parchment");
Parchment.register(CustomParagraph);
Quill.register("modules/clipboard", CustomClipboard, true);

const WysiwygComponent = ({
  rootProps,
  value,
  onChange,
  name,
  readOnly,
  enableFontToolbar,
  enableQuoteToolbar,
  enableAttachmentToolbar,
  enableHeaderToolbar,
  enableListToolbar,
  enableScriptToolbar,
  enableIndentToolbar,
  enableDirectionToolbar,
  enableSizeToolbar,
  enableColorToolbar,
  enableCleanToolbar,
  enableAlignToolbar,
}) => {
  const { nodeRef, ...restRootProps } = rootProps;
  const [valueQuill, setValueQuill] = useDebouncedValueState(
    value,
    onChange,
    name,
  );

  const [readOnlyValue, setReadOnlyValue] = useState(true);

  useEffect(() => {
    Promise.resolve(typeof readOnly === "function" ? readOnly() : readOnly)
      .then(setReadOnlyValue)
      .catch((err) =>
        showErrorToast({
          reasonTitle: "Cannot read readOnly prop",
          location: "WYSIWYG component",
          shortMessage: err.toString().slice(0, 300),
          message: err.toString(),
        }),
      );
  }, [readOnly]);

  return (
    <Box {...restRootProps} ref={nodeRef} className="wysiwyg">
      <EchoReactQuill
        key={`react-quill-${name}`}
        value={valueQuill}
        onChange={setValueQuill}
        readOnly={readOnlyValue}
        formats={formats}
        modules={getModules({
          enableFontToolbar,
          enableQuoteToolbar,
          enableAttachmentToolbar,
          enableHeaderToolbar,
          enableListToolbar,
          enableScriptToolbar,
          enableIndentToolbar,
          enableDirectionToolbar,
          enableSizeToolbar,
          enableColorToolbar,
          enableCleanToolbar,
          enableAlignToolbar,
        })}
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        placeholder="Write text here"
        theme="snow"
      />
    </Box>
  );
};

WysiwygComponent.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  rootProps: PropTypes.object,
  readOnly: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  enableFontToolbar: PropTypes.bool,
  enableQuoteToolbar: PropTypes.bool,
  enableAttachmentToolbar: PropTypes.bool,
  enableHeaderToolbar: PropTypes.bool,
  enableListToolbar: PropTypes.bool,
  enableScriptToolbar: PropTypes.bool,
  enableIndentToolbar: PropTypes.bool,
  enableDirectionToolbar: PropTypes.bool,
  enableSizeToolbar: PropTypes.bool,
  enableColorToolbar: PropTypes.bool,
  enableCleanToolbar: PropTypes.bool,
  enableAlignToolbar: PropTypes.bool,
};

export default withBaseComponent(WysiwygComponent);
