import {
  addMinutes,
  compareAsc,
  differenceInMinutes,
  format,
  getDay,
  isSameDay,
  startOfDay,
} from "date-fns";

const getEventsForSameDay = (events, currEvent) =>
  events.filter((ev) => isSameDay(ev.date, currEvent.date));

const getEventsWithSameStart = (events, currEvent) =>
  events.filter((ev) => ev.start === currEvent.start);

const getEventsInside = (events, currEvent) =>
  events.filter((ev) => currEvent.start > ev.start && currEvent.start < ev.end);

const getDateString = (date) =>
  format(date, "yyyy-MM-dd HH:mm:ss.SSS").replace(" ", "T");

const mapEventsToColumnRow = (events, shift) =>
  events.map((ev) => ({
    id: ev.id,
    date: ev.from,
    column: getEventDay(ev),
    start: getEventStart(ev, shift),
    end: getEventEnd(ev, shift),
  }));

const getEventProperty = (ev, key) => {
  const keys = Object.keys(ev);
  const propKey = keys.find((k) => k.toLowerCase() === key.toLowerCase());
  return ev[propKey];
};

const defaultEventKeys = [
  "id",
  "title",
  "startDate",
  "endDate",
  "description",
  "calendarId",
  "calendarName",
  "color",
];

const keysMapping = [
  { from: "startdate", to: "from" },
  { from: "enddate", to: "to" },
];

const getMappedName = (name) => {
  const f = keysMapping.find(
    (m) => m.from.toLowerCase() === name.toLowerCase(),
  );

  if (!f) return name;
  return f.to;
};

const getDefaultEventObj = (obj) => {
  const values = defaultEventKeys.map((k) => ({
    name: k,
    value: getEventProperty(obj, k),
  }));

  const mappedValues = values.map((v) => ({
    name: getMappedName(v.name),
    value: v.value,
  }));

  return mappedValues.reduce(
    (acc, curr) => ({ ...acc, [curr.name]: curr.value }),
    {},
  );
};

const getUnhandledEventObj = (obj) => {
  const objKeys = Object.keys(obj);
  const restKeys = objKeys.filter(
    (k1) =>
      !defaultEventKeys.find((k2) => k1.toLowerCase() === k2.toLowerCase()),
  );

  return restKeys.reduce((acc, curr) => ({ ...acc, [curr]: obj[curr] }), {});
};

const getEvent = (obj) => {
  const defaultEventObj = getDefaultEventObj(obj);
  const unhandledEventObj = getUnhandledEventObj(obj);
  return { ...defaultEventObj, ...unhandledEventObj };
};

export const mapEvents = (events) =>
  events.map((ev) => {
    const event = getEvent(ev);
    return {
      ...event,
      from: new Date(event.from),
      to: new Date(event.to),
    };
  });

export const mapEventToSql = (event) => {
  const {
    id,
    title,
    from,
    to,
    description,
    calendarId,
    calendarName,
    ...rest
  } = event;

  return {
    Id: id,
    Title: title,
    StartDate: getDateString(from),
    EndDate: getDateString(to),
    Description: description,
    CalendarId: calendarId,
    CalendarName: calendarName,
    ...rest,
  };
};

export const getMovedEvent = (event, area) => {
  if (!event || !area) return event;
  const eventStart = area;
  const eventEnd = addMinutes(
    eventStart,
    differenceInMinutes(event.to, event.from),
  );
  return { ...event, from: eventStart, to: eventEnd };
};

export const getEventDay = (event) => {
  const day = getDay(event.from);
  if (day === 0) return 7;
  return day;
};

export const getEventStart = (event, shift) => {
  const start = event.from;
  const hour = start.getHours();
  const minutes = start.getMinutes();
  const result = hour * 4 + minutes / 15;
  return result + 1 + shift;
};

export const getEventEnd = (event, shift) => {
  const end = event.to;
  const hour = end.getHours();
  const minutes = end.getMinutes();
  const result = (hour === 0 ? 24 : hour) * 4 + minutes / 15;
  return Math.ceil(result + 1) + shift;
};

export const getHourByRowId = (rowId) => Math.floor(rowId / 4);

export const getEventZIndex = (currEvent) => currEvent.start;

export const getEventMargin = (eventsList, currEvent, shift) => {
  const events = mapEventsToColumnRow(eventsList, shift);
  const sameDayEvents = getEventsForSameDay(events, currEvent);
  const startSameEvents = getEventsWithSameStart(sameDayEvents, currEvent);

  if (startSameEvents.length > 0) {
    const arrId = startSameEvents.findIndex((ev) => ev.id === currEvent.id);
    const margin = arrId * 15;
    return margin - arrId * 2;
  }

  return 0;
};

export const getEventWidth = (eventsList, currEvent, marginLeft, shift) => {
  const events = mapEventsToColumnRow(eventsList, shift);
  const sameDayEvents = getEventsForSameDay(events, currEvent);
  const startSameEvents = getEventsWithSameStart(sameDayEvents, currEvent);

  if (startSameEvents.length > 1) {
    const width = 100 / startSameEvents.length;

    if (marginLeft + width + 30 - startSameEvents.length > 100) {
      return 100 - marginLeft;
    }

    return width + 30 - startSameEvents.length;
  }

  const insideEvents = getEventsInside(sameDayEvents, currEvent);
  if (insideEvents.length > 0) {
    return 100 - insideEvents.length * 10;
  }

  return 100;
};

export const filterFullDayEvent = (ev) =>
  differenceInMinutes(ev.to, ev.from) >= 1440 &&
  compareAsc(ev.from, startOfDay(ev.from)) === 0;
