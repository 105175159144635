import { Box, Input } from "@chakra-ui/react";
import { withBaseComponent } from "../../../with-base-component";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useResolveProp } from "../../../../../hooks/resolve-prop/use-resolve-prop";

const ColorPicker = ({ label, value, onChange, isDisabled, rootProps }) => {
  const { nodeRef, style, ...restRootProps } = rootProps;
  const isDisabledState = useResolveProp(isDisabled, true);
  const [color, setColor] = useState("");

  useEffect(() => {
    setColor(value);
  }, [value]);

  useEffect(() => {
    let timeout;
    timeout = setTimeout(() => {
      onChange(color);
    }, 500);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  const handleChange = (e) => {
    const color = e.target.value;
    setColor(color);
  };

  const { height, width, ...restStyle } = style;

  return (
    <Box
      ref={nodeRef}
      {...restRootProps}
      style={restStyle}
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="fit-content"
      gap="5px"
    >
      <Box
        overflow="hidden"
        height={height || width}
        aspectRatio="1/1"
        borderRadius="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        background={color || "#000"}
      >
        <Input
          opacity="0"
          height="250%"
          width="250%"
          variant="unstyled"
          padding="0"
          margin="0"
          type="color"
          value={value}
          onChange={handleChange}
          border="none"
          outline="none"
          isDisabled={isDisabledState}
        />
      </Box>
      {label && label}
    </Box>
  );
};

ColorPicker.propTypes = {
  id: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  style: PropTypes.object,
  nodeRef: PropTypes.any,
  rootProps: PropTypes.object,
};

export default withBaseComponent(ColorPicker);
