import { mobileCheck } from "@echo/ui";
import { catchEventError } from "../../datagrid-component/utils/catch-event-error";

export const useTableActions = (api) => {
  const {
    onColumnChange,
    onColumnBatchChange,
    onColumnOrderChange,
    onFilterChange,
    onItemsPerPageChange,
    onPageChange,
    onSortChange,
    onRowEdit,
    onRowAdd,
    onRowClick,
    onRowDoubleClick,
    unselectAll,
    selectSingle,
    selectMany,
    deleteSetting,
  } = api;

  const isMobile = mobileCheck();

  const handleRowClick = (e) => {
    if (!onRowClick) {
      if (isMobile) onRowDoubleClick(e).catch(catchEventError);
    } else {
      onRowClick(e);
    }
  };

  const handleRowDoubleClick = (e) => {
    if (!onRowDoubleClick) return;
    Promise.resolve(onRowDoubleClick(e)).catch(catchEventError);
  };

  const tableActions = {
    onRowClick: handleRowClick,
    onRowDoubleClick: handleRowDoubleClick,
    onFilterChange: (name, type, values, shouldSave) => {
      onFilterChange(name, type, values, shouldSave);
      unselectAll();
    },
    onItemsPerPageChange: (val) => {
      onItemsPerPageChange(val);
      unselectAll();
    },
    onPageChange,
    onSortChange: (name, value, ctrlKey) => {
      onSortChange(name, value, ctrlKey);
      unselectAll();
    },
    onColumnChange,
    onColumnBatchChange,
    onColumnWidthChange: (name, value) => onColumnChange(name, "width", value),
    onColumnOrderChange,
    onColumnVisibilityChange: (name, value) =>
      onColumnChange(name, "isVisible", value),
    onRowSelect: selectSingle,
    onSelectAll: selectMany,
    onRowEdit,
    onRowAdd,
    deleteSetting,
  };

  return tableActions;
};
