import { Cell } from "./cell";
import { TypedEditCell } from "./edit-cell/typed-edit-cell";
import React from "react";
import { TypedEditCellV2 } from "./edit-cell/typed-edit-cell-v2";

export const EditCellV2 = (props) => {
  const { column, data, value, row, onChange, autoFocus, style } = props;

  const handleChange = (value) => {
    if (value === "") onChange(null, column);
    else onChange(value, column);
  };

  return (
    <Cell
      column={column}
      width={column.width}
      key={`${column.name}`}
      style={{ ...style, textAlign: column?.align }}
    >
      <TypedEditCellV2
        data={data}
        value={value}
        onChange={handleChange}
        column={column}
        autoFocus={autoFocus}
        row={row}
      />
    </Cell>
  );
};
