const getContextSections = (id, list) =>
  list.includes(id) ? list : [...list, id];

const getContextItems = (item, list) =>
  list.find((i) => i.id === item.id) ? list : [...list, item];

export const getContextWithItems = (arr, ctx, item) => {
  return [
    ...arr.map((c) =>
      c.id === ctx
        ? {
            ...c,
            items: getContextItems(item, c.items),
          }
        : c,
    ),
  ];
};

export const getContextWithSections = (arr, ctx, id) => {
  return [
    ...arr.map((c) =>
      c.id === ctx
        ? {
            ...c,
            sections: getContextSections(id, c.sections),
          }
        : c,
    ),
  ];
};
