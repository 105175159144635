// @flow

import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { Box, Button, Input, Th } from "@chakra-ui/react";
import type { Column, Filter as TFilter } from "../../datagrid-v2";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { Filter } from "../filter/filter";
import { DatePicker } from "../datepicker/datepicker";
import { ColumnResizer } from "../column-resizer/column-resizer";
import { BsTrash } from "react-icons/bs";
import { format } from "date-fns";
import { DateFilterInput } from "../filter/date-filter-input";
import { NumberFilterInput } from "../filter/number-filter-input";
import { FilterInput } from "../filter/filter-input";
import { filterOptions } from "../filter/filter-options";

type FilterCellType = "date" | "string" | "number" | "datetime";

type CellProps = {
  column: Column,
  width: string,
  type?: FilterCellType,
};

export const FilterCell = (props: CellProps) => {
  const { filter, onFilterChange, column } = props;
  const { type, width } = column;

  const handleValueChange = useCallback(
    (value, index) => {
      let newValues;
      if (!filter?.values || filter?.values?.length === 0) {
        newValues = [value];
      } else if (
        index === 1 &&
        !filter.values[index] &&
        filter.values[index] !== 0
      ) {
        newValues = [...filter.values, value];
      } else {
        newValues = filter.values.map((v, idx) => {
          if (idx === index) return value;
          return v;
        });
      }

      onFilterChange(column.name, filter?.type || "contains", newValues);
    },
    [filter, column],
  );

  const handleTypeChange = useCallback(
    (type) => {
      onFilterChange(column.name, type, filter?.values || []);
    },
    [filter, column],
  );

  const hasFilter = useMemo(
    () => filter?.values?.some((v) => v && v !== ""),
    [filter],
  );

  const filterValues = useMemo(() => filter?.values || [], [filter]);

  const [format, setFormat] = useState("");

  useEffect(() => {
    if (typeof column.format === "function") {
      column.format({}).then(setFormat);
    } else {
      setFormat(column.format);
    }
  }, [column.format]);

  return (
    <Th
      width={width || "auto"}
      minWidth={width || "auto"}
      maxWidth={width || "auto"}
      position="relative"
      overflow="hidden"
      padding="0"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height="100%"
        padding="4px 5px"
        margin="0"
        gap="5px"
        overflow="hidden"
        position="relative"
      >
        <Box display="flex" alignItems="center" width="100%">
          {type?.includes("date") ? (
            <DateFilterInput
              type={filter?.type}
              format={format || "dd-MM-yyyy"}
              onChange={handleValueChange}
              values={filterValues}
            />
          ) : type === "number" ? (
            <NumberFilterInput
              type={filter?.type}
              onChange={handleValueChange}
              values={filterValues}
              format={format || "##0.00"}
            />
          ) : (
            <FilterInput
              onChange={handleValueChange}
              value={filterValues?.[0]}
            />
          )}
          <Filter
            options={filterOptions}
            selected={filter?.type || "contains"}
            onChange={(e) => handleTypeChange(e.target.value)}
            style={{
              color: hasFilter ? "red" : "",
            }}
          />
        </Box>
      </Box>
      <ColumnResizer column={column} />
    </Th>
  );
};
