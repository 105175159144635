import PropTypes from "prop-types";
import { CustomSelect } from "../custom-select/custom-select";
import { useEffect, useState } from "react";
import { getQueryList } from "../../../../../../services/query-service";

export const DatagridQuerySelect = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getQueryList().then((res) => {
      const options = res.map(({ id, code }) => ({ value: id, label: code }));
      setOptions(options);
    });
  }, []);

  return <CustomSelect {...props} options={options} />;
};

DatagridQuerySelect.propTypes = {
  propValue: PropTypes.string,
  onChange: PropTypes.func,
  propName: PropTypes.string,
  diagramParams: PropTypes.object,
};
