import { subDays } from "date-fns";
import { useCallback } from "react";
import { List } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { FullDayEvent } from "./full-day-event";

export const FullDayEventsList = ({
  events,
  calendars,
  onEventEdit,
  onEventClick,
  sectionId,
}) => {
  const handleDrop = useCallback(
    (item, newArea) => {
      if (!newArea) return;
      const pos = item.startPos;
      const newPos = newArea;
      const event = events.find((ev) => ev.id === item.id);
      const diff = pos - newPos;
      const newEvent = {
        ...event,
        from: subDays(event.from, diff),
        to: subDays(event.to, diff),
      };

      if (onEventEdit) onEventEdit(newEvent);
    },
    [events, onEventEdit],
  );

  return (
    <List
      display="flex"
      flexDirection="column"
      gap="5px"
      height="100%"
      overflow="auto"
    >
      {events.map((ev) => (
        <FullDayEvent
          {...ev}
          background={
            ev.color ||
            calendars.find((c) => c.id === ev.calendarId).background.value
          }
          sectionId={sectionId}
          onDrop={handleDrop}
          onClick={() => onEventClick(ev)}
          key={`${ev.calendarId}-${ev.id}`}
        />
      ))}
    </List>
  );
};

FullDayEventsList.propTypes = {
  events: PropTypes.array,
  calendars: PropTypes.array,
  onEventEdit: PropTypes.func,
  onEventClick: PropTypes.func,
  sectionId: PropTypes.number,
};
