import React, { useEffect } from "react";
import { useDragContext } from "../context/drag-context";

export const DragItem = ({
  id,
  position,
  onDrag,
  onDrop,
  onDragStart,
  disabled,
  children,
  context,
}) => {
  const dragContext = useDragContext();
  const { registerItem } = dragContext;

  useEffect(() => {
    registerItem({ id, position }, context);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, id]);

  const handleOnDrag = (e) => {
    e.stopPropagation();
    if (disabled) return;
    const item = dragContext.getDraggedItem();
    const areaPos = dragContext.getAreaPos();

    if (onDrag) onDrag(item, areaPos, e);
  };

  const handleOnDrop = () => {
    if (disabled) return;
  };

  const handleOnDragEnd = (e) => {
    e.stopPropagation();
    if (disabled) return;
    const { item, areaPos } = dragContext.onDrop();

    if (onDrop) {
      onDrop(item, areaPos);
    }
  };

  const handleOnDragOver = () => {
    if (disabled) return;
    const contextList = dragContext.getContextList();
    const currentContext = contextList.find((c) => c.id === context);
    const currentItem = currentContext.items.find((i) => i.id === id);
    const itemExists = currentItem !== null && currentItem !== undefined;

    if (currentContext && itemExists) {
      dragContext.onDragOverItem(position, "item");
    }
  };

  const handleOnDragStart = (e) => {
    e.stopPropagation();
    if (disabled) return;
    dragContext.onDragStart({ id, startPos: position, context });
    if (onDragStart) onDragStart(e, { id, startPos: position });
  };

  const element = React.cloneElement(children, {
    onDrag: handleOnDrag,
    onDrop: handleOnDrop,
    onDragEnd: handleOnDragEnd,
    onDragOver: handleOnDragOver,
    onDragStart: handleOnDragStart,
    onMouseDown: (e) => {
      e.stopPropagation();
    },
    draggable: disabled ? false : true,
  });

  return element;
};
