import { useDragContext } from "../context/drag-context";
import { useEffect } from "react";

export const DragContainer = ({ children, context }) => {
  const { addContext } = useDragContext();

  useEffect(() => {
    addContext(context);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  return children;
};
