import ReactQuill from "react-quill";

/* 'destroyEditor' method fixes editor dissapearing problem
 * Project probably is abandoned.
 * https://github.com/zenoamaro/react-quill/issues/836
 */
class EchoReactQuill extends ReactQuill {
  destroyEditor() {
    super.destroyEditor();
    delete this.editor;
  }
}

export default EchoReactQuill;
