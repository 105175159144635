const boardBgDark = "#292929";
const boardBgLight = "#fdfdfd";

const sectionBgDark = "#2e2e2e";
const sectionBgLight = "#ededed";

const taskBgDark = "#2e2e2e";
const taskBgLight = "#ededed";

const borderDark = "#3b3b3b";
const borderLight = "#ededed";

const hoverBorderDark = "#5c5c5c";
const hoverBorderLight = "#ededed";

export const getEmptySectionBackground = (theme, hovered) => {
  if (!hovered) return getSectionBackground(theme);

  if (!theme || theme === "light") return "#e6e6e6";
  return "#2a2a2a";
};

export const getBorderColor = (theme) => {
  if (!theme || theme === "light") return borderLight;
  return borderDark;
};

export const getHoverBorderColor = (theme) => {
  if (!theme || theme === "light") return hoverBorderLight;
  return hoverBorderDark;
};

export const getBoardBackground = (theme) => {
  if (!theme || theme === "light") return boardBgLight;
  return boardBgDark;
};

export const getSectionBackground = (theme) => {
  if (!theme || theme === "light") return sectionBgLight;
  return sectionBgDark;
};

export const getTaskBackground = (theme) => {
  if (!theme || theme === "light") return taskBgLight;
  return taskBgDark;
};
