import { executeBlock } from "../../../../../../../process-executor/process-executor";

export const getData = async (dataSource, context, component) => {
  const componentInfo = {
    callerId: component?.id,
    callerGuid: component?.guid,
    callerNodeId: component?.nodeId,
  };

  return await executeBlock(context, dataSource.id, {}, [], componentInfo);
};
