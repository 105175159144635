import {
  Box,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";

import React, { useState } from "react";
import PropTypes from "prop-types";
import { getNumberFormat } from "../../../utils/get-number-format";
import { InputNumeric } from "@echo/ui";
import { getSeparators } from "../../../utils/format-number";

const getValue = (defaultValue, inputValue) => {
  if (typeof inputValue === "undefined" || inputValue === null) {
    return defaultValue;
  }

  return inputValue;
};

export const InputNumericEditCell = (props) => {
  const { value, onChange, autoFocus, format = "##0.00" } = props;
  const [inputValue, setInputValue] = useState(getValue(0.0, value));

  const { group, decimal } = getSeparators(format);
  const step = decimal ? 0.1 : 1;

  return (
    <Box height="100%">
      <InputNumeric
        value={value}
        onChange={onChange}
        format={format || "# ##0.00"}
        step={step}
        isRequired={false}
        isInvalid={false}
        isDisabled={false}
        isReadOnly={false}
        style={{
          height: "100%",
        }}
        btnTabIndex={-1}
      />
    </Box>
  );
};

InputNumericEditCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  format: PropTypes.string,
};
