// @flow

import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@chakra-ui/react";
import { FilterInput, FilterInputProps } from "./filter-input";
import { FilterOptionType } from "./filter-options";
import { InputNumeric } from "@echo/ui";
import { useTimeoutValue } from "../../hooks/use-timeout-value";

type NumberFilterInputProps = {
  type: FilterOptionType,
  onChange: (value) => void,
  values: string[],
};

export const NumberFilterInput = (props: NumberFilterInputProps) => {
  const { type, onChange, values, format } = props;

  const [firstValue, setFirstValue] = useTimeoutValue(values?.[0], (value) =>
    onChange(value, 0),
  );

  const [secondValue, setSecondValue] = useTimeoutValue(values?.[1], (value) =>
    onChange(value, 1),
  );

  const step = useMemo(
    () => (format.includes(",") || format.includes(".") ? 0.1 : 1),
    [format],
  );

  return type === "between" || type === "excludeRange" ? (
    <Box display="flex" alignItems="center" gap="5px">
      <InputNumeric
        onChange={setFirstValue}
        value={firstValue}
        format={format}
        step={step}
        btnTabIndex={-1}
      />
      <InputNumeric
        onChange={setSecondValue}
        value={secondValue}
        format={format}
        step={step}
        btnTabIndex={-1}
      />
    </Box>
  ) : (
    <InputNumeric
      onChange={setFirstValue}
      value={firstValue}
      format={format}
      step={step}
      btnTabIndex={-1}
      style={{ height: "100%", width: "100%" }}
    />
  );
};
