import { Box } from "@chakra-ui/react";
import { Layout } from "./layout";
import { RowLabels } from "./row-labels";
import { Event, EventsList } from "./events-list";

import PropTypes from "prop-types";
import { addHours } from "date-fns";
import { useCalendarDragContext } from "../hooks/use-calendar-drag-context";
import {
  filterFullDayEvent,
  mapEventToSql,
} from "../utils/calendar-events-utils";
import {
  DAY_HOURS,
  GRID_ROWS,
  GRID_TEMPLATE_COLUMNS,
} from "../utils/calendar-utils";
import { useMemo } from "react";
import { FullDayEventsGrid } from "../full-day-events/full-day-events-grid";

export const CalendarBody = (props) => {
  const {
    weekDays,
    events,
    onEventAdd,
    onEventEdit,
    onEventClick,
    availableCalendars,
  } = props;
  const { draggedElement, dragState } = useCalendarDragContext();

  const handleEventAdd = (eventDate) => {
    const startDate = eventDate;
    const endDate = addHours(startDate, 1);

    const sqlEvent = mapEventToSql({
      id: null,
      from: startDate,
      to: endDate,
    });

    if (onEventAdd) onEventAdd(sqlEvent);
  };

  const handleEventEdit = (event) => {
    const sqlEvent = mapEventToSql(event);
    if (onEventEdit) onEventEdit({ ...sqlEvent });
  };

  const handleEventClick = (event) => {
    const sqlEvent = mapEventToSql(event);
    if (onEventClick) onEventClick(sqlEvent);
  };

  const fullDayEvents = useMemo(
    () => events.filter(filterFullDayEvent),
    [events],
  );

  const restEvents = useMemo(
    () => events.filter((ev) => !filterFullDayEvent(ev)),
    [events],
  );

  const eventShift = useMemo(
    () => (fullDayEvents.length > 0 ? 14 : 0),
    [fullDayEvents.length],
  );

  const gridTemplateRows = useMemo(
    () =>
      `repeat(${fullDayEvents.length > 0 ? GRID_ROWS + eventShift : GRID_ROWS}, minmax(10px, 1fr))`,
    [fullDayEvents.length, eventShift],
  );

  return (
    <Box
      height="100%"
      width="100%"
      position="relative"
      display="flex"
      gap="5px"
      cursor={() => {
        if (!dragState) return "pointer";
        if (dragState === "drag") return "move";
        if (dragState.includes("resize-")) return "n-resize";
        return "pointer";
      }}
    >
      {/* labels for rows */}
      <RowLabels labels={DAY_HOURS} />

      {/* actual grid */}
      <Box
        width="100%"
        height="100%"
        display="grid"
        gridTemplateRows={gridTemplateRows}
        gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
      >
        {/* full day events section */}
        {fullDayEvents.length > 0 && (
          <FullDayEventsGrid
            events={fullDayEvents}
            calendars={availableCalendars}
            onEventEdit={handleEventEdit}
            onEventClick={handleEventClick}
            gridRow={"1/15"}
            columns={weekDays}
          />
        )}

        {/* layout */}
        <Layout
          onCellClick={handleEventAdd}
          onEventMove={handleEventEdit}
          columns={weekDays}
          rows={DAY_HOURS.length * 4}
          gridRowStart={fullDayEvents.length > 0 ? 15 : 1}
          gridRowEnd={-1}
        />

        {/* events */}
        <EventsList
          events={restEvents}
          onEventClick={handleEventClick}
          onEventMove={handleEventEdit}
          availableCalendars={availableCalendars}
          shift={eventShift}
        />

        {/* drag placeholder event element */}
        {draggedElement && (
          <Event
            eventsList={events}
            background={
              availableCalendars.find((c) => c.id === draggedElement.calendarId)
                .background
            }
            zIndex="100"
            event={draggedElement}
            onClick={() => {}}
            onMove={() => {}}
            shift={eventShift}
          />
        )}
      </Box>
    </Box>
  );
};

CalendarBody.propTypes = {
  weekDays: PropTypes.array,
  events: PropTypes.array,
  onEventAdd: PropTypes.func,
  onEventEdit: PropTypes.func,
  onEventMove: PropTypes.func,
  onEventClick: PropTypes.func,
  availableCalendars: PropTypes.array,
};
