import React, { useEffect, useRef, useState } from "react";

export const useTimeoutValue = (value, callback) => {
  const [valueState, setValueState] = useState(value);
  const initialRenderRef = useRef(true);

  useEffect(() => {
    if (initialRenderRef.current) return;
    setValueState(value);
  }, [value]);

  useEffect(() => {
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
      return;
    }

    let timeout;

    timeout = setTimeout(() => {
      callback(valueState);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [valueState]);

  return [valueState, setValueState];
};
