import { useContext, useEffect, useMemo, useReducer } from "react";
import PropTypes from "prop-types";
import { initialRouterState, routerReducer } from "./echo-router-reducer";
import EchoRouterContext from "./echo-router-context";
import { uuidv4 } from "@echo/tools";
import { useHistory } from "./hooks";
import UserContext from "../../user-context";
import { checkVersion } from "../../utils/echo-version/echo-version";
import { toast } from "react-toastify";

const encodeUrl = (key, url, displayName, params) => {
  return `ZWNobw==${btoa(
    encodeURI(
      JSON.stringify({
        key,
        displayName,
        params,
        path: `/${encodeURI(key)}${url.startsWith("/") ? "" : "/"}${url}`,
      }),
    ),
  )}`;
};

export const decodeUrl = (pathName) => {
  try {
    return JSON.parse(
      decodeURI(atob(pathName.substring(pathName.startsWith("/") ? 9 : 8))),
    );
  } catch {
    return {};
  }
};

const EchoRouter = ({ children }) => {
  const { companyId, userName } = useContext(UserContext);
  const history = useHistory();
  const [browserState, dispatch] = useReducer(
    routerReducer,
    initialRouterState,
  );

  const routerContext = useMemo(
    () => ({
      openPage: (url, state, displayName, focus, params, componentProps) => {
        const key = uuidv4();

        history.push(encodeUrl(key, url, displayName, params), { key });
        dispatch({
          type: "OPEN_PAGE",
          state,
          path: url,
          focus,
          key,
          displayName,
          componentProps,
          companyId,
        });
        return key;
      },
      updatePageName: (key, displayName) => {
        if (
          browserState?.openedPages.some(
            (p) => p.key === key && p.displayName !== displayName,
          )
        ) {
          dispatch({ type: "UPDATE_DISPLAY_NAME", key, displayName });
        }
      },
      focusPage: (key) => {
        const p = browserState.openedPages.find((p) => p.key === key);
        if (p) {
          history.push(encodeUrl(key, p.path, p.displayName, p?.state?.params));
          dispatch({ type: "FOCUS_PAGE", key });
          return true;
        } else {
          return false;
        }
      },
      closePage: (key, focusKey) => {
        const toCloseKey = key ?? browserState?.activePage?.key;
        if (!toCloseKey) {
          return;
        }

        const newActivePagesList = browserState.activePagesList.filter(
          (p) => p.key !== toCloseKey,
        );

        if (newActivePagesList.length > 0) {
          const newActivePage =
            newActivePagesList[newActivePagesList.length - 1];

          history.push(
            encodeUrl(
              newActivePage.key,
              newActivePage.path,
              newActivePage.displayName,
              newActivePage?.state?.params,
            ),
          );
        } else {
          history.push("");
        }

        dispatch({ type: "CLOSE_PAGE", key: toCloseKey, focusKey });
      },
      getActivePage: () => browserState.activePage,
      getOpenedPages: () => browserState.openedPages,
      closeAllPages: () => {
        dispatch({ type: "CLOSE_ALL_PAGES" });
        history.push("");
      },
      restorePages: (pages) =>
        dispatch({ type: "RESTORE_PAGES", openedPages: pages }),
      goToStart: () => {
        dispatch({ type: "GO_TO_START" });
        history.push("");
      },
    }),
    [browserState.activePage, browserState.openedPages, history, companyId],
  );

  const getLocalStoragePages = () =>
    JSON.parse(localStorage.getItem("opened_pages"));

  const fixPath = (path) => {
    if (typeof path != "string") {
      return path;
    }
    let pathElements = path.split("/");
    for (let elem of pathElements) {
      if (
        /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-4[a-fA-F0-9]{3}-[89abAB][a-fA-F0-9]{3}-[a-fA-F0-9]{12}$/g.test(
          elem,
        )
      ) {
        pathElements = pathElements.filter((e) => e !== elem);
      }
    }
    return pathElements.join("/");
  };

  const getPagesForCompany = (pages, companyId) =>
    pages.filter((p) => p.companyId === companyId);

  useEffect(() => {
    if (
      window.location.pathname.startsWith("/ZWNobw==") ||
      window.location.pathname.startsWith("ZWNobw==")
    ) {
      const request = decodeUrl(window.location.pathname);
      const {
        key,
        displayName,
        params,
        path,
        companyIds: requestCompanyIds,
      } = request;

      const storedPages = getLocalStoragePages();
      if (storedPages && storedPages.length > 0) {
        const companyPages = getPagesForCompany(storedPages, companyId);
        routerContext.restorePages(companyPages);
      }

      if (
        (!key ||
          (browserState.openedPages.findIndex((p) => p.key === key) === -1 &&
            !storedPages?.some((p) => p.key === key))) &&
        path
      ) {
        if (
          !requestCompanyIds ||
          requestCompanyIds
            .map((cId) => cId.toString())
            .includes(companyId.toString())
        ) {
          routerContext.openPage(
            fixPath(path),
            { params: Array.isArray(params) ? params : [params] },
            displayName,
            true,
            Array.isArray(params) ? params : [params],
          );
        } else {
          toast.warn("Please, log in to the correct company to open this url.");
        }
      }
    } else {
      const storedPages = getLocalStoragePages();
      if (storedPages && storedPages.length > 0) {
        const companyPages = getPagesForCompany(storedPages, companyId);
        routerContext.restorePages(companyPages);
      }
    }
  }, []);

  useEffect(() => {
    if (userName) {
      checkVersion();
    }
  }, [browserState.activePage, userName]);

  return (
    <EchoRouterContext.Provider value={routerContext}>
      {children}
    </EchoRouterContext.Provider>
  );
};

EchoRouter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EchoRouter;
