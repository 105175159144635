export const getChangesForColumns = (columns, newColumns) => {
  const changedColumns = columns
    .map((c1) => {
      const c2 = newColumns.find((c2) => c1.name === c2.name);
      const c2Keys = Object.keys(c2);

      const changed = c2Keys.reduce((obj, key) => {
        const isChanged = c1[key] !== c2[key];
        if (isChanged) return { ...obj, name: c1.name, [key]: c2[key] };
        return { ...obj };
      }, {});

      return changed;
    })
    .filter((c) => Object.keys(c).length > 0);

  let changes = [];

  changedColumns.forEach((c) => {
    const keys = Object.keys(c);

    keys.forEach((k) => {
      if (k === "name") return;
      const change = { name: c.name, propName: k, propValue: c[k] };
      changes.push(change);
    });
  });

  return changes;
};
