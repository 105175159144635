import { useContext, useMemo } from "react";
import EchoRouterRouteContext from "../echo-router-route-context";
import EchoRouterPageContext from "../echo-router-page-context";

export const usePage = () => {
  const { active } = useContext(EchoRouterRouteContext);
  const page = useContext(EchoRouterPageContext);

  const state = useMemo(() => ({ active, page }), [active, page]);

  return state;
};
