const getKeyFromObj = (obj, key) => {
  const keys = Object.keys(obj);
  return keys.find((k) => k.toLowerCase() === key.toLowerCase());
};

export const getSectionName = (obj) => {
  const titleKey = getKeyFromObj(obj, "name");
  return obj[titleKey];
};

export const getSectionId = (obj) => {
  const idKey = getKeyFromObj(obj, "id");
  return obj[idKey];
};

export const getSectionOrder = (obj) => {
  const order = getKeyFromObj(obj, "order");
  if (!order) return null;
  return obj[order];
};
