// @flow

import * as React from "react";

import { Box } from "@chakra-ui/react";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import type { Column } from "../../datagrid-v2";
import { useResize } from "../../hooks/useResize";

type ResizerProps = {
  column: Column,
  preventDraggable: (val: boolean) => void,
  onSizeChange: (value: number) => void,
};

export const ColumnResizer = ({
  column,
  preventDraggable,
  onSizeChange,
}: ResizerProps): React.Node => {
  const { isMouseDown, onMouseDown, onMouseUp, mousePos } = useResize(column);
  const {
    settings,
    actions: { onColumnWidthChange },
  } = useDatagridContext();

  const handleMouseDown = (e) => {
    if (preventDraggable) preventDraggable(true);
    if (onSizeChange) onMouseDown(e);
  };
  const handleMouseUp = (e) => {
    if (onSizeChange) {
      const resizedElement = onMouseUp(e);
      if (!onColumnWidthChange) return;
      if (resizedElement) {
        onColumnWidthChange(resizedElement.name, resizedElement.width);
      }
    }
    if (preventDraggable) preventDraggable(false);
  };

  const handleMouseMove = (e) => {
    const diff = e.clientX - mousePos.x;
    if (onSizeChange) onSizeChange(diff);
  };

  return (
    <>
      <Box
        position="absolute"
        top="0"
        right="0"
        width="6px"
        height="100%"
        borderRight="2px solid"
        onMouseDown={handleMouseDown}
        cursor={onSizeChange ? "col-resize" : "default"}
      />
      {isMouseDown && (
        <Box
          position="fixed"
          left="0"
          top="0"
          height="100%"
          width="100%"
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          zIndex="10"
          cursor="col-resize"
        />
      )}
    </>
  );
};
