import { uuidv4 } from "@echo/tools";
import { createStore } from "../utils/requests-store";
import { useCallback, useEffect, useState } from "react";

export const useDataLoading = (id, callback, deps, condition, delay) => {
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState();
  const { addRequest, removeRequest, getRequests } = createStore(id);

  const request = useCallback(() => {
    setLoading(true);
    const uuid = uuidv4();
    addRequest(uuid);

    callback()
      .then((res) => {
        const requests = getRequests();
        if (requests[requests.length - 1] === uuid) {
          setResult(res);
        }
      })
      .finally(() => {
        setLoading(false);
        removeRequest(uuid);
      });
  }, [addRequest, callback, getRequests, removeRequest]);

  useEffect(() => {
    let timeout;

    if (condition && delay) {
      timeout = setTimeout(() => {
        request();
      }, delay);
    } else if (condition && !delay) {
      request();
    }

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition, ...deps]);

  return { result, loading };
};
