import React, { useState } from "react";
import { Input, Textarea } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useDatagridContext } from "../../../hooks/useDatagridContext";

export const InputEditCell = (props) => {
  const { value, onChange, autoFocus } = props;
  const [inputValue, setInputValue] = useState(value);
  const { settings } = useDatagridContext();

  const handleChange = (e) => {
    const value = e.target.value;
    onChange(value);
  };

  return settings?.lockRowHeight === false ? (
    <Textarea
      value={inputValue}
      onBlur={handleChange}
      onChange={(e) => setInputValue(e.target.value)}
      autoFocus={autoFocus}
      width="100%"
      fontSize="12px"
      padding="2px 4px"
    />
  ) : (
    <Input
      value={inputValue}
      onBlur={handleChange}
      onChange={(e) => setInputValue(e.target.value)}
      autoFocus={autoFocus}
      width="100%"
    />
  );
};

InputEditCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
};
