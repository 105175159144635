// @flow

import React, { useContext, useEffect } from "react";
import { Box, Tbody, useDisclosure } from "@chakra-ui/react";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { useMultipleSelect } from "../../hooks/useMultipleSelect";
import { Row } from "./row";
import {
  DATAGRID_BODY_ROW_TESTID,
  DATAGRID_BODY_TESTID,
} from "../../utils/test-ids";
import { getId } from "../../utils/get-id";
import { useContextMenu } from "@echo/ui/src/lib/components/context-menu/hooks/useContextMenu";
import { ContextMenu } from "@echo/ui";
import { ContextMenuWrapper } from "../context-menu-wrapper/context-menu-wrapper";
import { BodyWithContextMenu } from "./body-with-context-menu";
import { EmptyRow } from "./empty-row";
import {
  getRowBackgroundByIdx,
  getRowTextColor,
  getTextColor,
} from "../../utils/theme-utils";
import { RowV2 } from "./row-v2";
import { getRowValues } from "../../utils/get-row-values";

export const BodyV2 = ({ columns }): React.Node => {
  const {
    data,
    actions: { onRowClick, onRowDoubleClick, onRowAdd },
    loading,
    enableAdding,
    theme,
  } = useDatagridContext();

  const { selectMany, unselectMany } = useMultipleSelect();

  const handleRowClick = (row: {
    [key]: { value: number | string | boolean },
  }) => {
    const values = getRowValues(row);
    if (!onRowClick) return;
    onRowClick(values);
  };

  const handleRowDoubleClick = (row: {
    [key]: { value: number | string | boolean },
  }) => {
    const values = getRowValues(row);
    if (!onRowDoubleClick) return;
    onRowDoubleClick(values);
  };

  const handleRowSelect = (e) => {
    const isChecked = e.target.checked;
    const shiftPressed = e.nativeEvent.shiftKey;
    const id = e.target.value;

    if (isChecked && selectMany) selectMany(id, shiftPressed);
    if (!isChecked && unselectMany) unselectMany(id, shiftPressed);
  };

  if (loading) {
    return (
      <BodyWithContextMenu
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box>Loading data...</Box>
      </BodyWithContextMenu>
    );
  }

  if (!loading && data.length === 0) {
    return !enableAdding ? (
      <BodyWithContextMenu
        height="100%"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box>No records found.</Box>
      </BodyWithContextMenu>
    ) : (
      <BodyWithContextMenu height="100%" display="block" overflow="auto">
        <EmptyRow
          columns={columns}
          style={{
            background: getRowBackgroundByIdx(0, theme),
            color: getRowTextColor(),
          }}
          data-testid={DATAGRID_BODY_ROW_TESTID}
        />
      </BodyWithContextMenu>
    );
  }

  return (
    <BodyWithContextMenu display="block">
      {enableAdding && (
        <EmptyRow
          columns={columns}
          style={{
            background: getRowBackgroundByIdx(1, theme),
            color: getTextColor(theme),
          }}
          data-testid={DATAGRID_BODY_ROW_TESTID}
        />
      )}
      {data.map((record, idx) => {
        return (
          <RowV2
            row={record}
            style={{
              background: getRowBackgroundByIdx(idx, theme),
              color: getRowTextColor(
                Object.keys(record).reduce(
                  (acc, curr) => ({ ...acc, [curr]: record[curr].value }),
                  {},
                ),
              ),
            }}
            columns={columns}
            onRowClick={() => handleRowClick(record)}
            onRowDoubleClick={() => handleRowDoubleClick(record)}
            onRowSelect={handleRowSelect}
            key={`${getId(record).value}-${idx}`}
          />
        );
      })}
    </BodyWithContextMenu>
  );
};
