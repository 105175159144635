import { createContext, useContext } from "react";
import PropTypes from "prop-types";
export const BoardContext = createContext();

export const BoardContextProvider = ({ children, value }) => {
  return (
    <BoardContext.Provider value={value}>{children}</BoardContext.Provider>
  );
};

BoardContextProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.object,
};

export const useBoardContext = () => {
  const context = useContext(BoardContext);

  if (!context) throw new Error("Board context not initialized");
  return context;
};
