export const mapObjPropertiesToCamelCase = (obj) => {
  const keys = Object.keys(obj);

  const newObj = keys.reduce((acc, curr) => {
    const newKey = curr.charAt(0).toLowerCase() + curr.slice(1);

    return { ...acc, [newKey]: obj[curr] };
  }, {});

  return newObj;
};
