import { getBlock } from "../../services/diagram-service";
import { getBlockExecutor } from "../get-block";

const getProcessExecutor = async (process, componentContext) => {
  try {
    const block = await getBlock(process.id);
    const executor = await getBlockExecutor(block, componentContext);
    return executor;
  } catch (e) {
    throw new Error("Couldn't get executor for the process.");
  }
};

const executeProcess = async (executor, staticParams) => {
  const { property, __model } = staticParams;
  const propertyValue = __model[property];

  const newStaticParams = { ...staticParams, value: propertyValue };
  const newValue = await executor.execute(newStaticParams, [propertyValue]);
  if (!newValue) return __model;
  return { ...__model, [property]: newValue };
};

export const changeModelProperty = (block, componentContext) => {
  return {
    definition: block,
    execute: async (staticParams) => {
      try {
        const { process } = staticParams;
        const executor = await getProcessExecutor(process, componentContext);
        const newModel = await executeProcess(executor, staticParams);
        const res = { ...staticParams, __model: newModel };
        return res;
      } catch (e) {
        throw new Error("Error occured when executing block.");
      }
    },
  };
};
