import { Checkbox, Td } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo } from "react";
import { format as formatDate } from "date-fns";
import { Cell } from "./cell";
import { formatValue } from "../../utils/format-number";

const getNumberValue = (value, format) => {
  if (!value && value !== 0) return "";
  if (!format) return value;

  return formatValue(value, format);
};

const getDateValue = (value, format) => {
  if (!value) return "";
  try {
    const date = new Date(value);
    const formatted = formatDate(date, format || "dd-MM-yyyy");
    return formatted;
  } catch (e) {
    console.error(e);
    return "";
  }
};

const getBoolValue = (value) => {
  if (!value) return false;
  if (value === 0 || value === false || value === "false") return false;
  if (value === 1 || value === true || value === "true") return true;

  return true;
};

const getComboValue = (value, options) => {
  if (!options || (options && options.length < 1)) return value;
  const f = options?.find((opt) => {
    const [optValue, optLabel] = Object.values(opt);
    return optValue == value;
  });

  if (f) return Object.values(f)[1];
  return value;
};

const getDisplayValue = (value, format, type, options) => {
  switch (type) {
    case "number":
    case "numeric":
      return getNumberValue(value, format || "# ##0.00");
    case "date":
    case "datetime":
      return getDateValue(value, format || "dd-MM-yyyy");
    case "boolean":
    case "checkbox":
      return getBoolValue(value);
    case "combo":
      return getComboValue(value, options);
    case "string":
    default:
      return value;
  }
};

export const DisplayCellV2 = ({ data, column, style, onClick, onChange }) => {
  const { type, value, format, options } = data;

  const handleClick = useCallback(() => {
    onClick(data);
  }, [data]);

  const displayValue = useMemo(
    () => getDisplayValue(value, column.format, type, options),
    [value, format, column.format, type, options],
  );

  return (
    <Cell
      onClick={handleClick}
      column={column}
      width={column?.width}
      style={{
        ...style,
        textAlign: column?.align,
      }}
    >
      {type === "boolean" || type === "checkbox" ? (
        <Checkbox
          onMouseDown={(e) => {
            e.stopPropagation();
            onChange(!value);
          }}
          isChecked={data.value}
          onChange={(e) => {
            e.stopPropagation();
            onChange(e.target.value);
          }}
          isReadOnly
        />
      ) : (
        displayValue
      )}
    </Cell>
  );
};
