import {
  getComponentSettings,
  saveComponentSettings,
} from "../../../../../../../services/settings-service";
import { PRIMARY_COLOR } from "./calendar-utils";

const getIsVisible = async (id, userId, companyId, componentElementId) => {
  const key = `calendar_${id}_isVisible`;
  let value;
  let settingId;
  try {
    const result = await getComponentSettings({
      userId,
      companyId,
      componentElementId,
      key,
    });
    value = JSON.parse(result.value);
    settingId = result.id;
  } catch {
    value = true;
    settingId = null;
  }

  return { value, settingId };
};

const getBackground = async (id, userId, companyId, componentElementId) => {
  const key = `calendar_${id}_background`;
  let value;
  let settingId;
  try {
    const result = await getComponentSettings({
      userId,
      companyId,
      componentElementId,
      key,
    });
    value = result.value;
    settingId = result.id;
  } catch {
    value = PRIMARY_COLOR;
    settingId = null;
  }
  return { value, settingId };
};

export const getCalendarsWithSettings = async (
  calendars,
  userId,
  companyId,
  componentElementId,
) => {
  const availableCalendars = await Promise.all(
    calendars.map(async (c) => ({
      ...c,
      isVisible: await getIsVisible(
        c.id,
        userId,
        companyId,
        componentElementId,
      ),
      background: await getBackground(
        c.id,
        userId,
        companyId,
        componentElementId,
      ),
    })),
  );

  return availableCalendars;
};

export const saveCalendarSetting = async (
  setting,
  calendarId,
  userId,
  companyId,
  componentElementId,
) => {
  const key = `calendar_${calendarId}_${setting.type}`;
  await saveComponentSettings({
    id: setting.id,
    userId: userId,
    companyId,
    componentElementId,
    key,
    value:
      typeof setting.value === "string"
        ? setting.value
        : JSON.stringify(setting.value),
  });
};
