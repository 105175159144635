import { Box, Input, useColorMode } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { BoardSection } from "./board-section/board-section";
import { DragItem, DragSection, DragContainer } from "@echo/draggable";
import { useBoardContext } from "../context/board-context";
import { useMemo, useRef, useState } from "react";
import { mapItemOrder } from "../utils/order-change";
import { getBoardBackground, getSectionBackground } from "../utils/theme-utils";
import { EmptySection } from "./board-section/empty-section";
import { getSectionId, getSectionOrder } from "../utils/section-utils";
import { getTaskSectionId, getTaskTitle } from "../utils/task-utils";
import { normalizeText } from "../utils/normalize-text";

export const Board = ({
  sections,
  tasks,
  sectionsLoading,
  tasksLoading,
  enableSectionAdd,
  enableSectionMove,
  enableFilter,
}) => {
  const { onSectionMove } = useBoardContext();
  const [draggedSection, setDraggedSection] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const mousePos = useRef();
  const gridRef = useRef();

  const handleDragStart = (ev) => {
    ev.dataTransfer.setDragImage(new Image(), 0, 0);
    setDragging(true);
  };

  const handleOnDrop = (section) => {
    onSectionMove({ ...section });
    setDraggedSection(null);
    setDragging(false);
    mousePos.current = null;
    setDisabled(true);
  };

  const handleOnDrag = (item, _, e) => {
    const x = e.clientX;
    const y = e.clientY;
    mousePos.current = { x, y };
    setDraggedSection(item);
  };

  const sectionsWithPlaceholder = useMemo(() => {
    if (!draggedSection) return sections;
    return sections
      .map((s) =>
        s.id === draggedSection.id ? { ...s, placeholder: true } : s,
      )
      .map((s) => mapItemOrder(s, draggedSection));
  }, [sections, draggedSection]);

  const { colorMode } = useColorMode();

  const gridTemplateColumns = useMemo(() => {
    if (!sections || sections.length === 0) {
      if (enableSectionAdd) return 2;
      return 1;
    }
    if (enableSectionAdd) return sections.length + 1;
    return sections.length;
  }, [sections, enableSectionAdd]);

  const [filterValue, setFilterValue] = useState("");
  const filteredTasks = useMemo(() => {
    if (!filterValue || filterValue === "") return tasks;
    if (tasks.length === 0) return [];

    return tasks.filter((t) =>
      normalizeText(getTaskTitle(t).toLowerCase()).includes(
        normalizeText(filterValue.toLowerCase()),
      ),
    );
  }, [tasks, filterValue]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      padding="5px"
      height="100%"
      width="100%"
      overflow="hidden"
      background={getBoardBackground(colorMode)}
    >
      {enableFilter && (
        <Input
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
          width="300px"
        />
      )}
      <DragContainer context="board">
        <DragSection id="board" context="board">
          <Box
            ref={gridRef}
            display="grid"
            columnGap="2px"
            gridTemplateColumns={`repeat(${gridTemplateColumns}, 300px)`}
            overflowX="auto"
            overflowY="hidden"
            height="100%"
            width="100%"
            onMouseUp={() => setDisabled(true)}
          >
            {draggedSection && mousePos.current && (
              <Box
                position="fixed"
                top={`${mousePos.current.y - 20}px`}
                left={`${mousePos.current.x - 100}px`}
                width="250px"
                height="100%"
                pointerEvents="none"
                background={getSectionBackground(colorMode)}
                borderRadius=".75rem"
                zIndex={10}
                onDragOver={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onDragStart={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onDrag={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <BoardSection
                  section={sections.find(
                    (s) => getSectionId(s) === getSectionId(draggedSection),
                  )}
                  tasks={filteredTasks.filter(
                    (t) => getTaskSectionId(t) === getSectionId(draggedSection),
                  )}
                />
              </Box>
            )}
            <DragContainer context="board-sections">
              {sectionsLoading ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                  width="100%"
                >
                  Loading data...
                </Box>
              ) : (
                sectionsWithPlaceholder
                  .sort((a, b) => getSectionOrder(a) - getSectionOrder(b))
                  .map((s) => (
                    <DragItem
                      id={getSectionId(s)}
                      position={getSectionOrder(s)}
                      onDrop={handleOnDrop}
                      onDrag={handleOnDrag}
                      onDragStart={handleDragStart}
                      context="board"
                      disabled={!enableSectionMove || disabled}
                      key={getSectionId(s)}
                    >
                      <Box height="100%" overflow="hidden">
                        <BoardSection
                          section={s}
                          tasks={filteredTasks.filter(
                            (t) => getTaskSectionId(t) === getSectionId(s),
                          )}
                          tasksLoading={tasksLoading}
                          dragging={dragging}
                          disableDrag={setDisabled}
                          enableSectionMove={enableSectionMove}
                        />
                      </Box>
                    </DragItem>
                  ))
              )}
            </DragContainer>
            {enableSectionAdd && <EmptySection order={sections.length} />}
          </Box>
        </DragSection>
      </DragContainer>
    </Box>
  );
};

Board.propTypes = {
  sections: PropTypes.array,
  tasks: PropTypes.array,
  sectionsLoading: PropTypes.bool,
  tasksLoading: PropTypes.bool,
  enableSectionAdd: PropTypes.bool,
  enableSectionMove: PropTypes.bool,
  enableFilter: PropTypes.bool,
};
