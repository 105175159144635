import { localStorageKeys } from "../local-storage/local-storage-keys";

export const requestNotificationPermission = () => {
  if (!("Notification" in window)) {
    console.error("This browser does not support desktop notification");
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then((permission) => {
      localStorage.setItem(
        localStorageKeys.NOTIFICATION_PERMISSION,
        permission,
      );
    });
  }
};
