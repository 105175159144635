import { useCallback, useEffect, useRef, useState } from "react";
import {
  addArrayElementAction,
  removeArrayElementAction,
  updateArrayElementAction,
  updateValueAction,
} from "../../../../../../../services/settings-service";

export const useSorting = (initialSorting, settingData, onSortChange) => {
  const [sorting, setSorting] = useState([]);
  const initialRenderRef = useRef(true);

  useEffect(() => {
    if (initialRenderRef.current) return;
    if (initialSorting) {
      setSorting(initialSorting);
    }
  }, [initialSorting]);

  useEffect(() => {
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
      return;
    }

    onSortChange(sorting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  const updateSorting = useCallback(
    async (name, value) => {
      setSorting((prev) =>
        [...prev].map((s) => {
          if (s.name === name) return { ...s, value };
          return s;
        }),
      );

      const key = "use-sorting-update-array-element-sorting";
      const payload = {
        ...settingData,
        arrayName: "sorting",
        locatorKey: "name",
        locatorValue: name,
        value: { name, value },
      };

      const res = await updateArrayElementAction(payload, key).catch(() => {});
      if (res) {
        const sorting = JSON.parse(res?.value)?.sorting;
        return sorting;
      }

      return null;
    },
    [settingData],
  );

  const addSorting = useCallback(
    async (name, value) => {
      setSorting((prev) => [...prev, { name, value }]);

      const key = "use-sorting-add-array-element-sorting";
      const payload = {
        ...settingData,
        arrayName: "sorting",
        locatorKey: "name",
        locatorValue: name,
        value: { name, value },
      };

      const res = await addArrayElementAction(payload, key).catch(() => {});
      if (res) {
        const sorting = JSON.parse(res?.value)?.sorting;
        return sorting;
      }

      return null;
    },
    [settingData],
  );

  const removeSorting = useCallback(
    async (name) => {
      setSorting((prev) => [...prev.filter((s) => s.name !== name)]);

      const key = "use-sorting-remove-array-element-sorting";
      const payload = {
        ...settingData,
        arrayName: "sorting",
        locatorKey: "name",
        locatorValue: name,
      };

      const res = await removeArrayElementAction(payload, key).catch(() => {});
      if (res) {
        const sorting = JSON.parse(res?.value)?.sorting;
        return sorting;
      }

      return null;
    },
    [settingData],
  );

  const replaceSorting = useCallback(
    async (name, value) => {
      setSorting([{ name, value }]);

      const key = "use-sorting-update-value-sorting";
      const payload = {
        ...settingData,
        name: "sorting",
        value: [{ name, value }],
      };

      const res = await updateValueAction(payload, key).catch(() => {});
      if (res) {
        const sorting = JSON.parse(res?.value)?.sorting;
        return sorting;
      }

      return null;
    },
    [settingData],
  );

  const handleSorting = useCallback(
    async (name, newValue, ctrlKey) => {
      if (!newValue) {
        const res = await removeSorting(name);
        return res;
      }

      if (ctrlKey) {
        const existing = sorting.find((s) => s.name === name);
        if (existing) {
          const res = await updateSorting(name, newValue);
          return res;
        } else {
          const res = await addSorting(name, newValue);
          return res;
        }
      } else {
        const existing = sorting.find((s) => s.name === name);
        if (existing) {
          const res = await updateSorting(name, newValue);
          return res;
        } else {
          const res = await replaceSorting(name, newValue);
          return res;
        }
      }
    },
    [addSorting, removeSorting, replaceSorting, sorting, updateSorting],
  );

  return { sorting, handleSorting };
};
