import React, { useEffect } from "react";
import { useDragContext } from "../context/drag-context";

export const DragSection = ({ id, children, context }) => {
  const { registerSection, onDragOverSection, getContextList, getDraggedItem } =
    useDragContext();

  useEffect(() => {
    registerSection(id, context);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, id]);

  const element = React.cloneElement(children, {
    onDragOver: () => {
      const draggedItem = getDraggedItem();
      const contextList = getContextList();
      const currentContext = contextList.find(
        (c) => c.id === draggedItem?.context,
      );
      const sectionExistsInContext = currentContext?.sections?.some(
        (el) => el === id,
      );
      if (sectionExistsInContext) onDragOverSection(id);
    },
  });

  return element;
};
