import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { Calendar, useCalendar } from "@echo/ui";
import { eachDayOfInterval, format } from "date-fns";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from "react-icons/md";

const getInitialWeek = (start, end) => eachDayOfInterval({ start, end });

export const CalendarNavigation = (props) => {
  const { prevWeek, nextWeek, weekStart, weekEnd, setWeek } = props;

  const getDay = (date) => format(date, "dd.MM");

  const { currentDate, current, prev, next, onCalendarDateChange } =
    useCalendar();

  const calendarDays = [prev, current, next];

  const [selectedWeek, setSelectedWeek] = useState(() =>
    getInitialWeek(weekStart, weekEnd),
  );

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleDateSelected = (week) => {
    setWeek(week[0]);
    setSelectedWeek(week);
    onClose();
  };

  useEffect(() => {
    setSelectedWeek(getInitialWeek(weekStart, weekEnd));
  }, [weekStart, weekEnd]);

  const styleModifier = {
    name: "zIndex",
    enabled: true,
    phase: "main",
    fn({ state }) {
      state.styles.popper["z-index"] = 9999999;
    },
  };

  const calendarRef = useRef(null);
  useOutsideClick({
    ref: calendarRef,
    handler: () => {
      onClose();
    },
  });

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      gap="20px"
      padding="10px"
      fontSize="18px"
    >
      <Button variant="outline" onClick={prevWeek}>
        <MdOutlineNavigateBefore fontSize="20px" />
      </Button>
      <Popover modifiers={[styleModifier]} isOpen={isOpen}>
        <PopoverTrigger>
          <Button variant="ghost" fontSize="1.2rem" onClick={onOpen}>
            {getDay(weekStart)} - {getDay(weekEnd)}
          </Button>
        </PopoverTrigger>
        <PopoverContent width="fit-content" height="fit-content">
          <PopoverBody
            width="fit-content"
            height="fit-content"
            ref={calendarRef}
          >
            <Calendar
              calendarDays={calendarDays}
              date={currentDate}
              onDateChange={onCalendarDateChange}
              onDateSelected={handleDateSelected}
              selectedDates={selectedWeek}
              selectionType="week"
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>

      <Button variant="outline" onClick={nextWeek}>
        <MdOutlineNavigateNext fontSize="20px" />
      </Button>
    </Box>
  );
};

CalendarNavigation.propTypes = {
  weekStart: PropTypes.instanceOf(Date),
  weekEnd: PropTypes.instanceOf(Date),
  nextWeek: PropTypes.func,
  prevWeek: PropTypes.func,
  setWeek: PropTypes.func,
};
