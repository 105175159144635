import { useContext } from "react";
import { Box } from "@chakra-ui/react";
import EchoRouterContext from "./echo-router-context";
import Tab from "../shared/tabs/tab/tab";
import UserContext from "../../user-context";
import {
  getTabMaxWidthConfig,
  getTabMinWidthConfig,
} from "../../app-config/find-config";

const EchoRouterTabs = () => {
  const { getOpenedPages, getActivePage, focusPage, closePage } =
    useContext(EchoRouterContext);
  const { isSuperuser } = useContext(UserContext);
  const openedPages = getOpenedPages();
  const handleOnClick = (page) => focusPage(page.key, page.path);

  const minWidth = getTabMinWidthConfig();
  const maxWidth = getTabMaxWidthConfig();

  return (
    <Box className="tabs-box">
      <Box className="tabs-elem">
        {openedPages?.map((page, index) => (
          <Tab
            key={`tab-${index}-${page.name}`}
            text={page.displayName}
            label={isSuperuser && page.path?.replace("/", "").split("?")[0]}
            isActive={getActivePage()?.key === page.key}
            onClick={() => handleOnClick(page)}
            enableClose
            onClose={() => closePage(page.key)}
            onMouseDown={(e) => {
              if (e.button === 1) closePage(page.key);
            }}
            minWidth={minWidth}
            maxWidth={maxWidth}
          />
        ))}
      </Box>
    </Box>
  );
};

export default EchoRouterTabs;
