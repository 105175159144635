import { useContext, useEffect } from "react";
import { getNotifications } from "../services/notification-service";
import { setLocalStorageItem } from "../utils/local-storage/local-storage";
import { localStorageKeys } from "../utils/local-storage/local-storage-keys";
import { runNotification } from "../utils/notification/run-notification";
import { requestNotificationPermission } from "../utils/notification/request-notification-permission";
import UserContext from "../user-context";

export const useNotification = () => {
  const { userId } = useContext(UserContext);
  const storeNotifications = async (userId) => {
    const notifications = await getNotifications(userId);
    if (!notifications) return;

    setLocalStorageItem(localStorageKeys.NOTIFICATIONS, notifications);
  };

  useEffect(() => {
    requestNotificationPermission();
  }, []);

  useEffect(() => {
    storeNotifications(parseInt(userId));
    let interval = setInterval(() => {
      storeNotifications(parseInt(userId));
    }, 300000);

    return () => clearInterval(interval);
  }, [userId]);

  useEffect(() => {
    let interval = runNotification();
    return () => clearInterval(interval);
  }, []);
};
