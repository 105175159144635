import { createEchoObject } from "../../../../services/echo-object-service";
import { useContext, useEffect } from "react";
import { showErrorToast } from "../../../shared/echo-error-toast";
import { getCrudData } from "../../utils/get-crud-data";
import { initializeAfterCreate } from "../../utils/initialize-after-create";
import UserContext from "../../../../user-context";
import { usePage } from "../../../echo-router/hooks/use-page";

const getStateProperties = (component) => {
  let stateObj = {};

  if (
    component?.childrenComponents &&
    component?.childrenComponents.length > 0
  ) {
    component.childrenComponents.forEach((child) => {
      const childProperties = getStateProperties(child);
      stateObj = { ...stateObj, ...childProperties };
    });
  }

  if (component?.componentProps?.onChange) {
    const key = component.componentProps.name;
    stateObj = { ...stateObj, [key]: null };
  }

  return stateObj;
};

export const useComponentInitialization = (
  component,
  componentState,
  componentContext,
  dispatchState,
  setComponent,
) => {
  const userContext = useContext(UserContext);
  const { page } = usePage();
  const { state: tabState } = page;
  const uriQueryParams = componentContext?.page?.queryParams;
  const stateProps = getStateProperties(component);

  useEffect(() => {
    if (component && !componentState?.__mounted) {
      const idContainingObject =
        tabState?.params?.find(
          (param) =>
            typeof param === "object" &&
            !Array.isArray(param) &&
            (param?._id || param?.id || param?.Id || param?.ID || param?.iD),
        ) ||
        (typeof uriQueryParams === "object" && !Array.isArray(uriQueryParams)
          ? uriQueryParams
          : null);

      const id =
        idContainingObject?._id ||
        idContainingObject?.id ||
        idContainingObject?.Id ||
        idContainingObject?.ID ||
        idContainingObject?.iD;

      if (!id && component.relatedObjectId) {
        createEchoObject(component.relatedObjectId, {
          sessionData: {
            userId: parseFloat(userContext.contextId || userContext.userId),
            userName: userContext.userName,
            companyId: isNaN(userContext.companyId)
              ? userContext.companyId
              : Number(userContext.companyId),
            origin: window.origin,
          },
        })
          .then((result) => {
            const objectState = { ...stateProps, ...result?.objectState };
            return dispatchState({
              type: "SET_FORM_SOURCE",
              propValue: objectState,
            });
          })
          .catch((err) => {
            showErrorToast(err);
          });
      } else if (!id) {
        dispatchState({
          type: "SET_FORM_SOURCE",
          propValue: { ...stateProps },
        });
      } else {
        const params = [];
        if (uriQueryParams) {
          for (let paramKey of Object.keys(uriQueryParams).filter(
            (name) => name.toLowerCase() !== "id",
          )) {
            params.push({ name: paramKey, value: uriQueryParams[paramKey] });
          }
        }
        getCrudData(
          {
            component,
            context: componentContext,
            id,
            params,
          },
          (result) => {
            const resObj =
              Array.isArray(result) && result.length
                ? result[0]
                : Array.isArray(result)
                  ? {}
                  : result;

            const stateObj = { ...stateProps, ...resObj };
            return dispatchState({
              type: "SET_FORM_SOURCE",
              propValue: stateObj,
            });
          },
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component, dispatchState, componentState.__mounted]);

  useEffect(() => {
    if (tabState?.afterCreateEvent && component) {
      initializeAfterCreate(component, tabState.afterCreateEvent, setComponent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component, tabState?.afterCreateEvent]);
};
