// @flow

import React, { useEffect, useRef, useState } from "react";
import { Input } from "@chakra-ui/react";
import { FilterOptionType } from "./filter-options";
import { useTimeoutValue } from "../../hooks/use-timeout-value";

export type FilterInputProps = {
  onChange: () => void,
  value: string,
  type: FilterOptionType,
};

export const FilterInput = (props: FilterInputProps) => {
  const { type, onChange, value } = props;

  const [valueState, setValueState] = useTimeoutValue(value, (value) =>
    onChange(value, 0),
  );

  return (
    <Input
      padding="2px 4px"
      variant="outline"
      size="xs"
      width="100%"
      value={valueState || ""}
      onChange={(e) => setValueState(e.target.value)}
    />
  );
};
