import { List, useColorMode } from "@chakra-ui/react";
import { EmptyTask } from "./empty-task";
import { SectionItem } from "../section-item/section-item";
import PropTypes from "prop-types";
import { getSectionBackground } from "../../../utils/theme-utils";
import { getSectionId } from "../../../utils/section-utils";
import { getTaskId, getTaskOrder } from "../../../utils/task-utils";

export const TasksList = ({ section, tasks, addingNewTask, closeNewTask }) => {
  const { colorMode } = useColorMode();
  return (
    <List
      display="flex"
      flexDirection="column"
      gap="5px"
      height="100%"
      overflowY="auto"
      overflowX="hidden"
      padding="2px 4px"
      background={
        tasks.data.length > 0 || addingNewTask
          ? ""
          : getSectionBackground(colorMode)
      }
      borderRadius="8px"
    >
      {addingNewTask && (
        <EmptyTask closeTask={closeNewTask} sectionId={getSectionId(section)} />
      )}
      {!tasks.loading &&
        tasks.data
          .sort((a, b) => getTaskOrder(a) - getTaskOrder(b))
          .map((t, idx) => (
            <SectionItem
              item={t}
              sectionId={getSectionId(section)}
              key={`${getSectionId(section)}-${getTaskId(t)}-${idx}`}
            />
          ))}
    </List>
  );
};

TasksList.propTypes = {
  tasks: PropTypes.shape({
    data: PropTypes.array,
    loading: PropTypes.bool,
  }),
  section: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  addingNewTask: PropTypes.func,
  openNewTask: PropTypes.func,
  closeNewTask: PropTypes.func,
};
