import { Box } from "@chakra-ui/react";
import { GRID_COLUMNS, GRID_TEMPLATE_COLUMNS } from "../utils/calendar-utils";
import { DragSection, DragContextProvider } from "@echo/draggable";
import PropTypes from "prop-types";
import { FullDayEventsList } from "./full-day-events-list";
import { useCallback } from "react";
import { isSameDay, isSameWeek } from "date-fns";

export const FullDayEventsGrid = ({
  events,
  onEventEdit,
  onEventClick,
  calendars,
  gridRow,
  columns,
}) => {
  const getEventsForDay = useCallback(
    (day) =>
      events.filter(
        (ev) => isSameDay(ev.from, day) && isSameWeek(ev.from, day),
      ),
    [events],
  );

  return (
    <DragContextProvider>
      <Box
        display="grid"
        gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
        gridColumn="1/-1"
        gridRow={gridRow}
        borderBottomWidth="1px"
        borderBottomStyle="solid"
      >
        {columns.map((c, idx) => (
          <GridColumn
            id={idx}
            events={getEventsForDay(c)}
            column={c}
            calendars={calendars}
            onEventEdit={onEventEdit}
            onEventClick={onEventClick}
            key={idx}
          />
        ))}
      </Box>
    </DragContextProvider>
  );
};

FullDayEventsGrid.propTypes = {
  events: PropTypes.array,
  onEventEdit: PropTypes.func,
  onEventClick: PropTypes.func,
  calendars: PropTypes.array,
  gridRow: PropTypes.number,
  columns: PropTypes.array,
};

const GridColumn = ({ id, events, calendars, onEventEdit, onEventClick }) => {
  return (
    <DragSection id={id}>
      <Box
        borderRightWidth={id === GRID_COLUMNS - 1 ? "" : "1px"}
        borderRightStyle={id === GRID_COLUMNS - 1 ? "" : "solid"}
        padding="5px"
        height="100%"
        overflow="hidden"
      >
        <FullDayEventsList
          events={events}
          sectionId={id}
          onEventEdit={onEventEdit}
          onEventClick={onEventClick}
          calendars={calendars}
        />
      </Box>
    </DragSection>
  );
};

GridColumn.propTypes = {
  id: PropTypes.number,
  events: PropTypes.array,
  onEventEdit: PropTypes.func,
  onEventClick: PropTypes.func,
  calendars: PropTypes.array,
};
