// @flow

import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Td, Tooltip } from "@chakra-ui/react";
import { ColumnResizer } from "../column-resizer/column-resizer";
import type { Column } from "../../datagrid-v2";
import { useShowTooltip } from "@echo/ui";
import { useDatagridContext } from "../../hooks/useDatagridContext";

type CellProps = {
  column: Column,
  width: string,
  children?: React.Node,
  style: React.CSSProperties,
  onClick?: (e: any) => void,
};

export const Cell = (props: CellProps): React.Node => {
  const { column, width, children, style, onClick, cellLinesNumber } = props;
  const { settings } = useDatagridContext();

  const contentRef = useRef();
  const { showTooltip } = useShowTooltip(contentRef.current);

  const isDisabled = useMemo(() => {
    if (typeof children !== "string") return true;
    if (showTooltip) return false;
    return true;
  }, [showTooltip, children]);

  const getWhiteSpace = (lock) => (lock === false ? "normal" : "nowrap");

  return (
    <Tooltip isDisabled={isDisabled} label={children} padding="2px 8px">
      <Td
        width={width || "auto"}
        minWidth={width || "auto"}
        maxWidth={width || "auto"}
        padding="0"
        position="relative"
        overflow="hidden"
        lineHeight="unset"
        onMouseDown={onClick}
      >
        {typeof children === "string" ? (
          <Box
            ref={contentRef}
            height="100%"
            width="100%"
            padding="4px 16px"
            overflow="hidden"
            textOverflow="ellipsis"
            style={style}
            dangerouslySetInnerHTML={{ __html: children }}
            noOfLines={cellLinesNumber}
          />
        ) : (
          <Box
            ref={contentRef}
            height="100%"
            width="100%"
            padding="4px 16px"
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            style={style}
            noOfLines={cellLinesNumber}
          >
            {children}
          </Box>
        )}

        <ColumnResizer column={column} />
      </Td>
    </Tooltip>
  );
};
