export const mergeUniqueObjects = (...arrays) => {
  const mergedObjects = {};

  const mergeObject = (obj) => {
    if (!mergedObjects[obj.name]) {
      mergedObjects[obj.name] = { ...obj };
    } else {
      mergedObjects[obj.name] = { ...mergedObjects[obj.name], ...obj };
    }
  };

  arrays.forEach((arr) => {
    arr.forEach(mergeObject);
  });

  return { object: mergedObjects, list: Object.values(mergedObjects) };
};
