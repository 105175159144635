export const DAY_HOURS = Array.from({ length: 24 }).map((_, id) => {
  if (id < 10) return `0${id}:00`;
  return `${id}:00`;
});

export const GRID_COLUMNS = 7;
export const GRID_ROWS = DAY_HOURS.length * 4;

export const GRID_TEMPLATE_COLUMNS = `repeat(${GRID_COLUMNS}, minmax(2.5rem, 1fr))`;
export const GRID_TEMPLATE_ROWS = `repeat(${GRID_ROWS}, minmax(10px, 1fr))`;

export const PRIMARY_COLOR = "#0079ff";
export const PRIMARY_FONT_COLOR_DARK = "#2E3135";
export const PRIMARY_FONT_COLOR_LIGHT = "#FFF";

export const getFontColor = (hex) => {
  if (!hex || !hex.includes("#")) return PRIMARY_FONT_COLOR_DARK;
  const r = parseInt(hex.substr(1, 2), 16);
  const g = parseInt(hex.substr(3, 2), 16);
  const b = parseInt(hex.substr(5, 2), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance > 0.5 ? PRIMARY_FONT_COLOR_DARK : PRIMARY_FONT_COLOR_LIGHT;
};
