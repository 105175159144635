import { useSectionsDataSource } from "./use-sections-datasource";
import { useTasksDataSource } from "./use-tasks-datasource";

export const useBoardComponent = (props, component) => {
  const { sections, loading, actions } = useSectionsDataSource(
    props,
    component,
  );

  const {
    tasks,
    loading: tasksLoading,
    actions: tasksActions,
  } = useTasksDataSource(props, component);

  return {
    tasks: {
      data: tasks,
      loading: tasksLoading,
      actions: tasksActions,
    },
    sections: {
      data: sections,
      loading,
      actions,
    },
  };
};
