import { useContext, useEffect, useState } from "react";
import { getBlock } from "../../../services/diagram-service";
import { deserializeDiagram } from "../utils/serializer";
import { echoPortal } from "../../echo-portal/echo-portal";
import QuestionAlertComponent from "../../shared/echo-components/base-components/components/question-alert-component/question-alert.component";
import EchoRouterContext from "../../echo-router/echo-router-context";
import { uuidv4 } from "@echo/tools";
import { usePage } from "../../echo-router/hooks/use-page";
import { getObjectDefinition } from "../../../services/echo-object-service";

const mapType = (echoObjectType) => {
  switch (echoObjectType.toLowerCase()) {
    case "tinyint":
    case "smallint":
    case "int":
    case "float":
    case "money":
    case "decimal":
    case "numeric":
    case "bigint":
      return "number";
    case "bit":
      return "boolean";
    default:
      return "string";
  }
};

export const useDesignerInit = (
  id,
  block,
  setBlock,
  setDiagramState,
  getActivePage,
  setCallback,
) => {
  const routerContext = useContext(EchoRouterContext);
  const { page } = usePage();
  const { state: tabState, path } = page;
  const [stateProps, setStateProps] = useState();

  useEffect(() => {
    if (id > 0) {
      getBlock(id).then((result) => {
        if (result.executeType !== 0) {
          echoPortal.render((container) => (
            <QuestionAlertComponent
              open={true}
              header={"Cannot edit system process"}
              question={
                "System blocks are readonly. Do you want to create diagram instead?"
              }
              alertMode={"Yes/No"}
              onAction={(type) => {
                container.unmount();
                switch (type) {
                  case "Yes": {
                    const name = uuidv4();
                    setBlock({
                      ...block,
                      name,
                      description: `Process ${name}`,
                    });
                    break;
                  }
                  case "No":
                    routerContext?.closePage();
                    break;
                }
              }}
            />
          ));
        }
        const blockResult = deserializeDiagram(result);
        Promise.all(
          blockResult.blocks.map(
            (processBlock) =>
              new Promise((resolve, reject) => {
                getBlock(processBlock.relatedBlock.id)
                  .then((relatedBlockResult) =>
                    resolve({
                      ...processBlock,
                      relatedBlock: relatedBlockResult,
                    }),
                  )
                  .catch(reject);
              }),
          ),
        ).then((processBlocks) => {
          Promise.all([
            setBlock(blockResult),
            setDiagramState({ processBlocks, processLinks: blockResult.links }),
          ]);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (
      (!id || (path || "").includes(id.toString())) &&
      tabState?.afterCreateEvent &&
      tabState?.afterCreateEvent?.afterCreateEventName === "SAVE_CALLBACK"
    ) {
      setCallback(() => tabState.afterCreateEvent.callbackEvent);
      if (tabState.afterCreateEvent.customParams) {
        setBlock({ ...block, ...tabState.afterCreateEvent.customParams });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, getActivePage, setCallback]);

  useEffect(() => {
    let mounted = true;
    if (tabState?.diagramParams?.objectId) {
      const loadStateProps = async () => {
        const definition = await getObjectDefinition(
          tabState.diagramParams.objectId,
        );
        if (mounted && Array.isArray(definition.properties)) {
          const props = definition.properties.map((prop) => ({
            name: prop.name,
            type: mapType(prop.type),
          }));
          setStateProps(props);
        }
      };
      loadStateProps();
    }
    return () => {
      mounted = false;
    };
  }, [tabState?.diagramParams?.objectId]);

  return [stateProps, tabState?.diagramParams || {}];
};
