import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";
import {
  format,
  isSameMinute,
  roundToNearestMinutes,
  setHours,
  setMinutes,
  startOfHour,
} from "date-fns";
import { useCalendarDragContext } from "../hooks/use-calendar-drag-context";

import { toast } from "react-toastify";
import {
  getFontColor,
  GRID_TEMPLATE_COLUMNS,
  PRIMARY_COLOR,
} from "../utils/calendar-utils";

export const Layout = (props) => {
  const { columns, rows, onCellClick, onEventMove, gridRowStart, gridRowEnd } =
    props;

  const getRowValueById = (id) => {
    const hour = Math.floor(id / 4);
    const minutes = (id - hour * 4) * 15;
    return { hour, minutes };
  };

  const labels = Array.from({ length: 24 }).map((_, idx) => {
    if (idx === 0) return "";
    return `${idx}`;
  });

  return (
    <Box
      height="100%"
      width="100%"
      gridColumn="1/-1"
      gridRow={`${gridRowStart}/${gridRowEnd}`}
      display="grid"
    >
      {Array.from({ length: rows }).map((_, rowId) => (
        <LayoutRow
          value={getRowValueById(rowId)}
          columns={columns}
          label={rowId % 4 === 0 || rowId === 0 ? labels[rowId / 4] : null}
          onEventMove={onEventMove}
          onCellClick={onCellClick}
          style={{
            gridTemplateColumns: GRID_TEMPLATE_COLUMNS,
            borderBottomWidth:
              rowId === rows - 1 ? "" : (rowId + 1) % 4 === 0 ? "1px" : "",
            borderBottomStyle: "solid",
          }}
          key={rowId}
        />
      ))}
    </Box>
  );
};

const LayoutRow = (props) => {
  const { value, label, columns, onCellClick, onEventMove, style } = props;

  const getCellValue = (date, hour, minutes) => {
    const dateWithHours = setHours(date, hour);
    const result = setMinutes(dateWithHours, minutes);
    return result;
  };

  return (
    <Box
      display="grid"
      height="100%"
      {...style}
      position="relative"
      _before={
        label
          ? {
              content: `"${label}"`,
              position: "absolute",
              display: "block",
              textAlign: "right",
              top: "-11px",
              right: "calc(100% + 10px)",
              fontSize: "14px",
            }
          : {}
      }
    >
      {columns.map((date, colId) => (
        <LayoutCell
          value={getCellValue(date, value.hour, value.minutes)}
          hour={value.hour}
          minute={value.minutes}
          onClick={onCellClick}
          onEventMove={onEventMove}
          style={{
            height: "100%",
            borderRightWidth: colId === columns.length - 1 ? "" : "1px",
            borderRightStyle: "solid",
          }}
          key={colId}
        />
      ))}
    </Box>
  );
};

const LayoutCell = (props) => {
  const { value, style, onClick, onEventMove, hour, minute } = props;
  const { handleDragEnd, handleDragOver, draggedElement, dragState } =
    useCalendarDragContext();

  const handleMove = async (event) => {
    if (onEventMove) {
      try {
        await onEventMove(event);
      } catch {
        toast.error("Error occured while updating event.");
      }
    }
  };

  const isCurrentTime = (value, currDate) => {
    const res = roundToNearestMinutes(currDate, {
      nearestTo: 15,
      roundingMethod: "floor",
    });

    return isSameMinute(value, res);
  };

  const getResizingValue = (eventDate, cellHour, cellMinute) => {
    const dateWithHours = setHours(eventDate, cellHour);
    const date = setMinutes(dateWithHours, cellMinute);
    return date;
  };

  return (
    <Box
      {...style}
      _before={
        isCurrentTime(value, new Date())
          ? {
              content: `""`,
              position: "absolute",
              zIndex: "102",
              top: "0",
              left: "0",
              right: "0",
              height: "2px",
              background: "red ",
            }
          : {}
      }
      _after={
        isCurrentTime(value, new Date())
          ? {
              content: `""`,
              position: "absolute",
              zIndex: "102",
              top: "-4px",
              left: "-5px",
              height: "10px",
              width: "10px",
              borderRadius: "100%",
              background: "#EA4335",
            }
          : {}
      }
      position="relative"
      onClick={() => onClick(startOfHour(value))}
      onMouseOver={(e) => {
        e.stopPropagation();
        if (!draggedElement) return;
        const resizingValue = getResizingValue(
          draggedElement.from,
          hour,
          minute,
        );
        if (dragState === "drag") handleDragOver(value, dragState);
        else handleDragOver(resizingValue, dragState);
      }}
      onPointerUp={async () => {
        if (draggedElement) {
          await handleMove(draggedElement);
          handleDragEnd();
        }
      }}
    >
      {draggedElement &&
        (dragState === "resize-top" || dragState === "drag") &&
        isSameMinute(value, draggedElement.from) && (
          <Box
            position="absolute"
            zIndex="120"
            background={PRIMARY_COLOR}
            color={getFontColor(PRIMARY_COLOR)}
            bottom="calc(100% + 5px)"
            padding="0 6px"
            pointerEvents="none"
          >
            {format(value, "HH:mm")}
          </Box>
        )}
      {draggedElement &&
        dragState === "resize-bottom" &&
        isSameMinute(value, draggedElement.to) && (
          <Box
            position="absolute"
            zIndex="120"
            background={PRIMARY_COLOR}
            color={getFontColor(PRIMARY_COLOR)}
            top="5px"
            padding="0 6px"
            pointerEvents="none"
          >
            {format(value, "HH:mm")}
          </Box>
        )}
    </Box>
  );
};

Layout.propTypes = {
  columns: PropTypes.number,
  rows: PropTypes.number,
  onCellClick: PropTypes.func,
  onEventMove: PropTypes.func,
  gridRowStart: PropTypes.number,
  gridRowEnd: PropTypes.number,
};

LayoutRow.propTypes = {
  value: PropTypes.number,
  columns: PropTypes.array,
  onCellClick: PropTypes.func,
  onEventMove: PropTypes.func,
  style: PropTypes.object,
  label: PropTypes.string,
};

LayoutCell.propTypes = {
  value: PropTypes.instanceOf(Date),
  hour: PropTypes.number,
  minute: PropTypes.number,
  onClick: PropTypes.func,
  onEventMove: PropTypes.func,
  style: PropTypes.object,
};
