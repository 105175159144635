import {
  Box,
  Button,
  ListItem,
  Text,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import { DragItem } from "@echo/draggable";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import {
  getBorderColor,
  getHoverBorderColor,
  getTaskBackground,
} from "../../../utils/theme-utils";
import { useBoardContext } from "../../../context/board-context";
import {
  getTaskAssignedPerson,
  getTaskCompleted,
  getTaskDueDate,
  getTaskId,
  getTaskOrder,
  getTaskPriority,
  getTaskPriorityName,
  getTaskTitle,
} from "../../../utils/task-utils";
import { FaTag } from "react-icons/fa";

export const SectionItem = ({ item }) => {
  const { onItemMove, onItemClick } = useBoardContext();

  const handleClick = useCallback(() => onItemClick(item), [item, onItemClick]);

  const onDrop = (i, newSection) => onItemMove(i, newSection);

  const { colorMode } = useColorMode();

  const priority = useMemo(() => getTaskPriority(item), [item]);
  const priorityName = useMemo(() => getTaskPriorityName(item), [item]);
  const isCompleted = useMemo(() => getTaskCompleted(item), [item]);
  const title = useMemo(() => getTaskTitle(item), [item]);
  const assignedPerson = useMemo(() => getTaskAssignedPerson(item), [item]);
  const dueDate = useMemo(() => getTaskDueDate(item), [item]);

  return (
    <DragItem
      id={getTaskId(item)}
      position={getTaskOrder(item)}
      onDrop={onDrop}
      context="board-sections"
      stopPropagation
    >
      <ListItem
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        borderRadius="8px"
        padding="10px"
        gap="5px"
        border={`1px solid ${getBorderColor(colorMode)}`}
        _hover={{
          borderColor: getHoverBorderColor(colorMode),
        }}
        cursor="pointer"
        onClick={handleClick}
        background={getTaskBackground(colorMode)}
      >
        <Box whiteSpace="wrap" noOfLines={5} lineHeight="1.2rem">
          {priority === 0 && (
            <Tooltip label={priorityName} padding="2px 4px">
              <Button
                padding="0"
                margin="0"
                variant="ghost"
                height="fit-content"
                color={isCompleted ? "green" : "gray"}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <FaTag color="red" />
              </Button>
            </Tooltip>
          )}
          {title}
        </Box>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text>{assignedPerson}</Text>
          <Text>{dueDate}</Text>
        </Box>
      </ListItem>
    </DragItem>
  );
};

SectionItem.propTypes = {
  item: PropTypes.object,
  sectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
