import { uuidv4 } from "@echo/tools";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import EchoRouterContext from "../../../../../echo-router/echo-router-context";
import { FiTrash2 } from "react-icons/fi";
import QuestionAlertComponent from "../../../../echo-components/base-components/components/question-alert-component/question-alert.component";
import { echoPortal } from "../../../../../echo-portal/echo-portal";

export const CustomSelect = (props) => {
  const { propValue, onChange, propName, diagramParams = {}, options } = props;

  const { openPage, focusPage } = useContext(EchoRouterContext);

  const [openedPageKey, setOpenedPageKey] = useState(null);

  const handleOnSave = (process) => {
    onChange({ type: "PROCESS", name: process?.name, id: process?.id });
  };

  const getValue = () => {
    if (typeof propValue === "object") {
      return propValue.name && `Process: ${propValue.name}`;
    } else {
      return propValue;
    }
  };

  const handleOnClick = () => {
    if ((openedPageKey && !focusPage(openedPageKey)) || !openedPageKey) {
      setOpenedPageKey(
        openPage(
          propValue && propValue.id && propValue.type === "PROCESS"
            ? `diagramDesigner/${propValue.id}`
            : "diagramDesigner",
          {
            diagramParams,
            afterCreateEvent: {
              afterCreateEventName: "SAVE_CALLBACK",
              callbackEvent: handleOnSave,
              customParams: {
                name: `${propName}-${uuidv4()}`,
                description: `${propName} process.`,
                private: true,
              },
            },
          },
          `${propName}${propName ? " - " : ""}Diagram designer`,
          true,
        ),
      );
    }
  };

  const handleOnDelete = () => {
    echoPortal.render((container) => (
      <QuestionAlertComponent
        open={true}
        header={"Erase property value"}
        question={"Do you really want to erase this property?"}
        alertMode={"Yes/No"}
        onAction={(type) => {
          if (type === "Yes" && onChange) {
            onChange(null);
          }
          container.unmount();
        }}
      />
    ));
  };

  const sortOptionsByLabel = (a, b) => {
    const aLabel = a.label.toUpperCase();
    const bLabel = b.label.toUpperCase();

    if (aLabel < bLabel) {
      return -1;
    }
    if (aLabel > bLabel) {
      return 1;
    }
    return 0;
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: propValue ? "space-between" : "flex-end",
        flexDirection: "row",
      }}
    >
      {propValue && typeof propValue === "object" ? (
        <div
          style={{
            marginLeft: "8px",
            whiteSpace: "nowrap",
            textAlign: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {getValue()}
        </div>
      ) : (
        <select
          className="table-select"
          name="properties-table-option-select"
          id={`option-select-${uuidv4()}`}
          value={propValue?.toString() || ""}
          onChange={(e) =>
            onChange(e.target.value === "" ? null : e.target.value)
          }
        >
          {!options.some((o) => o.value === "") ? <option value={""} /> : null}
          {options.sort(sortOptionsByLabel).map((o, index) => {
            const [value, label] = Object.values(o);
            return (
              <option key={`option-${index}`} value={value}>
                {label || value}
              </option>
            );
          })}
        </select>
      )}
      <button onClick={handleOnClick} tabIndex={-1}>
        ...
      </button>
      <button onClick={handleOnDelete} tabIndex={-1}>
        <FiTrash2 size="16px" />{" "}
      </button>
    </div>
  );
};

CustomSelect.propTypes = {
  options: PropTypes.array,
  propValue: PropTypes.string,
  onChange: PropTypes.func,
  propName: PropTypes.string,
  diagramParams: PropTypes.object,
};
