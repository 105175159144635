import { timeIntervalType } from "./time-interval-type";

const getLastCharInt = (int) => {
  const string = int.toString();
  const lastChar = string[string.length - 1];
  return parseInt(lastChar);
};

const getMinutesText = (minutes) => {
  const lastCharInt = getLastCharInt(minutes);

  if (minutes === 1) {
    return "minutę";
  }

  if (lastCharInt > 1 && lastCharInt < 5) {
    return `${minutes} minuty`;
  }

  return `${minutes} minut`;
};

const getHoursText = (hours) => {
  const lastCharInt = getLastCharInt(hours);

  if (hours === 1) {
    return "godzinę";
  }

  if (lastCharInt > 1 && lastCharInt < 5) {
    return `${hours} godziny`;
  }

  return `${hours} godzin`;
};

const getDaysText = (days) => {
  if (days === 1) {
    return `${days} dzień`;
  }

  return `${days} dni`;
};

const getWeeksText = (weeks) => {
  const lastCharInt = getLastCharInt(weeks);

  if (weeks === 1) {
    return `${weeks} tydzień`;
  }

  if (weeks > 20 && lastCharInt > 1 && lastCharInt < 5) {
    return `${weeks} tygodnie`;
  }

  return `${weeks} tygodni`;
};

export const getNotificationText = (n) => {
  switch (n.interval) {
    case timeIntervalType.hours:
      return `${n.text} odbędzie się za ${getHoursText(n.timeToNotify)}.`;
    case timeIntervalType.days:
      return `${n.text} odbędzie się za ${getDaysText(n.timeToNotify)}.`;
    case timeIntervalType.weeks:
      return `${n.text} odbędzie się za ${getWeeksText(n.timeToNotify)}.`;
    case timeIntervalType.minutes:
    default:
      return `${n.text} odbędzie się za ${getMinutesText(n.timeToNotify)}.`;
  }
};
