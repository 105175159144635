const getOptions = async (row, column) => {
  if (column?.editDataSource && typeof column?.editDataSource === "function") {
    const options = await column.editDataSource(row);
    return options;
  }

  return null;
};

const getType = async (row, column) => {
  if (column?.editType && typeof column?.editType === "function") {
    const type = await column.editType(row);
    return type;
  } else if (column?.editType && typeof column?.editType === "string") {
    return column.editType;
  }

  return column?.type || "string";
};

const getFormat = async (row, column) => {
  if (column?.format && typeof column?.format === "function") {
    const format = await column.format(row);
    return format;
  } else if (column?.format && typeof column?.format === "string") {
    return column.format;
  }

  return null;
};

const getEditable = async (row, column) => {
  if (column?.editable && typeof column?.editable === "function") {
    const editable = await column.editable(row);
    return editable;
  } else if (
    column?.editable &&
    (typeof column?.editable === "string" ||
      typeof column?.editable === "boolean")
  ) {
    return column.editable;
  }

  return null;
};

export const rowReducer = async (currentKey, row, columns) => {
  const column = columns[currentKey];

  const type = await getType(row, column);
  const format = await getFormat(row, column);
  const editable = await getEditable(row, column);
  const options = await getOptions(row, column);

  return { value: row[currentKey], options, type, format, editable };
};
